import { Button, Form, Input, Modal, Select, message } from 'antd';
import { rejectOrdertQuote, rejectOrdertTenderRequest } from 'api/coreapi/orders';
import { useState } from 'react';
import { RejectOrderQuotePayload } from 'shared/models/payloads/rejectOrderQuotePayload';
import { RejectOrderTenderRequestPayload } from 'shared/models/payloads/rejectOrderTenderRequestPayload';

const { TextArea } = Input;

interface RejectOrderQuoteModalProps {
    orderQuoteId: string;
    onRejected?: () => void,
}

const RejectOrderQuoteModal = (props: RejectOrderQuoteModalProps) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const reject = () => {
        form.validateFields()
            .then((values: any) => {

                const reject = async (payload: RejectOrderQuotePayload) => {
                    setSubmitting(true);
                    try {
                        const result = await rejectOrdertQuote(payload);
                        if (!result?.result) {
                            message.error("Ocurrió un error rechazando la cotización.");
                            return;
                        }

                        if (props.onRejected) {
                            props.onRejected();
                        }

                        setIsModalOpen(false);
                    } catch (error) {
                        message.error('Error obteniendo las órdenes.');

                    } finally {
                        setSubmitting(false);
                    }
                };

                reject({
                    orderQuoteId: props.orderQuoteId,
                    orderQuoteRejectionReasonId: values.rejectionReasonId,
                });
            })
            .catch(() => {

            });
    };

    return (
        <>
            <Button type="primary" danger htmlType="button" style={{ margin: '10px' }}
                disabled={isModalOpen} onClick={() => setIsModalOpen(true)}>
                Rechazar Cotización
            </Button>

            <Modal
                title="Rechazar cotización"
                open={isModalOpen}
                onOk={reject}
                confirmLoading={submitting}
                onCancel={() => setIsModalOpen(false)}
            >
                <Form layout="vertical" form={form}>
                    <Form.Item name="rejectionReasonId"
                        label="Nos gustaría saber la razón por la que no te parece interesante este servicio."
                        rules={[{ required: true, message: 'Por favor ingrese el motivo de rechazo.' }]}>
                        <Select options={[{ value: 1, label: <span>No tengo disponibilidad</span> },
                        { value: 2, label: <span>Tarifa inadecuada</span> }]} />
                    </Form.Item>
                </Form>
            </Modal>
        </>

    );
};

export default RejectOrderQuoteModal;
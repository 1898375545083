import { Alert, Card, message } from "antd";
import { getOrderQuoteSupplierView } from "api/coreapi/orders";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import { OrderQuoteStatuses } from "constants/orders";
import { PermissionList } from "constants/permissions";
import dayjs from 'dayjs';
import usePermission from "features/auth/usePermission";
import ConfirmOrderQuoteModal from "features/orders/confirmOrderQuoteModal";
import OrderDetail from "features/orders/orderDetail";
import OrderQuoteDetail from "features/orders/orderQuoteDetail";
import RejectOrderQuoteModal from "features/orders/rejectOrderQuoteModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderQuoteSupplierView } from "shared/models/orderQuoteSupplierViewModel";

const OrderQuoteConfirmationPage = () => {

    const { orderQuoteId } = useParams();
    const [loading, setLoading] = useState(false);
    const userHasSupplierView = usePermission(PermissionList.OrderQuoteSupplierView);
    const [orderQuoteSupplierView, setOrderQuoteSupplierView] = useState<OrderQuoteSupplierView>();
    const [missedDeadline, setMissedDeadline] = useState<boolean>(false);

    const getInfo = async () => {

        if (!orderQuoteId || !userHasSupplierView)
            return;

        try {
            setLoading(true);
            const response = await getOrderQuoteSupplierView(orderQuoteId);

            if (!response || !response.result) {
                throw new Error("Error cargando la cotización");
            }

            setMissedDeadline(response.result.statusId === OrderQuoteStatuses.ConfirmingAvailability
                && dayjs(response.result?.order.quoteDeadline).isBefore(dayjs(Date.now())));
            setOrderQuoteSupplierView(response.result);
        } catch (error: any) {
            message.error('Error obteniendo la cotización.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getInfo();
    }, [orderQuoteId, userHasSupplierView]);

    if (!orderQuoteId) {
        return (
            <>
                URL inválida
            </>
        );
    }

    if (loading)
        return <LoadingSpinner></LoadingSpinner>

    if (!orderQuoteSupplierView)
        return <></>

    if (missedDeadline) {
        return (<Alert
            message="Atención"
            description={`El tiempo para confirmar la cotización ha terminado. Se esperaba confirmación hasta el ${dayjs(orderQuoteSupplierView?.order.quoteDeadline).format('DD-MM-YYYY HH:mm A')}`}
            type="error"
            showIcon
        />
        );
    }

    return (
        <Card>
            <PageTitle>Confirmar cotización de orden de servicio</PageTitle>
            <OrderDetail order={orderQuoteSupplierView.order}></OrderDetail>
            <OrderQuoteDetail orderQuoteId={orderQuoteSupplierView.id}></OrderQuoteDetail>

            {orderQuoteSupplierView.statusId === OrderQuoteStatuses.ConfirmingAvailability &&
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <ConfirmOrderQuoteModal orderQuoteId={orderQuoteId} onConfirmed={() => getInfo()}></ConfirmOrderQuoteModal>
                    <RejectOrderQuoteModal
                        orderQuoteId={orderQuoteId}
                        onRejected={() => getInfo()} />
                </div>

            }


        </Card>
    );
};

export default OrderQuoteConfirmationPage;
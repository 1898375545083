import { Button } from 'antd';
import { getOrderPaymentCheckout } from 'api/coreapi/orders';
import { useEffect, useState } from 'react';
import { OrderPaymentCheckout } from 'shared/models/orderPaymentCheckoutModel';
import PATHS from 'routes/paths'

interface orderPaymentButtonProps {
    orderNumber: number;
}

const OrderPaymentButton = (props: orderPaymentButtonProps) => {
    const [loading, setLoading] = useState(true);
    const [checkout, setCheckout] = useState<OrderPaymentCheckout>();

    useEffect(() => {

        const getCheckoutInfo = async () => {

            setLoading(true);

            try {

                var checkoutInfo = await getOrderPaymentCheckout(props.orderNumber);
                setCheckout(checkoutInfo.result);
            }
            finally {
                setLoading(false);
            }
        };

        getCheckoutInfo();

    }, [props.orderNumber]);

    if (loading) {
        return <Button type="primary" loading disabled>Pagar</Button>
    }

    if (!checkout) {
        return <Button disabled danger>Error cargando datos de pago</Button>
    }

    return (<form action="https://checkout.wompi.co/p/" method="GET">
        <input type="hidden" name="public-key" value={checkout.publicKey} />
        <input type="hidden" name="currency" value={checkout.currency} />
        <input type="hidden" name="amount-in-cents" value={checkout.amountInCents} />
        <input type="hidden" name="reference" value={checkout.reference} />
        <input type="hidden" name="signature:integrity" value={checkout.integritySignature} />
        <input type="hidden" name="redirect-url" value={window.location.protocol + '//' + window.location.host + PATHS.PAYMENT_RESULT} />
        <Button type="primary" htmlType="submit">Pagar ahora</Button>

    </form>);
};

export default OrderPaymentButton;
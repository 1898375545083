import { RightOutlined } from "@ant-design/icons";
import { Button, theme } from "antd";
import { useResponsive } from "hooks/useResponsive";
import { Link } from "react-router-dom";
import { LAYOUT } from 'utils/layoutConstants';

interface SiderLogoProps {
    isSiderCollapsed: boolean;
    toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
    const { tabletOnly, isTablet } = useResponsive();
    const {
        token: { colorPrimaryHover },
    } = theme.useToken();
    return (
        <div style={{
            height: isTablet ? LAYOUT.desktop.headerHeight : LAYOUT.mobile.headerHeight,
            padding: LAYOUT.mobile.headerPadding,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: isTablet ? LAYOUT.desktop.paddingVertical : 'unset',
            paddingBottom: isTablet ? LAYOUT.desktop.paddingVertical : 'unset',
        }}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', position: 'relative' }}>
                <img src={isSiderCollapsed && tabletOnly ? '/images/logoShortGreen.png' : '/images/logoWhite2.png'} alt="Eslavón" style={{ width: isSiderCollapsed && tabletOnly ? 35 : 178 }} />
            </Link>
            {tabletOnly && (
                <Button style={{
                    color: 'white',
                    background: colorPrimaryHover,
                    border: '1px solid white',
                    transition: 'all 0.2s ease',
                    position: 'absolute' as 'absolute',
                    right: isSiderCollapsed ? '-1rem' : '0.5rem'
                }}
                    shape="circle"
                    size="small"
                    icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
                    onClick={toggleSider}
                />
            )}
        </div>
    );
};
import { Alert } from "antd";


function InternalHome() {
    // var userCanViewCustomer = usePermission(PermissionList.CustomerCreate);
    // var userCanViewSupplier = usePermission(PermissionList.SupplierCreate);
    // var navigate = useNavigate();

    // useEffect(() => {
    //     if (userCanViewCustomer) {
    //         navigate('customer/profile');
    //     }

    //     if (userCanViewSupplier) {
    //         navigate('supplier/profile');
    //     }
    // }, [navigate, userCanViewCustomer, userCanViewSupplier]);

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <Alert
                message="¡Ajusta tus Preferencias de Notificación!"
                description="Ahora puedes ajustar tus preferencias de notificación a tu estilo: 📱 Recibe actualizaciones al instante por WhatsApp, 📧 Opta por correos detallados o 📱+📧 Mantén un ojo en ambos. ¡Tú decides cómo seguir el camino hacia el éxito logístico! 😉📣."
                type="info"
                showIcon
            />
            <p style={{ marginTop: '10px' }}>
                Para acceder a la configuración de notificaciones, haz clic en tu nombre de usuario en la esquina superior derecha y selecciona 'Mi Perfil'. Desde allí, podrás personalizar cómo recibes la información.
            </p>
            {/* <Button type="primary" size="large" onClick={handleConfigure} style={{ marginTop: '20px' }}>
                Configurar Notificaciones
            </Button> */}
        </div>


    );

}

export default InternalHome;
import { Row, Typography } from "antd";
import { FC, PropsWithChildren } from "react";

export const PageTitle: FC<PropsWithChildren> = (props) => {
    return (
        <Row justify={"center"}>
                <Typography.Title level={3} >{props.children}</Typography.Title>
        </Row>
    );

}
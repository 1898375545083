import App from 'App';
import LoadingSpinner from 'components/common/loadingSpinner/loadingSpinner';
import CertificationListPage from 'pages/certificationListPage';
import ContactListPage from 'pages/contactListPage';
import CreateCustomerPage from 'pages/createCustomerPage';
import CreateEmployeePage from 'pages/createEmployeePage';
import CreateOrderPage from 'pages/createOrderPage';
import CreateSupplierPage from 'pages/createSupplierPage';
import CustomerDetailPage from 'pages/customerDetailPage';
import CustomerListPage from 'pages/customerListPage';
import CustomerProfilePage from 'pages/customerProfilePage';
import DocumentListPage from 'pages/documentListPage';
import EmployeeDetailPage from 'pages/employeeDetailPage';
import ErrorPage from 'pages/error';
import ForbiddenPage from 'pages/forbiddenPage';
import InternalHome from 'pages/internalHome';
import OrderBoardPage from 'pages/orderBoardPage';
import OrderDetailPage from 'pages/orderDetailPage';
import { OrderListPage } from 'pages/orderListPage';
import OrderQuoteConfirmationPage from 'pages/orderQuoteConfirmationPage';
import OrderQuotesReviewPage from 'pages/orderQuotesReviewPage';
import OrderTenderRequestPage from 'pages/orderTenderRequestPage';
import PaymentResultPage from 'pages/paymentResultPage';
import ServiceQuoteListPage from 'pages/serviceQuoteListPage';
import SignInCallbackPage from 'pages/signinCallbackPage';
import SignoutCallbackPage from 'pages/signoutCallbackPage';
import SupplierDetailPage from 'pages/supplierDetailPage';
import SupplierListPage from 'pages/supplierListPage';
import SupplierProfilePage from 'pages/supplierProfilePage';
import UnauthorizedPage from 'pages/unauthorizedPage';
import UserListPage from 'pages/userListPage';
import { lazy, Suspense } from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import paths from 'routes/paths';

const EmployeeListPage = lazy(() => import("pages/employeeListPage"));

const getRouteElement = (Component: React.ElementType, props?: any): React.ReactNode => (
   <Suspense fallback={<LoadingSpinner />}>
      <Component {...props} />
   </Suspense>
);

const routes: RouteObject[] = [
   {
      path: paths.SIGNIN_CALLBACK,
      element: getRouteElement(SignInCallbackPage),
      errorElement: <ErrorPage />,
   },
   {
      path: paths.SIGNOUT_CALLBACK,
      element: getRouteElement(SignoutCallbackPage),
      errorElement: <ErrorPage />,
   },
   {
      path: paths.UNAUTHORIZED,
      element: getRouteElement(UnauthorizedPage),
      errorElement: <ErrorPage />,
   },
   {
      path: paths.FORBIDDEN,
      element: getRouteElement(ForbiddenPage),
      errorElement: <ErrorPage />,
   },
   {
      path: paths.HOME,
      element: getRouteElement(App),
      errorElement: <ErrorPage />,
      children: [
         { path: paths.HOME, element: getRouteElement(InternalHome) },
         { path: paths.CONTACTS, element: getRouteElement(ContactListPage) },
         { path: paths.CUSTOMER_PROFILE, element: getRouteElement(CustomerProfilePage) },
         { path: paths.CUSTOMER_CREATE, element: getRouteElement(CreateCustomerPage) },
         { path: paths.CUSTOMER_DOCUMENTS, element: getRouteElement(DocumentListPage, { OwnerType: 'customer' }) },
         { path: paths.CUSTOMER_CERTIFICATIONS, element: getRouteElement(CertificationListPage, { OwnerType: 'customer' }) },
         { path: paths.CUSTOMER_LIST, element: getRouteElement(CustomerListPage) },
         { path: paths.CUSTOMER_DETAIL, element: getRouteElement(CustomerDetailPage) },
         { path: paths.EMPLOYEES, element: getRouteElement(EmployeeListPage) },
         { path: paths.EMPLOYEES_CREATE, element: getRouteElement(CreateEmployeePage) },
         { path: paths.EMPLOYEES_DETAIL, element: getRouteElement(EmployeeDetailPage) },
         { path: paths.SUPPLIER_PROFILE, element: getRouteElement(SupplierProfilePage) },
         { path: paths.SUPPLIER_CREATE, element: getRouteElement(CreateSupplierPage) },
         { path: paths.SUPPLIER_DOCUMENTS, element: getRouteElement(DocumentListPage, { OwnerType: 'supplier' }) },
         { path: paths.SUPPLIER_CERTIFICATIONS, element: getRouteElement(CertificationListPage, { OwnerType: 'supplier' }) },
         { path: paths.SUPPLIER_LIST, element: getRouteElement(SupplierListPage) },
         { path: paths.SUPPLIER_DETAIL, element: getRouteElement(SupplierDetailPage) },
         { path: paths.SERVICE_QUOTES, element: getRouteElement(ServiceQuoteListPage) },
         { path: paths.USERS, element: getRouteElement(UserListPage) },
         { path: paths.ORDER_LIST, element: getRouteElement(OrderListPage) },
         { path: paths.ORDER_CREATE, element: getRouteElement(CreateOrderPage) },
         { path: paths.ORDER_DETAIL, element: getRouteElement(OrderDetailPage) },
         { path: paths.ORDER_BOARD, element: getRouteElement(OrderBoardPage) },
         { path: paths.ORDER_TENDER_REQUEST, element: getRouteElement(OrderTenderRequestPage) },
         { path: paths.QUOTES_REVIEW, element: getRouteElement(OrderQuotesReviewPage) },
         { path: paths.ORDER_QUOTE_CONFIRMATION, element: getRouteElement(OrderQuoteConfirmationPage) },
         { path: paths.PAYMENT_RESULT, element: getRouteElement(PaymentResultPage) }
      ]
   },




   //{ path: paths.NOT_FOUND, element: getRouteElement(PageNotFound) },
];

export default createBrowserRouter(routes);
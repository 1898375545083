import { Header } from "antd/es/layout/layout";
import { useResponsive } from "hooks/useResponsive";
import { LAYOUT } from "utils/layoutConstants";
import DesktopHeader from "./desktopHeader";
import { MobileHeader } from "./mobileHeader";
import { theme } from "antd";

interface MainHeaderProps {
    toggleSider: () => void;
    isSiderOpened: boolean;
}

function MainHeader(props: MainHeaderProps) {
    const { isTablet } = useResponsive();
    const {
        token: { colorPrimary },
    } = theme.useToken();

    return (
        <Header style={{
            lineHeight: 1.5,
            padding: isTablet ? `${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal}` : 'unset,',
            height: isTablet ? LAYOUT.desktop.headerHeight : 'unset',
            background: isTablet ? 'transparent' : colorPrimary
        }}>
            {isTablet
                ? <DesktopHeader></DesktopHeader>
                : <MobileHeader toggleSider={props.toggleSider} isSiderOpened={props.isSiderOpened}></MobileHeader>}
        </Header>
    );
}

export default MainHeader;
import { Alert, Card, Descriptions, Space } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import { OrderTenderRequest } from 'shared/models/orderTenderRequestModel';
import { getDuration } from 'utils/dateUtils';


interface Props {
    orderTenderRequest: OrderTenderRequest;
}

const OrderTenderRequestCard: React.FC<Props> = ({ orderTenderRequest }) => {
    const { order, rejectionReason } = orderTenderRequest;
    const quoteDeadline = dayjs(orderTenderRequest.quoteDeadline);

    return (
        <Space direction='vertical'>
            {!orderTenderRequest.orderQuote && <Alert
                message="Atención"
                description={`Puedes cotizar este servicio hasta el ${dayjs(quoteDeadline).format('DD-MM-YYYY HH:mm A')}`}
                type="info"
                showIcon
            />}

            <Card title="Información del servicio">

                <Descriptions>
                    {/* <Descriptions.Item label="Orden #">{order.orderNumber}</Descriptions.Item> */}
                    <Descriptions.Item label="Tipo de servicio">{order.serviceTypeName}</Descriptions.Item>
                    <Descriptions.Item label="Fecha inicio">{dayjs(order.startDate).format('DD-MM-YYYY hh:mm A')}</Descriptions.Item>
                    {order.endDate &&
                        <Descriptions.Item label="Fecha fin">
                            {dayjs(order.endDate).format('DD-MM-YYYY hh:mm A')}
                        </Descriptions.Item>
                    }

                    {order.endDate &&
                        <Descriptions.Item label="Duración estimada">
                            {getDuration(order.startDate, order.endDate)}
                        </Descriptions.Item>
                    }

                    <Descriptions.Item label="Ciudad">{order.cityName}</Descriptions.Item>
                    <Descriptions.Item label="Departamento">{order.stateName}</Descriptions.Item>
                    <Descriptions.Item label="Dirección">{order.addressLine1}</Descriptions.Item>

                    {order.peopleAmount && <Descriptions.Item label="# de personas">{order.peopleAmount}</Descriptions.Item>}
                    {order.loadType && <Descriptions.Item label="Tipo de carga">{order.loadType}</Descriptions.Item>}
                    {order.packageType && <Descriptions.Item label="Empaque">{order.packageType}</Descriptions.Item>}
                    {order.packagingUnits && <Descriptions.Item label="Unidades">{order.packagingUnits}</Descriptions.Item>}
                    {order.weight && <Descriptions.Item label="Peso (TON)">{order.weight}</Descriptions.Item>}
                    {order.truckTypeName && <Descriptions.Item label="Tipo de vehículo">{order.truckTypeName}</Descriptions.Item>}
                    {order.shiftStartTime && order.shiftStartTime !== "00:00:00" &&
                        <Descriptions.Item label="Turno">{order.shiftStartTime} - {order.shiftEndTime}</Descriptions.Item>
                    }
                    {rejectionReason && <Descriptions.Item label="Rejection Reason">{rejectionReason}</Descriptions.Item>}
                </Descriptions>
                <Descriptions column={1}>
                    <Descriptions.Item label="Requerimientos especiales">
                        {order.requirements.map((req) => req.name).join(', ')}
                    </Descriptions.Item>
                    {order.comments && <Descriptions.Item label="Información complementaria">{order.comments}</Descriptions.Item>}
                </Descriptions>
            </Card>
        </Space >
    );
};

export default OrderTenderRequestCard;
import { useContext } from 'react';
import PermissionContext from "./permissionContext";

type PermissionCheckType = 'any' | 'all';

const usePermission = (permissions: string | string[], type: PermissionCheckType = 'any') => {
    const { hasPermission, hasAllPermissions, hasAtLeastOnePermission } = useContext(PermissionContext);

    if (typeof permissions === 'string') {
        return hasPermission(permissions);
    } else if (Array.isArray(permissions)) {
        return type === 'all' ? hasAllPermissions(permissions) : hasAtLeastOnePermission(permissions);
    } else {
        return false;
    }
}

export default usePermission;
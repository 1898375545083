import { coreApiInstance } from "api/apiInstance";
import { EslavonPageableResponse } from "shared/models/apiResponses/eslavonPageableResponse";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { Contact } from "shared/models/contactModel";
import { Employee } from "shared/models/employeeModel";
import { EnumTypeModel } from "shared/models/enumTypeModel";
import { OrderBoard } from "shared/models/orderBoardModel";
import { OrderCustomer } from "shared/models/orderCustomerModel";
import { Order } from "shared/models/orderModel";
import { OrderPaymentCheckout } from "shared/models/orderPaymentCheckoutModel";
import { OrderQuoteCustomerView } from "shared/models/orderQuoteCustomerViewModel";
import { OrderQuote } from "shared/models/orderQuoteModel";
import { OrderQuoteSupplierView } from "shared/models/orderQuoteSupplierViewModel";
import { OrderTenderRequest } from "shared/models/orderTenderRequestModel";
import { CancelOrderPayload } from "shared/models/payloads/cancelOrderPayload";
import { ConfirmOrderQuotePayload } from "shared/models/payloads/confirmOrderQuotePayload";
import { CreateOrderQuotePayload } from "shared/models/payloads/createOrderQuotePayload";
import { GetOrderBoardPayload } from "shared/models/payloads/getOrderBoardPayload";
import { RejectOrderQuotePayload } from "shared/models/payloads/rejectOrderQuotePayload";
import { RejectOrderTenderRequestPayload } from "shared/models/payloads/rejectOrderTenderRequestPayload";
import { UpsertOrderPayload } from "shared/models/payloads/upsertOrderPayload";
import { toQueryString } from "utils/urlUtils";

const ordersResourceUri = "orders";
const orderRequirementTypesResourceUri = "orderrequirementtypes";
const ordersTenderRequestsResourceUri = "ordertenderrequests";
const orderQuotesResourceUri = "orderquotes";

export const getOrderRequirementTypes = async (): Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        const { data: types } = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>(orderRequirementTypesResourceUri);
        return types;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const createOrder = async (payload: UpsertOrderPayload): Promise<EslavonApiResponse<Order>> => {
    try {
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<Order>>(ordersResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderByNumber = async (orderNumber: string): Promise<EslavonApiResponse<Order>> => {
    try {
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<Order>>(`${ordersResourceUri}/${orderNumber}`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderTenderRequestById = async (orderTenderRequestId: string): Promise<EslavonApiResponse<OrderTenderRequest>> => {
    try {
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<OrderTenderRequest>>(`${ordersTenderRequestsResourceUri}/${orderTenderRequestId}`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const rejectOrdertTenderRequest = async (payload: RejectOrderTenderRequestPayload): Promise<EslavonApiResponse<boolean>> => {
    try {
        const actionUri = `${ordersTenderRequestsResourceUri}/${payload.orderTenderRequestId}/reject`;
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<boolean>>(actionUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const createOrderQuote = async (payload: CreateOrderQuotePayload): Promise<EslavonApiResponse<OrderQuote>> => {
    try {
        const actionUri = `${ordersTenderRequestsResourceUri}/${payload.orderTenderRequestId}/quote`;
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<OrderQuote>>(actionUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const acceptOrderQuote = async (orderQuoteId: string): Promise<EslavonApiResponse<boolean>> => {
    try {
        const actionUri = `${orderQuotesResourceUri}/${orderQuoteId}/accept`;
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<boolean>>(actionUri);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const rejectOrdertQuote = async (payload: RejectOrderQuotePayload): Promise<EslavonApiResponse<boolean>> => {
    try {
        const actionUri = `${orderQuotesResourceUri}/${payload.orderQuoteId}/reject`;
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<boolean>>(actionUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const confirmOrderQuote = async (payload: ConfirmOrderQuotePayload): Promise<EslavonApiResponse<boolean>> => {
    try {
        const actionUri = `${orderQuotesResourceUri}/${payload.orderQuoteId}/confirm`;
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<boolean>>(actionUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderQuotesForCustomerReview = async (orderNumber: string): Promise<EslavonApiResponse<OrderQuoteCustomerView[]>> => {
    try {
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<OrderQuoteCustomerView[]>>(`${ordersResourceUri}/${orderNumber}/quotes/review`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderQuoteCustomerView = async (orderQuoteId: string): Promise<EslavonApiResponse<OrderQuoteCustomerView>> => {
    try {
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<OrderQuoteCustomerView>>(`${orderQuotesResourceUri}/${orderQuoteId}/customerview`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderQuoteSupplierView = async (orderQuoteId: string): Promise<EslavonApiResponse<OrderQuoteSupplierView>> => {
    try {
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<OrderQuoteSupplierView>>(`${orderQuotesResourceUri}/${orderQuoteId}/supplierview`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderQuote = async (orderQuoteId: string): Promise<EslavonApiResponse<OrderQuote>> => {
    try {
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<OrderQuote>>(`${orderQuotesResourceUri}/${orderQuoteId}`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const addOrderEmployee = async (orderNumber: number, employeeId: string): Promise<EslavonApiResponse<Employee>> => {
    try {
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<Employee>>(`${ordersResourceUri}/${orderNumber}/employees`, { orderNumber, employeeId });
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const removeOrderEmployee = async (orderNumber: number, employeeId: string): Promise<EslavonApiResponse<boolean>> => {
    try {
        const { data: result } = await coreApiInstance.delete<EslavonApiResponse<boolean>>(`${ordersResourceUri}/${orderNumber}/employees/${employeeId}`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const addOrderContact = async (orderNumber: number, contactId: string): Promise<EslavonApiResponse<Contact>> => {
    try {
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<Contact>>(`${ordersResourceUri}/${orderNumber}/contacts`, { orderNumber, contactId });
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const removeOrderContact = async (orderNumber: number, contactId: string): Promise<EslavonApiResponse<boolean>> => {
    try {
        const { data: result } = await coreApiInstance.delete<EslavonApiResponse<boolean>>(`${ordersResourceUri}/${orderNumber}/contacts/${contactId}`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderCustomer = async (orderNumber: number): Promise<EslavonApiResponse<OrderCustomer>> => {
    try {
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<OrderCustomer>>(`${ordersResourceUri}/${orderNumber}/customer`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const cancelOrder = async (payload: CancelOrderPayload): Promise<EslavonApiResponse<boolean>> => {
    try {
        var queryString = toQueryString(payload);
        const actionUri = `${ordersResourceUri}/${payload.orderNumber}${queryString}`;
        const { data: result } = await coreApiInstance.delete<EslavonApiResponse<boolean>>(actionUri);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderCancellationReasons = async (): Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        const { data: types } = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>(`${ordersResourceUri}/cancellationreasons`);
        return types;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderStatuses = async (): Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        const actionUri = `${ordersResourceUri}/statuses`;
        const { data: types } = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>(actionUri);
        return types;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderBoard = async (payload: GetOrderBoardPayload): Promise<EslavonPageableResponse<OrderBoard>> => {
    try {
        const queryString = toQueryString(payload);
        const actionUri = `${ordersResourceUri}/board${queryString}`;
        const { data: types } = await coreApiInstance.get<EslavonPageableResponse<OrderBoard>>(actionUri);
        return types;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getOrderPaymentCheckout = async (orderNumber: number): Promise<EslavonApiResponse<OrderPaymentCheckout>> => {
    try {
        const actionUri = `${ordersResourceUri}/${orderNumber}/checkout`;
        const { data: types } = await coreApiInstance.get<EslavonApiResponse<OrderPaymentCheckout>>(actionUri);
        return types;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}


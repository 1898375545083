import { ApartmentOutlined, ClockCircleOutlined, EnvironmentOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Col, List, Row, Space, Tag, Typography, message } from "antd";
import { getCustomerOrders } from "api/coreapi/customers";
import { getSupplierOrders } from "api/coreapi/suppliers";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "routes/paths";
import { OrderOverview } from "shared/models/orderOverviewModel";

interface OrderListProps {
    customerId?: string;
    supplierId?: string;
    canCreate?: boolean;
}

const { Text } = Typography;

export function OrderList(props: OrderListProps) {
    const [data, setData] = useState<OrderOverview[]>();
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 5, total: 0 });
    const navigate = useNavigate();


    useEffect(() => {
        const fetchCustomerOrders = async (customerId: string) => {
            setLoading(true);
            try {
                const result = await getCustomerOrders({ customerId, pageSize: pagination.pageSize, pageNumber: pagination.current });
                setData(result.result.data);
                setPagination({ ...pagination, total: result.result.totalCount });
            } catch (error) {
                message.error('Error obteniendo las órdenes.');

            } finally {
                setLoading(false);
            }
        };

        const fetchSupplierOrders = async (supplierId: string) => {
            setLoading(true);
            try {
                const result = await getSupplierOrders({ supplierId, pageSize: pagination.pageSize, pageNumber: pagination.current });
                setData(result.result.data);
                setPagination({ ...pagination, total: result.result.totalCount });
            } catch (error) {
                message.error('Error obteniendo las órdenes.');

            } finally {
                setLoading(false);
            }
        };

        if (props.customerId) {
            fetchCustomerOrders(props.customerId);
        }

        if (props.supplierId) {
            fetchSupplierOrders(props.supplierId);
        }


        // eslint-disable-next-line
    }, [pagination.pageSize, pagination.current]);


    return (
        <Space direction="vertical">
            <Row justify={"end"}>
                {props.canCreate &&
                    <Button type="dashed" onClick={() => navigate(paths.ORDER_CREATE)} size="small">
                        Nueva orden de servicio
                    </Button>}
            </Row>
            <List
                loading={loading}
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        setPagination({ ...pagination, current: page });
                    },
                    ...pagination,
                }}
                dataSource={data}
                renderItem={item => (
                    <List.Item

                        key={item.id}
                        actions={[
                            <Text><Tag color={'gray'}>{item.orderStatusName}</Tag></Text>,
                            <Button type="link" onClick={() => (navigate('/orders/' + item.orderNumber))}><EyeOutlined />Ver más...</Button>
                        ]}
                    >
                        <h3>{`Orden # ${item.orderNumber}`}</h3>
                        <Row justify='start' gutter={50}>
                            <Col>
                                <Space direction="vertical" size='large'>
                                    <Text><ApartmentOutlined /> Tipo:  {item.serviceTypeName}</Text>
                                    <Text><ClockCircleOutlined /> Fecha del servicio: {dayjs(item.startDate).format('DD/MM/YYYY hh:mm A')}</Text>
                                </Space>

                            </Col>
                            <Col>
                                <Space direction="vertical" size='large'>

                                    <Text><EnvironmentOutlined /> Dirección:  {item.addressLine1}, {item.cityName}, {item.stateName}</Text>
                                </Space>

                            </Col>


                        </Row>



                    </List.Item>
                )}
            />
        </Space>
    );
}
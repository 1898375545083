import { PageTitle } from "components/common/pageTitle/pageTitle";
import CustomerList from "features/customers/customerList";

export default function CustomerListPage() {

    return (
        <>
            <PageTitle>Clientes</PageTitle>
            <CustomerList></CustomerList>
        </>
    );

}
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import { cancelOrder, getOrderCancellationReasons } from 'api/coreapi/orders';
import LoadingSpinner from 'components/common/loadingSpinner/loadingSpinner';
import { OrderCancellationReasons } from 'constants/orders';
import { useState } from 'react';
import { EnumTypeModel } from 'shared/models/enumTypeModel';
import { CancelOrderPayload } from 'shared/models/payloads/cancelOrderPayload';
const { TextArea } = Input;

interface CancelOrderButtonProps {
    orderNumber: number;
    onCanceled?: () => void,
}

const CancelOrderButton = (props: CancelOrderButtonProps) => {


    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedReasonId, setSelectedReasonId] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cancellationReasons, setCancellationReasons] = useState<EnumTypeModel[]>();

    const getReasons = async () => {
        try {
            setLoading(true);
            const res = await getOrderCancellationReasons();
            setCancellationReasons(res.result);
        } catch (error: any) {
        } finally {
            setLoading(false);
        }
    };

    const startCancellation = async () => {
        if (!cancellationReasons || cancellationReasons.length <= 0) {
            getReasons();
        }
        setIsModalOpen(true);
    }

    const handleOk = async () => {
        form.validateFields()
            .then((values: any) => {
                const cancel = async (payload: CancelOrderPayload) => {
                    setSubmitting(true);
                    try {
                        const result = await cancelOrder(payload);
                        if (!result?.result) {
                            message.error("Ocurrió un error rechazando la solicitud de cotización.");
                            return;
                        }

                        if (props.onCanceled) {
                            props.onCanceled();
                        }

                        setIsModalOpen(false);
                    } catch (error) {
                        message.error('Error obteniendo las órdenes.');

                    } finally {
                        setSubmitting(false);
                    }
                };

                cancel({
                    cancellationReasonId: values.cancellationReasonId,
                    cancellationReasonDescription: values.cancellationReasonDescription,
                    orderNumber: props.orderNumber
                });
            })
            .catch(() => {

            });
    };

    return (
        <>
            <Modal
                title="Cancelar solicitud de servicio"
                open={isModalOpen}
                onOk={handleOk}
                confirmLoading={submitting}
                onCancel={() => setIsModalOpen(false)}
            >
                {loading
                    ? <LoadingSpinner></LoadingSpinner>
                    : <Form layout="vertical" form={form}>
                        <Form.Item name="cancellationReasonId"
                            label="Motivo"
                            rules={[{ required: true, message: 'Por favor ingrese el motivo de cancelación.' }]}>
                            <Select
                                options={cancellationReasons?.map((item) => ({
                                    value: item.id,
                                    label: item.displayName ?? item.name,
                                }))}
                                onChange={(item) => { setSelectedReasonId(item); }}>
                            </Select>
                        </Form.Item>
                        {selectedReasonId === OrderCancellationReasons.Other &&
                            <Form.Item name="cancellationReasonDescription"
                                label="Cuál?"
                                rules={[{ required: true, message: 'Por favor ingrese el motivo de cancelación.' }]}>
                                <TextArea maxLength={200} rows={2}></TextArea>
                            </Form.Item>
                        }

                    </Form>}

            </Modal>
            <Button type="primary" shape="circle"
                icon={<DeleteOutlined />}
                danger
                onClick={startCancellation}
                disabled={isModalOpen} /></>

    );
};

export default CancelOrderButton;
import { theme } from "antd";
import Sider from "antd/es/layout/Sider";
import Overlay from "components/common/overlay/overlay";
import { useResponsive } from "hooks/useResponsive";
import { useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { SiderContent } from "./siderContent";
import { SiderLogo } from "./siderLogo";

interface MainSiderProps {
    isCollapsed: boolean;
    setCollapsed: (isCollapsed: boolean) => void;
}

function MainSider(props: MainSiderProps) {

    const {
        token: { colorPrimary },
    } = theme.useToken();
    const auth = useAuth();
    const { isDesktop, mobileOnly, tabletOnly, isTablet } = useResponsive();
    const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);
    const toggleSider = () => props.setCollapsed(!props.isCollapsed);


    return (

        !auth.isAuthenticated
            ? <></>
            :
            <>
                <Sider className="mainSider"
                    trigger={null}
                    collapsed={!isDesktop && props.isCollapsed}
                    collapsedWidth={tabletOnly ? 80 : 0}
                    collapsible={isCollapsible}
                    width={260}
                    style={{
                        background: colorPrimary,
                        height: "110vh",
                        color: "white",
                        position: isDesktop ? 'unset' : 'fixed',
                        overflow: 'visible',
                        right: isTablet ? 'unset' : 0,
                        left: isTablet ? 0 : 'unset',
                        zIndex: 5,
                        minHeight: '100vh',
                        maxHeight: '100vh',

                    }}>
                    {isTablet && <SiderLogo isSiderCollapsed={props.isCollapsed} toggleSider={toggleSider}></SiderLogo>}
                    <SiderContent setCollapsed={props.setCollapsed}></SiderContent>
                    {/* <Row justify="space-between" align="middle" style={{ marginTop: 20, marginLeft: 10 }}>
                        {!props.isCollapsed && <Col span={18}><img alt="Eslavón" src="/images/logoWhite.svg" style={{ width: 180 }}></img></Col>}
                        <Col span={6} style={{ paddingLeft: 15 }}>
                            {React.createElement(props.isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: () => props.setCollapsed(!props.isCollapsed),
                                style: { fontSize: '20px' }

                            })}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={22} offset={1}>
                            <span>{auth?.user?.profile?.name}</span>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 40 }}>
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{ height: '100%', borderRight: 0, background: colorPrimary, color: "white" }}
                        >
                            <Restricted to="supplier.create">
                                <Menu.Item key="supplierCreate" icon={<UsergroupDeleteOutlined />}>
                                    <Link to="supplier/profile">Home</Link>
                                </Menu.Item>
                            </Restricted>

                            <Restricted to="employee.list">
                                <Menu.Item id="employees" key="employees" icon={<UsergroupDeleteOutlined />}>
                                    <Link to="employees">Colaboradores</Link>
                                </Menu.Item>
                            </Restricted>

                        </Menu>

                    </Row> */}
                </Sider>
                {mobileOnly && <Overlay onClick={toggleSider} show={!props.isCollapsed} />}
            </>

    );

}

export default MainSider;
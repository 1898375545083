import { GetRolesPayload } from "shared/models/payloads/getRolesPayload";
import { Role } from "shared/models/role";
import { User } from "shared/models/userModel";
import { toQueryString } from "utils/urlUtils";
import { identityApiInstance } from "../apiInstance";

const rolesResourceUri = "roles";

export const getRoles = async (payload: GetRolesPayload): Promise<Role[]> => {
    var queryString = toQueryString(payload);
    const actionUri = `${rolesResourceUri}${queryString}`;
    const { data: roles } = await identityApiInstance.get<User[]>(actionUri);
    
    return roles;
}
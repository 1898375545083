import { Button, Checkbox, Form, Input, message, Row, Select, Space, Typography } from "antd";
import { createUser, updateUser } from "api/identityapi/users";
import ErrorMessage from "components/errorMessage";
import RoleSelect from "components/roleSelect";
import { useEffect, useState } from "react";
import { CreateUserPayload } from "shared/models/payloads/createUserPayload";
import { EditUserPayload } from "shared/models/payloads/editUserPayload";
import { User } from "shared/models/userModel";

interface UserFormProps {
    supplierId?: string,
    customerId?: string,
    initialValue?: User,
    onCreate?: (user?: User) => void;
    onEdit?: (user?: User) => void;
    onCancel?: () => void;
}

function UserForm(props: UserFormProps) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [checked, setChecked] = useState<boolean>(props.initialValue?.receiveOrderNotifications ?? false);
    useEffect(() => form.resetFields(), [form, props.initialValue]);
    let isNew = props.initialValue?.id === undefined;

    const toggleChecked = () => {
        setChecked(!checked);
    };

    const onFinish = async (values: any) => {

        try {
            setLoading(true);
            let response: User = {} as User;

            if (isNew) {
                //Create user
                let payload = values as CreateUserPayload;
                response = await createUser({
                    ...payload,
                    supplierId: props.supplierId,
                    customerId: props.customerId,
                    receiveOrderNotifications: checked
                });
            } else {
                let payload = values as EditUserPayload;
                payload.receiveOrderNotifications = checked;
                response = await updateUser(payload);
            }

            message.success('Usuario guardado.');
            form.resetFields();

            if (isNew && props.onCreate) {
                props.onCreate(response);
            } else if (!isNew && props.onEdit) {
                props.onEdit(response);
            }
        } catch (error: any) {
            if (error) {
                ErrorMessage(error);
            }
            else {
                message.error('Ha ocurrido un error.');
            }

        } finally {
            setLoading(false);
        }
    };

    if (!props.customerId && !props.supplierId) {
        return (
            <Typography.Text type="danger">Invalid configuration for UserForm component. </Typography.Text>
        );
    }

    return (
        <Form
            form={form}
            name="userForm"
            layout="vertical"
            initialValues={{
                ...props.initialValue,
                roleId: props?.initialValue?.roles ? props?.initialValue?.roles[0]?.id : undefined,
                countryPhoneCode: props?.initialValue?.countryPhoneCode ?? '+57'
            }}
            onFinish={onFinish}>

            <Form.Item name='email' label='Correo electrónico' rules={[{ max: 256, required: true, type: 'email' }]}>
                <Input type="text" disabled={!isNew}></Input>
            </Form.Item>
            <Form.Item name={['firstName']} label="Nombres" rules={[{ required: true }]}>
                <Input maxLength={200}></Input>
            </Form.Item>
            <Form.Item name={['lastName']} label="Apellidos" rules={[{ required: true }]}>
                <Input maxLength={200}></Input>
            </Form.Item>
            <Space>
                <Form.Item name={['countryPhoneCode']} label="País" rules={[{ required: true }]}>
                    <Select style={{ minWidth: '80px' }}>
                        <Select.Option value={'57'}>+57</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name={['phoneNumber']} label="Número celular" rules={[{ required: true }]}>
                    <Input type="text" pattern="[0-9]{10}" maxLength={10}></Input>
                </Form.Item>

            </Space>
            <Form.Item>
                <Checkbox checked={checked} onChange={toggleChecked}>Recibir notificaciones de ordenes de servicio</Checkbox>
            </Form.Item>

            <Form.Item name="roleId" label="Rol" rules={[{ required: true }]}>
                <RoleSelect
                    isForCustomers={props.customerId !== undefined}
                    isForSuppliers={props.supplierId !== undefined} />
            </Form.Item>
            <Row justify={"end"}>
                <Form.Item>
                    <Space>
                        <Button type="default" onClick={() => {
                            form.resetFields();
                            if (props.onCancel) {
                                props.onCancel();
                            }
                        }}>
                            Cancelar
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Guardar
                        </Button>

                    </Space>

                </Form.Item>

            </Row>


        </Form>
    );
}

export default UserForm;

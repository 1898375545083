import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Form, Image, Input, InputNumber, message, Row, Space } from "antd";
import { editEmployee } from "api/coreapi/employees";
import ErrorMessage from "components/errorMessage";
import IdentificationNumbersSelect from "components/identificationNumbersSelect";
import { useState } from "react";
import { Employee } from "shared/models/employeeModel";
import { EditEmployeePayload } from "shared/models/payloads/editEmployeePayload";

interface EmployeePersonalFormProps {
    employee: Employee;
    canEdit: boolean,
}

function EmployeePersonalForm(props: EmployeePersonalFormProps) {

    const [loading, setLoading] = useState(false);
    const onFinish = async (values: any) => {

        try {
            setLoading(true);
            let payload = values as EditEmployeePayload;
            payload.employeeStatusId = props.employee.statusId;
            await editEmployee(payload);
            message.success('Colaborador actualizado correctamente');

        } catch (error: any) {
            if (error.errors) {
                message.error(ErrorMessage(error.errors));
            }
            else {
                message.error('Ha ocurrido un error.');
            }

        } finally {
            setLoading(false);
        }
    };

    return (
        <Form
            name="employeeForm"
            layout="vertical"
            onFinish={onFinish}
            disabled={!props.canEdit}
            initialValues={{ ...props.employee }}>
            <Form.Item hidden name="id">
                <Input type="hidden" />
            </Form.Item>
            <Row gutter={24} justify="end">
                <Col order={2} style={{ marginBottom: 20 }} xs={{ span: 24, order: 1 }} sm={{ span: 12 }}>
                    <Row justify="center">
                        <Space style={{ background: "whitesmoke", padding: 5 }}>
                            {props.employee.profilePictureUrl
                                ? <Image
                                    width={150}
                                    src={props.employee.profilePictureUrl} />
                                : <Avatar size={150} icon={<UserOutlined />} />
                            }
                        </Space>
                    </Row>
                </Col>
                <Col order={1} xs={{ span: 24, order: 2 }} sm={{ span: 12 }}>
                    <Row justify={"start"}>
                        <Col span={24}>
                            <Form.Item name="identificationNumberTypeId" label="Tipo de identificación" rules={[{ required: true }]}>
                                <IdentificationNumbersSelect />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="identificationNumber" label="Número identificación" rules={[{ required: true }]}>
                                <InputNumber controls={false} style={{ width: 200 }} maxLength={15} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

            </Row>
            <Row gutter={24}>
                <Col md={{ span: 24 }} lg={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    <Form.Item name={['firstName']} label="Nombres" rules={[{ required: true }]}>
                        <Input maxLength={200}></Input>
                    </Form.Item>
                </Col>
                <Col md={{ span: 24 }} lg={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    <Form.Item name={['lastName']} label="Apellidos" rules={[{ required: true }]}>
                        <Input maxLength={200}></Input>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col md={{ span: 24 }} lg={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    <Form.Item name={['phoneNumber']} label="Número celular">
                        <Input type="number"></Input>
                    </Form.Item>
                </Col>
                <Col md={{ span: 24 }} lg={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    <Form.Item name={['emailAddress']} label="Correo electrónico" rules={[{ max: 200 }]}>
                        <Input type="email"></Input>
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col md={{ span: 24 }} lg={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    {/* <Form.Item name="employeeStatusId" label="Estado" rules={[{ required: true }]}>
                        <EmployeeStatusSelect />
                    </Form.Item> */}
                </Col>
                <Col md={{ span: 24 }} lg={{ span: 12 }} sm={{ span: 12 }}>

                </Col>
            </Row>

            {
                props.canEdit &&
                <Row justify={"end"}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Guardar
                        </Button>
                    </Form.Item>
                </Row>
            }
        </Form>
    );
}

export default EmployeePersonalForm;
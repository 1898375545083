export enum PaymentTypes {
    Cash = 1,
    Transfer = 2
}

export enum OrderPaymentStatuses {
    Pending = 1,
    PartiallyPaid = 2,
    Paid = 3
}

export enum WompiTransactionStatuses {
    Approved = 'APPROVED',
    Declined = 'DECLINED',
    Voided = 'VOIDED',
    Error = 'ERROR'
}

export enum PaymentStatus {
    Pending = 1,
    Approved = 2,
    Declined = 3,
    Error = 4,
    Voided = 5
}
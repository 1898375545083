import { Descriptions, Alert } from "antd";
import dayjs from 'dayjs';
import { useResponsive } from "hooks/useResponsive";
import { OrderQuoteCustomerView } from "shared/models/orderQuoteCustomerViewModel";
import { formatMoney } from "utils/moneyUtils";

interface OrderQuoteCustomerOverviewProps {
    orderQuoteCustomerView: OrderQuoteCustomerView,
}

const OrderQuoteCustomerOverview: React.FC<OrderQuoteCustomerOverviewProps> = ({ orderQuoteCustomerView }) => {
    const { mobileOnly } = useResponsive();

    return (
        <div style={{ background: '#f7f7f7', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            {orderQuoteCustomerView &&
                <Descriptions column={{ xs: 1, sm: 1, md: 2 }} labelStyle={{ minWidth: 130 }} bordered={mobileOnly ? false : true}>
                    <Descriptions.Item label="Fecha cotización">{dayjs(orderQuoteCustomerView?.createdOn).format('DD/MM/YYYY hh:mm A')}</Descriptions.Item>
                    <Descriptions.Item label="Costo servicio">{formatMoney((orderQuoteCustomerView.total - orderQuoteCustomerView.totalVat).toString())}</Descriptions.Item>
                    <Descriptions.Item label="IVA">{formatMoney(orderQuoteCustomerView.totalVat.toString())}</Descriptions.Item>
                    <Descriptions.Item label="Total" span={2}>{formatMoney(orderQuoteCustomerView.total.toString())}</Descriptions.Item>
                    {orderQuoteCustomerView?.comments &&
                        <Descriptions.Item span={2}><Alert type="info" message='Información adicional del proveedor' description={orderQuoteCustomerView.comments} showIcon /></Descriptions.Item>
                    }
                </Descriptions>
            }
        </div>
    );
}

export default OrderQuoteCustomerOverview;

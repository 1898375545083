import { Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PaymentStatus } from 'constants/payments';
import { useResponsive } from 'hooks/useResponsive';
import { Payment } from 'shared/models/paymentModel';
import { formatDate } from 'utils/dateUtils';
import { formatMoney } from "utils/moneyUtils";
const { Title } = Typography;

interface PaymentListProps {
    payments: Payment[];
}

const PaymentList = (props: PaymentListProps) => {
    const { isTablet } = useResponsive();

    let columns: ColumnsType<Payment> = [];

    if (isTablet) {
        columns.push({
            title: 'Transacción',
            dataIndex: 'transactionId',
            key: 'transactionId',
        });
    }

    columns.push({
        title: 'Fecha',
        dataIndex: 'finalizedAt',
        key: 'finalizedAt',
        render: (finalizedAt: string) => finalizedAt ? formatDate(new Date(finalizedAt)) : '-',
    },
        {
            title: 'Monto',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: string) => formatMoney(amount),
        },);

    if (isTablet) {
        columns.push({
            title: 'Referencia',
            dataIndex: 'reference',
            key: 'reference',
        },);
    }

    columns.push({
        title: 'Estado',
        dataIndex: 'paymentStatusName',
        key: 'paymentStatusName',
        render: (paymentStatusName: string, record: Payment) => (
            <Tag color={record.paymentStatusId === PaymentStatus.Approved ? 'green' : 'red'}>{paymentStatusName}</Tag>
        ),
    });

    return <div style={{ background: '#f7f7f7', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <Title level={5} style={{ textAlign: 'center', marginBottom: '20px' }}>Lista de Pagos</Title>
        <Table dataSource={props.payments} columns={columns} />
    </div>
};

export default PaymentList;

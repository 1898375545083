import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { City } from "shared/models/cityModel";
import { coreApiInstance } from "../apiInstance";

const citiesResourceUri = "cities";

export const getCities  = async (name?: string) : Promise<EslavonApiResponse<City[]>> => {
    try {
        let actionUri = citiesResourceUri;
        
        if(name)
        {
            actionUri = actionUri + '?name='+name;
        }

        const {data: cities} = await coreApiInstance.get<EslavonApiResponse<City[]>>(actionUri);
        return cities;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getCitiesWithCoverage  = async () : Promise<EslavonApiResponse<City[]>> => {
    try {        
        const {data: cities} = await coreApiInstance.get<EslavonApiResponse<City[]>>(`${citiesResourceUri}/coverage`);
        return cities;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}
import { Space, Typography } from 'antd';

const { Text } = Typography;

function ErrorMessage(errors: string[]) {
    const fontSize: string = errors.length > 5 ? 'x-small' : 'small';

    return (
        <Space direction='vertical'>
            {errors.map((error, index) => <Text key={index} style={{ fontSize: fontSize }}> - {error}</Text>)}
        </Space>

    );
}

export default ErrorMessage;
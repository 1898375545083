import { Descriptions, message } from "antd";
import { getOrderCustomer } from "api/coreapi/orders";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import OrderContactList from "features/contacts/orderContactList";
import { useEffect, useState } from "react";
import { OrderCustomer } from "shared/models/orderCustomerModel";

interface OrderCustomerDetailProps {
    orderNumber: number,
    canEdit?: boolean
}

const OrderCustomerDetail = (props: OrderCustomerDetailProps) => {

    const [orderCustomer, setOrderCustomer] = useState<OrderCustomer>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getInfo = async () => {
            try {
                setLoading(true);
                const res = await getOrderCustomer(props.orderNumber);
                setOrderCustomer(res.result);
            } catch (error: any) {
                message.error('Error cargando información del cliente.');
            }
            finally {
                setLoading(false);
            }
        };

        getInfo();
    }, [props]);

    if (loading) {
        return <LoadingSpinner></LoadingSpinner>
    }

    if (!orderCustomer) {
        return <></>
    }

    return (
        <>
            <Descriptions title="Información del cliente">
                {orderCustomer?.name &&
                    <Descriptions.Item label="Nombre">{orderCustomer.name}</Descriptions.Item>}
                <Descriptions.Item label="Tipo identificación">{orderCustomer.identificationNumberTypeName}</Descriptions.Item>
                <Descriptions.Item label="# Identificación">{orderCustomer.identificationNumber}</Descriptions.Item>
            </Descriptions>

            <OrderContactList
                orderNumber={props.orderNumber}
                customerId={orderCustomer?.id}
                canEdit={props.canEdit}
                orderContacts={orderCustomer?.orderContacts} />
        </>
    );

}

export default OrderCustomerDetail;
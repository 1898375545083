import { PageTitle } from "components/common/pageTitle/pageTitle";
import SupplierList from "features/suppliers/supplierList";

export default function SupplierListPage(){

    return (
        <>
            <PageTitle>Proveedores</PageTitle>
            <SupplierList></SupplierList>
        </>
        
    );

}
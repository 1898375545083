import { Select } from "antd";
import { getServiceTypes } from "api/coreapi/services";
import { useEffect, useState } from "react";
import { ServiceType } from "shared/models/serviceTypeModel";

interface ServiceTypesSelectProps {
    value?: number;
    mode?: 'multiple' | 'tags' | undefined;
    style?: React.CSSProperties | undefined;
    maxTagCount?: number | 'responsive' | undefined;
    onChange?: (value?: number) => void;
}

const ServiceTypesSelect: React.FC<ServiceTypesSelectProps> = (props) => {
    const {
        value,
        mode,
        style,
        onChange
    } = props;

    var [types, setTypes] = useState<ServiceType[]>();
    const triggerChange = (changedValue?: number) => {
        onChange?.(changedValue);
    };

    const onSelectedItemChange = (newType: number) => {
        triggerChange(newType);
    };

    useEffect(() => {

        const getInfo = async () => {
            try {
                const res = await getServiceTypes();
                setTypes(res.result);
            } catch (error: any) {
            }
        };

        getInfo();
    }, []);


    return (
        <Select
            style={style}
            mode={mode}
            defaultValue={value}
            onChange={onSelectedItemChange}
            maxTagCount={props.maxTagCount}
            showArrow={true}
            options={types?.map((item) => ({
                value: item.id.toString(),
                label: item.displayName ?? item.name,
            }))}
        />

    );
}

export default ServiceTypesSelect;
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message } from 'antd';
import { updateEmployeeLock } from 'api/coreapi/employees';
import { useState } from 'react';
import { Employee } from 'shared/models/employeeModel';

interface unlockEmployeeButtonProps {
    employee: Employee;
    onUnlocked?: () => void,
}

const UnlockEmployeeButton = (props: unlockEmployeeButtonProps) => {
    const [submitting, setSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = async () => {
        setSubmitting(true);
        try {
            const result = await updateEmployeeLock({
                id: props.employee.id,
                lockReasonId: undefined,
            });
            if (!result?.result) {
                message.error("Ocurrió un error desbloqueando el colaborador.");
                return;
            }

            if (props.onUnlocked) {
                props.onUnlocked();
            }

            setIsModalOpen(false);
        } catch (error) {
            message.error('Error desbloqueando el colaborador.');

        } finally {
            setSubmitting(false);
        }


    };

    return (

        <Popconfirm
            title="Desbloquear colaborador"
            description="Seguro desea desbloquear el colaborador?. A partir de este momento el colaborador podrá ser usado por el proveedor."
            open={isModalOpen}
            onConfirm={handleOk}
            okButtonProps={{ loading: submitting }}
            okText="Desbloquear"
            onCancel={() => setIsModalOpen(false)}
        >
            <Button style={{ color: "green" }} icon={<CheckCircleOutlined />}
                onClick={() => setIsModalOpen(true)}>Desbloquear colaborador</Button>
        </Popconfirm>
    );
};

export default UnlockEmployeeButton;
import { coreApiInstance } from "api/apiInstance";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { Contact } from "shared/models/contactModel";
import { CreateContactPayload } from "shared/models/payloads/createContactPayload";
import { EditContactPayload } from "shared/models/payloads/editContactPayload";

const contactsResourceUri = 'contacts';

export const createContact = async (payload: CreateContactPayload): Promise<EslavonApiResponse<Contact>> => {
    try {
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<Contact>>(contactsResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const editContact = async (payload: EditContactPayload): Promise<EslavonApiResponse<Contact>> => {
    try {
        const { data: result } = await coreApiInstance.put<EslavonApiResponse<Contact>>(contactsResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const deleteContact = async (contactId: string): Promise<EslavonApiResponse<boolean>> => {
    try {
        const { data: result } = await coreApiInstance.delete<EslavonApiResponse<boolean>>(`${contactsResourceUri}/${contactId}`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}


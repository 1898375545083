import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, InputNumber, message, Row, Tag, theme, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { createCustomer, editCustomer } from 'api/coreapi/customers';
import { createDocument } from 'api/coreapi/documents';
import CitiesAutoCompleteSelect, { ConvertToCityValue } from 'components/citiesAutoCompleteSelect';
import ErrorMessage from 'components/errorMessage';
import IdentificationNumbersSelect from 'components/identificationNumbersSelect';
import { CustomerStatuses } from 'constants/customers';
import { DocumentTypes } from 'constants/documents';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import { CustomerOverview } from 'shared/models/customerOverviewModel';
import { CreateDocumentPayload } from 'shared/models/payloads/createDocumentPayload';
import { UpsertCustomerPayload } from 'shared/models/payloads/upsertCustomerPayload';


interface CustomerFormProps {
    customer?: CustomerOverview
}
const validDocTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

function getBadgeColor(customerStatusId: number): string {

    let color = '';

    switch (customerStatusId) {
        case CustomerStatuses.Active:
            color = 'green';
            break;
        case CustomerStatuses.Inactive:
            color = 'red';
            break;
        default:
            color = 'orange'
            break;
    }

    return color;
}

const CustomerForm: React.FC<CustomerFormProps> = (props) => {

    const navigate = useNavigate();
    const { customer } = props;
    const [identificationNumberTypeId, setidentificationNumberTypeId] = useState();
    const [loading, setLoading] = useState(false);
    const [rutFileList, setRutFileList] = useState<UploadFile[]>([]);
    const [identificationFileList, setIdentificationFileList] = useState<UploadFile[]>([]);
    const [form] = Form.useForm();
    const [isNewCustomer] = useState(customer?.id === undefined);
    const {
        token: { colorInfo },
    } = theme.useToken();

    useEffect(() => form.resetFields(), [props.customer, form]);

    const uploadProps: UploadProps = {
        accept: '.jpg,.png,.pdf',
        listType: 'picture-card',
        showUploadList: { showPreviewIcon: false },
    };

    const onFinish = async (values: any) => {

        let requestPayload: UpsertCustomerPayload = {
            customerId: values.customerId,
            identificationNumber: values.identificationNumber?.toString(),
            identificationNumberTypeId: values.identificationNumberTypeId,
            name: values.name,
            contact: values.contact,
            address: {
                id: values.address.id,
                address1: values.address.address1,
                cityId: values.address.cityId[0].value
            }
        }

        if (isNewCustomer && rutFileList.length <= 0) {
            form.setFields([{ name: 'rutFile', errors: ['Requerido'] }]);
            return;
        }

        try {
            setLoading(true);
            message.info('Guardando perfil del cliente');

            var result = isNewCustomer
                ? await createCustomer(requestPayload)
                : await editCustomer(requestPayload);

            if (result.result?.id) {
                message.success('Información actualizada correctamente.');

                if (isNewCustomer) {
                    message.info('Cargando documentos. Esto puede tardar un par de minutos.');
                    await sendCustomerDocument(result.result?.id, DocumentTypes.Rut, rutFileList, setRutFileList);

                    if (identificationFileList && identificationFileList.length > 0) {
                        await sendCustomerDocument(result.result?.id, DocumentTypes.LegalRepresentativeIdentification, identificationFileList, setIdentificationFileList);
                    }

                    navigate(paths.CUSTOMER_PROFILE);
                }
            }

        } catch (error: any) {
            if (error.errors) {
                message.error(ErrorMessage(error.errors));
            }
            else {
                message.error('Ha ocurrido un error.');
            }
        }
        finally {
            setLoading(false);
        }
    };

    const onIdentificationNumberTypeChange = (value: any) => {
        setidentificationNumberTypeId(value);
    }

    const sendCustomerDocument = async (customerId: string,
        documentTypeId: number,
        fileList: UploadFile<any>[],
        setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>) => {
        let documentCreated = false;

        setFileList(fileList.map(item => {
            return { ...item, status: 'uploading', percent: 10 }
        }));

        const payload: CreateDocumentPayload = {
            file: fileList[0] as RcFile,
            name: fileList[0].name ?? '',
            documentTypeId: documentTypeId,
            customerId: customerId
        };

        try {
            documentCreated = (await createDocument(payload)).isSuccess ?? false;
        }
        catch {
            setFileList(fileList.map(item => {
                return { ...item, status: 'error', percent: 0 }
            }));
            message.error('Error cargando el documento.');
        }

        if (documentCreated) {
            setFileList(fileList.map(item => {
                return { ...item, status: 'success', percent: 0 }
            }));
        }
    }

    return (
        <Form
            form={form}
            name="customerForm"
            layout="vertical"
            onFinish={onFinish}
            initialValues={customer ? {
                customerId: customer.id,
                identificationNumber: customer.identificationNumber,
                identificationNumberTypeId: customer.identificationNumberTypeId,
                name: customer.name,
                address: {
                    id: customer.address?.id,
                    address1: customer.address?.address1,
                    cityId: customer.address?.cityId
                        ? [ConvertToCityValue({
                            ...customer.address,
                            id: customer.address.cityId.toString(),
                            displayName: customer.address.cityName,
                            name: customer.address.cityName
                        })]
                        : null
                },
                contact: {
                    id: customer.contact?.id,
                    firstName: customer.contact?.firstName,
                    lastName: customer.contact?.lastName,
                    phoneNumber: customer.contact?.phoneNumber,
                    emailAddress: customer.contact?.emailAddress,
                    jobTitle: customer.contact?.jobTitle
                }
            } : {}}

        >
            <Divider orientation='left' style={{ borderColor: colorInfo, marginBottom: 30 }}>Información general</Divider>
            <Form.Item hidden name="customerId">
                <Input type="hidden" />
            </Form.Item>
            <Row gutter={24} align='middle'>
                <Col xs={{ order: 2, span: 24 }} sm={{ order: 2, span: 12 }} md={{ order: 1 }} lg={{ order: 1, span: 8 }}>
                    <Form.Item name="identificationNumberTypeId" label="Tipo de identificación" rules={[{ required: true }]}>
                        <IdentificationNumbersSelect onChange={onIdentificationNumberTypeChange} />
                    </Form.Item>
                </Col>
                <Col xs={{ order: 3, span: 24 }} sm={{ order: 3, span: 12 }} md={{ order: 2 }} lg={{ order: 2, span: 8 }}>
                    <Form.Item name="identificationNumber" label="Número identificación" rules={[{ required: true }]}>
                        <InputNumber controls={false} style={{ width: '100%' }} maxLength={15} />
                    </Form.Item>
                </Col>
                <Col xs={{ order: 1, span: 24 }} sm={{ order: 1, span: 24 }} md={{ order: 3 }} lg={{ order: 3, span: 8 }}>
                    {props.customer?.customerStatusId && <Row justify='end'>
                        <Tag color={getBadgeColor(props.customer?.customerStatusId)}>{props.customer?.customerStatusName}</Tag>
                    </Row>}

                </Col>

            </Row>

            <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
                <Input></Input>
            </Form.Item>


            <Row gutter={24}>
                <Col lg={{ span: 16 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    <Form.Item hidden name={['address', 'id']}>
                        <Input type="hidden" />
                    </Form.Item>

                    <Form.Item name={['address', 'address1']} label="Dirección" rules={[{ required: true }]}>
                        <Input type='text' />
                    </Form.Item>
                </Col>
                <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    <Form.Item name={['address', 'cityId']} label="Ciudad" rules={[{ required: true }]}>
                        <CitiesAutoCompleteSelect max={1} />
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation='left' style={{ borderColor: colorInfo, margin: '30px 0px' }}>Información de contacto</Divider>

            <Row gutter={24}>
                <Col lg={{ span: 8 }}>
                    <Form.Item hidden name={['contact', 'id']}>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name={['contact', 'firstName']} label="Nombres" rules={[{ required: true }]}>
                        <Input maxLength={200}></Input>
                    </Form.Item>
                </Col>
                <Col lg={{ span: 8 }}>
                    <Form.Item name={['contact', 'lastName']} label="Apellidos">
                        <Input maxLength={200}></Input>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col lg={{ span: 8 }}>
                    <Form.Item name={['contact', 'phoneNumber']} label="Número celular" rules={[{ required: true }]}>
                        <Input type="number"></Input>
                    </Form.Item>
                </Col>
                <Col lg={{ span: 16 }}>
                    <Form.Item name={['contact', 'emailAddress']} label="Correo electrónico" rules={[{ required: true }]}>
                        <Input type="email"></Input>
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Form.Item name={['contact', 'jobTitle']} label="Cargo" rules={[{ required: true }]}>
                    <Input maxLength={200}></Input>
                </Form.Item>

            </Row>

            {isNewCustomer &&
                <>
                    <Divider orientation='left' style={{ borderColor: colorInfo, marginTop: 50 }}>Documentos</Divider>
                    <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                            <Form.Item name="rutFile" label="RUT" rules={[{ required: true }]}>
                                <Upload {...uploadProps}
                                    maxCount={1}
                                    beforeUpload={(file) => {
                                        const isValidType = validDocTypes.includes(file.type);
                                        if (!isValidType) {
                                            message.error(`${file.name} no es un tipo de archivo válido.`);
                                            return false;
                                        }
                                        setRutFileList([...rutFileList, file]);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        const index = rutFileList.indexOf(file);
                                        const newFileList = rutFileList.slice();
                                        newFileList.splice(index, 1);
                                        setRutFileList(newFileList);
                                    }}
                                    fileList={rutFileList}
                                    className="upload-list-inline">
                                    {rutFileList.length === 0 && <Button icon={<PlusOutlined />}></Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                        {
                            identificationNumberTypeId !== 2 &&
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <Form.Item name="identificationFile" label="Documento identidad" rules={[{ required: false }]}>
                                    <Upload
                                        {...uploadProps}
                                        beforeUpload={(file) => {
                                            const isValidType = validDocTypes.includes(file.type);
                                            if (!isValidType) {
                                                message.error(`${file.name} no es un tipo de archivo válido.`);
                                                return false;
                                            }
                                            setIdentificationFileList([...identificationFileList, file]);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            const index = identificationFileList.indexOf(file);
                                            const newFileList = identificationFileList.slice();
                                            newFileList.splice(index, 1);
                                            setIdentificationFileList(newFileList);
                                        }}
                                        fileList={identificationFileList}
                                        className="upload-list-inline">
                                        {identificationFileList.length === 0 && <Button icon={<PlusOutlined />}></Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                </>
            }

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                </Button>
            </Form.Item>
        </Form>

    );

}

export default CustomerForm;
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { Employee } from "shared/models/employeeModel";
import { EnumTypeModel } from "shared/models/enumTypeModel";
import { UpsertSupplierPayload } from "shared/models/payloads/upsertSupplierPayload";
import { SupplierOverview } from "shared/models/supplierOverviewModel";
import { coreApiInstance } from "../apiInstance";
import { Document } from 'shared/models/documentModel';
import { Certification } from "shared/models/certificationModel";
import { Contact } from "shared/models/contactModel";
import { Address } from "shared/models/addressModel";
import { EslavonPageableResponse } from "shared/models/apiResponses/eslavonPageableResponse";
import { GetSuppliersBySearchPayload } from "shared/models/payloads/getSuppliersBySearchPayload";
import { Supplier } from "shared/models/supplierModel";
import { UpdateSupplierStatusPayload } from "shared/models/payloads/updateSupplierStatusPayload";
import { GetSupplierOrdersPayload } from "shared/models/payloads/getSupplierOrdersPayload";
import { OrderOverview } from "shared/models/orderOverviewModel";
import { SortingDirection } from "constants/common";
import { toQueryString } from "utils/urlUtils";
import { GetSupplierServiceQuotesPayload } from "shared/models/payloads/GetSupplierServiceQuotesPayload";
import { ServiceQuote } from "shared/models/serviceQuoteModel";

const suppliersResourceUri = 'suppliers';

export const getSupplierById = async (supplierId: string): Promise<EslavonApiResponse<Supplier>> => {
    try {
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<Supplier>>(`${suppliersResourceUri}/${supplierId}`);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getMySuppliers = async (): Promise<EslavonApiResponse<SupplierOverview[]>> => {
    try {
        const { data: suppliers } = await coreApiInstance.get<EslavonApiResponse<SupplierOverview[]>>(`${suppliersResourceUri}/getmysuppliers`);
        return suppliers;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getSuppliersBySearch = async (payload: GetSuppliersBySearchPayload): Promise<EslavonPageableResponse<SupplierOverview>> => {
    try {
        const { data: suppliers } = await coreApiInstance.post<EslavonPageableResponse<SupplierOverview>>(`${suppliersResourceUri}/search`, payload);
        return suppliers;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getSupplierTypes = async (): Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        const { data: types } = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>(`${suppliersResourceUri}/types`);
        return types;
    }
    catch (error: any) {
        if (error.response.data) {
            return error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const createSupplier = async (payload: UpsertSupplierPayload): Promise<EslavonApiResponse<SupplierOverview>> => {
    try {
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<SupplierOverview>>(suppliersResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const editSupplier = async (payload: UpsertSupplierPayload): Promise<EslavonApiResponse<SupplierOverview>> => {
    try {
        const { data: result } = await coreApiInstance.put<EslavonApiResponse<SupplierOverview>>(suppliersResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getEmployees = async (supplierId: string, includeLockedEmployees: boolean = false): Promise<EslavonApiResponse<Employee[]>> => {
    try {
        var query = toQueryString({ includeLockedEmployees });
        const { data: employees } = await coreApiInstance.get<EslavonApiResponse<Employee[]>>(`${suppliersResourceUri}/${supplierId}/employees${query}`);
        return employees;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getSupplierDocuments = async (supplierId: string): Promise<EslavonApiResponse<Document[]>> => {
    try {
        const actionUri = `${suppliersResourceUri}/${supplierId}/documents`;
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<Document[]>>(actionUri);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getSupplierCertifications = async (supplierId: string): Promise<EslavonApiResponse<Certification[]>> => {
    try {
        const actionUri = `${suppliersResourceUri}/${supplierId}/certifications`;
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<Certification[]>>(actionUri);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getSupplierContacts = async (supplierId: string): Promise<EslavonApiResponse<Contact[]>> => {
    try {
        const actionUri = `${suppliersResourceUri}/${supplierId}/contacts`;
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<Contact[]>>(actionUri);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getSupplierAddresses = async (supplierId: string): Promise<EslavonApiResponse<Address[]>> => {
    try {
        const actionUri = `${suppliersResourceUri}/${supplierId}/addresses`;
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<Address[]>>(actionUri);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getSupplierStatuses = async (): Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        const actionUri = `${suppliersResourceUri}/statuses`;
        const { data: types } = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>(actionUri);
        return types;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getSupplierOrders = async (payload: GetSupplierOrdersPayload): Promise<EslavonPageableResponse<OrderOverview>> => {
    try {
        payload.orderByDirection = SortingDirection.Descending;
        const actionUri = `${suppliersResourceUri}/${payload.supplierId}/orders${toQueryString(payload)}`;
        const { data: result } = await coreApiInstance.get<EslavonPageableResponse<OrderOverview>>(actionUri);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const getSupplierServiceQuotes = async (payload: GetSupplierServiceQuotesPayload): Promise<EslavonApiResponse<ServiceQuote[]>> => {
    try {
        const actionUri = `${suppliersResourceUri}/${payload.supplierId}/servicequotes${toQueryString(payload)}`;
        const { data: result } = await coreApiInstance.get<EslavonApiResponse<ServiceQuote[]>>(actionUri);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}

export const updateSupplierStatus = async (payload: UpdateSupplierStatusPayload): Promise<EslavonApiResponse<boolean>> => {
    try {
        const actionUri = `${suppliersResourceUri}/${payload.supplierId}/status`;
        const { data: result } = await coreApiInstance.put<EslavonApiResponse<boolean>>(actionUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Avatar, Button, Descriptions, List, Popconfirm, Select, Space, Typography, message } from 'antd';
import { addOrderEmployee, removeOrderEmployee } from 'api/coreapi/orders';
import { getEmployees } from 'api/coreapi/suppliers';
import LoadingSpinner from 'components/common/loadingSpinner/loadingSpinner';
import OrderContactList from 'features/contacts/orderContactList';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import { Employee } from 'shared/models/employeeModel';
import { OrderSupplier } from 'shared/models/orderSupplierModel';

interface OrderSupplierDetailProps {
    orderSupplier: OrderSupplier,
    orderNumber: number,
    canEdit?: boolean
}

const OrderSupplierDetail = (props: OrderSupplierDetailProps) => {
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(props.orderSupplier.orderEmployees);
    const [supplierEmployees, setSupplierEmployees] = useState<Employee[]>();
    const [loading, setLoading] = useState(false);
    const [updatingEmployeeList, setUpdatingEmployeeList] = useState(false);

    useEffect(() => {
        const getSupplierEmployees = async () => {
            try {
                setLoading(true);
                const res = await getEmployees(props.orderSupplier.id);
                setSupplierEmployees(res.result);
            } catch (error: any) {
                message.error('Error cargando colaboradores del proveedor.');
            }
            finally {
                setLoading(false);
            }
        };

        if (props.canEdit) {
            getSupplierEmployees();
        }

    }, [props]);

    const availableEmployees = supplierEmployees?.filter(
        (employee: Employee) => !selectedEmployees.some((e: Employee) => e.id === employee.id)
    );

    const handleAddEmployee = async () => {
        if (selectedEmployee !== '' && supplierEmployees) {
            // Verificar si el empleado ya ha sido agregado previamente
            const employee = supplierEmployees.find((e: Employee) => e.id === selectedEmployee);
            if (employee && !selectedEmployees.some((e: Employee) => e.id === employee.id)) {
                setUpdatingEmployeeList(true);
                try {
                    const response = await addOrderEmployee(props.orderNumber, employee.id);

                    if (response.isSuccess && response.result) {
                        setSelectedEmployees([...selectedEmployees, response.result]);
                    }
                }
                catch (error) {
                    message.error("Error agregando colaborador a la orden de servicio");
                }
                finally {
                    setUpdatingEmployeeList(false);
                }
            }
            setSelectedEmployee('');
        }
    };

    const handleRemoveEmployee = async (employee: Employee) => {
        try {
            setUpdatingEmployeeList(true);
            const result = await removeOrderEmployee(props.orderNumber, employee.id);

            if (result.isSuccess) {
                setSelectedEmployees(selectedEmployees.filter((e) => e.id !== employee.id));
            }
        } catch (error) {
            message.error("Ha ocurrido un error eliminando el colaborador de la orden.");
        }
        finally {
            setUpdatingEmployeeList(false);
        }
    };

    if (loading) {
        return <LoadingSpinner></LoadingSpinner>
    }

    return (
        <>
            <Descriptions title="Información del proveedor">
                {props.orderSupplier.name &&
                    <Descriptions.Item label="Nombre">{props.orderSupplier.name}</Descriptions.Item>}
                <Descriptions.Item label="Tipo proveedor">{props.orderSupplier.supplierTypeName}</Descriptions.Item>
                <Descriptions.Item label="Tipo identificación">{props.orderSupplier.identificationNumberTypeName}</Descriptions.Item>
                <Descriptions.Item label="# Identificación">{props.orderSupplier.identificationNumber}</Descriptions.Item>
            </Descriptions>

            <OrderContactList
                orderNumber={props.orderNumber}
                supplierId={props.orderSupplier.id}
                canEdit={props.canEdit}
                orderContacts={props.orderSupplier.orderContacts} />

            <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Title level={4} >Colaboradores</Typography.Title>
                <Typography.Text type="secondary">Personal para la prestación del servicio</Typography.Text>
                {(supplierEmployees?.length ?? 0) === 0 && props.canEdit && <Alert
                    message="¡Atención proveedor!"
                    description={
                        <div>
                            Para seleccionar los colaboradores que prestarán el servicio, por favor registralos <Link to={paths.EMPLOYEES}>aquí</Link>.<br />
                            Ingresa los detalles de tus empleados para avanzar con la asignación del servicio.
                            <br />
                            <br />
                            ¡Gracias por tu colaboración!
                        </div>
                    }
                    type="warning"
                />}
                {
                    <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                        {supplierEmployees?.length && props.canEdit &&
                            <>
                                <Select
                                    value={selectedEmployee}
                                    onChange={(value) => setSelectedEmployee(value)}
                                    placeholder="Seleccionar empleado"
                                    style={{ width: '100%', marginBottom: '10px' }}
                                    options={availableEmployees?.map((item) => ({
                                        value: item.id.toString(),
                                        label: `${item.firstName} ${item.lastName}`
                                    }))}
                                >
                                </Select>

                                <Button onClick={handleAddEmployee}
                                    loading={updatingEmployeeList}
                                    style={{ width: '100%', marginBottom: '10px' }}>
                                    Agregar Empleado
                                </Button>
                            </>
                        }

                        <List
                            dataSource={selectedEmployees}
                            renderItem={(employee) => (
                                <List.Item key={employee.id}
                                    actions={[
                                        props.canEdit
                                            ?
                                            <Popconfirm
                                                title="Atención"
                                                description="¿Seguro deseas eliminar el colaborador de ésta orden de servicio?"
                                                onConfirm={() => handleRemoveEmployee(employee)}
                                                okButtonProps={{ loading: updatingEmployeeList }}
                                            >
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                />
                                            </Popconfirm>
                                            : null
                                    ]}
                                    style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>

                                    <Avatar
                                        src={employee.profilePictureUrl}
                                        size={60}
                                        icon={(employee.profilePictureUrl ? null : <UserOutlined />)} />
                                    <div style={{ marginLeft: '10px' }}>
                                        <div>{`${employee.firstName} ${employee.lastName}`}</div>
                                        <div>{employee.identificationNumberTypeName}: {employee.identificationNumber}</div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                }


            </Space>
        </>
    );
};

export default OrderSupplierDetail;
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PATHS from "../routes/paths";

export default function SignoutCallbackPage(){
    const navigate = useNavigate();
    useEffect(() => {
        navigate(PATHS.HOME);
    }, [navigate]);
    
    return <></>
}
import React from 'react';
import usePermission from "./usePermission";

type Props = {
    to: string;
    fallback?: JSX.Element | string;
    children: React.ReactNode;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({to, fallback, children}) => {
    
    const allowed = usePermission(to);
    
    if(allowed){
        return <>{children}</>;
    }
    
    return <>{fallback}</>;
};

export default Restricted;
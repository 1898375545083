import { Space, Table, Typography } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import Column from "antd/es/table/Column";
import { Address } from "shared/models/addressModel";

interface AddressListProps {
    addresses: Address[];
    supplierId?: string,
    customerId?: string,
    canCreate?: boolean,
    canEdit?: boolean,
    canDelete?: boolean,
    onDelete?: (addressId: string) => void,
    tableSize?: SizeType
}

function AddressList(props: AddressListProps) {

    if (!props.customerId && !props.supplierId) {
        return (
            <Typography.Text type="danger">Invalid configuration for AddresstList component. </Typography.Text>
        );
    }

    return (
        <Space direction="vertical" style={{width:'100%'}}>
            <Table dataSource={props.addresses} rowKey="id" size={props.tableSize} 
                showHeader={props.addresses.length ? true: false} 
                 pagination={{hideOnSinglePage: true}}>
                <Column title="Ciudad" dataIndex="cityName" key="cityName" />
                <Column title="Departamento" dataIndex="stateName" key="stateName" />
                <Column title="Dirección" dataIndex="address1" key="address1" />
            </Table>

        </Space>

    );

}

export default AddressList;
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { coreApiInstance } from "../apiInstance";
import { ServiceType } from "shared/models/serviceTypeModel";

const servicesResourceUri = "services";

export const getServiceTypes = async (): Promise<EslavonApiResponse<ServiceType[]>> => {
    try {
        const actionUri = `${servicesResourceUri}/types`;
        const { data: types } = await coreApiInstance.get<EslavonApiResponse<ServiceType[]>>(actionUri);
        return types;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}
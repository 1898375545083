import { Card, Col, Row } from "antd";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import { CustomerStatuses } from "constants/customers";
import { OrderForm } from "features/orders/orderForm";
import { useAppSelector } from "hooks/useAppSelector";

function CreateOrderPage() {

    const currentUserCustomer = useAppSelector(store => store.currentUser?.customer);

    if (currentUserCustomer?.customerStatusId !== CustomerStatuses.Active) {
        return (<>Ups, parece que tu perfil de cliente no se ha activado aún, no puedes crear ordenes de servicio. </>);
    }

    return (
        <Row justify={"center"}>
            <Col md={{ span: 16 }} span={24}>
                <Card>
                    <PageTitle>Nueva orden de servicio</PageTitle>
                    <OrderForm />
                </Card>
            </Col>
        </Row>
    );

}

export default CreateOrderPage;
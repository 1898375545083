import { Select } from "antd";
import { getCertificationTypes } from "api/coreapi/certifications";
import { useEffect, useState } from "react";
import { EnumTypeModel } from "shared/models/enumTypeModel";

interface CertificationTypeSelectProps {
    value?: number;
    onChange?: (value?: number) => void;
    isForCustomers?: boolean,
    isForEmployees?: boolean,
    isForSuppliers?: boolean,
    includeDeleted?: boolean
}

const CertificationTypeSelect: React.FC<CertificationTypeSelectProps> = (props) => {
    const {
        value,
        onChange,
        isForCustomers, isForEmployees, isForSuppliers, includeDeleted
    } = props;

    var [types, setTypes] = useState<EnumTypeModel[]>();
    const triggerChange = (changedValue?: number) => {
        onChange?.(changedValue);
    };

    const onSelectedItemChange = (newType: number) => {
        triggerChange(newType);
    };

    useEffect(() => {
        const getInfo = async () => {
            try {
                const res = await getCertificationTypes({
                    isForCustomers: isForCustomers,
                    isForEmployees: isForEmployees,
                    isForSuppliers: isForSuppliers,
                    includeDeleted: includeDeleted
                });
                setTypes(res.result);
            } catch (error: any) {
            }
        };
        getInfo();
    }, [isForCustomers, isForEmployees, isForSuppliers, includeDeleted]);


    return (
        <Select
            style={{ width: 220 }}
            defaultValue={value}
            onChange={onSelectedItemChange}
            options={types?.map((item) => ({
                value: item.id,
                label: item.displayName ?? item.name,
            }))}
        />

    );
}

export default CertificationTypeSelect;
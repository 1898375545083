import { Input, Table, message } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { getCustomersBySearch } from "api/coreapi/customers";
import CustomerStatusSelect from "components/customerStatusSelect";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerOverview } from "shared/models/customerOverviewModel";
import { GetCustomersBySearchPayload } from "shared/models/payloads/getCustomersBySearchPayload";
import { formatDate } from "utils/dateUtils";

const { Search } = Input;

const CustomerList = () => {
  const [data, setData] = useState<CustomerOverview[]>();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 15, total: 0 });
  let timeoutId: NodeJS.Timeout;
  const [filters, setFilters] = useState<GetCustomersBySearchPayload>({
    text: "",
    statusIds: [],
    orderByColumns: [],
    orderByDirection: 0,
    pageNumber: pagination.current,
    pageSize: pagination.pageSize
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await getCustomersBySearch({ ...filters, pageSize: pagination.pageSize, pageNumber: pagination.current });
        setData(result.result.data);
        setPagination({ ...pagination, total: result.result.totalPages });
      } catch (error) {
        message.error('Error obteniendo información de clientes.');

      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [filters, pagination.pageSize, pagination.current]);

  const handleTableChange = (
    pagination: any,
    tableFilters: any,
    sorter: SorterResult<CustomerOverview> | SorterResult<CustomerOverview>[]) => {
    let orderByColumns: string[] = [];
    let orderByDirection: 0 | 1 = 0;

    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize
    });

    if (Array.isArray(sorter)) {
      orderByColumns = sorter.map((s) => s.columnKey ? s.columnKey.toString() : '');
      orderByDirection = sorter[0].order === 'descend' ? 1 : 0;
    } else if (sorter?.columnKey) {
      orderByColumns = [sorter.columnKey.toString()];
      orderByDirection = sorter.order === 'descend' ? 1 : 0;
    }

    setFilters({
      ...filters,
      orderByColumns: orderByColumns,
      orderByDirection: orderByDirection,
    });
  };

  const handleFilterChange = (key: string, value: any) => {
    setFilters({ ...filters, [key]: value });
    setPagination({ ...pagination, current: 1 });
  };

  const debounceHandleFilterChange = (key: string, value: any) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => handleFilterChange(key, value), 1000);
  };

  const columns = [
    {
      title: "# Identificación",
      dataIndex: "identificationNumber",
      key: "identificationNumber",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      sorter: (a: CustomerOverview, b: CustomerOverview) => a.name.localeCompare(b.name),
    },
    {
      title: "Estado",
      dataIndex: "customerStatusName",
      key: "customerStatusName",
      sorter: (a: CustomerOverview, b: CustomerOverview) => a.customerStatusName.localeCompare(b.customerStatusName),
    },
    {
      title: "Creado el",
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: (a: CustomerOverview, b: CustomerOverview) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      render: (text: Date, record: CustomerOverview) => formatDate(record.createdOn, 'DateAndTime'),
    },
  ];

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Search
          placeholder="Buscar por nombre o identificación"
          onChange={(e) => debounceHandleFilterChange("text", e.target.value)}
          style={{ width: 300, marginRight: 16 }}
        />
        <CustomerStatusSelect
          mode="multiple"
          onChange={(value) => handleFilterChange("statusIds", value)}
          placeholder="Filtrar por estado"
          style={{ width: 200, marginRight: 16 }} />
      </div>
      <Table
        rowKey='id'
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
        onRow={(record) => ({
          onClick: () => navigate(`/customers/${record.id}`)
        })}
      />
    </>
  );
};

export default CustomerList;

interface Paths {
   [key: string]: string;
}

export default {
   HOME: '/',
   UNAUTHORIZED: '/401',
   FORBIDDEN: '/403',
   SIGNIN_CALLBACK: '/signin-oidc',
   SIGNOUT_CALLBACK: '/signout-callback-oidc',

   CONTACTS: '/contacts',
   CUSTOMER_PROFILE: '/customer/profile',
   CUSTOMER_CREATE: '/customer/create',
   CUSTOMER_DOCUMENTS: '/customer/documents',
   CUSTOMER_CERTIFICATIONS: '/customer/certifications',
   CUSTOMER_LIST: '/customers',
   CUSTOMER_DETAIL: '/customers/:customerId',
   EMPLOYEES: '/employees',
   EMPLOYEES_CREATE: '/employees/create',
   EMPLOYEES_DETAIL: '/employees/:id',
   SUPPLIER_PROFILE: '/supplier/profile',
   SUPPLIER_CREATE: '/supplier/create',
   SUPPLIER_DOCUMENTS: '/supplier/documents',
   SUPPLIER_CERTIFICATIONS: '/supplier/certifications',
   SUPPLIER_LIST: '/suppliers',
   SUPPLIER_DETAIL: '/suppliers/:supplierId',
   SERVICE_QUOTES: '/servicequotes',
   ORDER_LIST: '/orders',
   ORDER_CREATE: '/orders/create',
   ORDER_DETAIL: '/orders/:orderNumber',
   ORDER_BOARD: '/orderboard',
   USERS: '/users',
   ORDER_TENDER_REQUEST: '/tenderrequests/:tenderRequestId',
   QUOTES_REVIEW: '/quotes/review/:orderNumber',
   ORDER_QUOTE_CONFIRMATION: '/quoteconfirmation/:orderQuoteId',
   PAYMENT_RESULT: '/payments/result',
   NOT_FOUND: '*',
} as Paths;
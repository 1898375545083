import { Card, Col, Row, message, theme } from "antd";
import { getSupplierById, updateSupplierStatus } from "api/coreapi/suppliers";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import { SupplierStatusDropdown } from "components/supplierStatusDropdown";
import AddressList from "features/addresses/addressList";
import CertificationList from "features/certifications/certificationList";
import ContactList from "features/contacts/contactList";
import DocumentList from "features/documents/documentList";
import EmployeeList from "features/employees/employeeList";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EnumTypeModel } from "shared/models/enumTypeModel";
import { Supplier } from "shared/models/supplierModel";
import { formatDate } from "utils/dateUtils";

function SupplierDetailPage() {
    const { supplierId } = useParams();
    const [loading, setLoading] = useState(false);
    const [supplier, setSupplier] = useState<Supplier>();

    const {
        token: { colorPrimaryBg },
    } = theme.useToken();
    const headerStyle = { background: colorPrimaryBg };

    useEffect(() => {
        const getInfo = async () => {
            if (!supplierId) {
                return;
            }

            try {
                setLoading(true);
                const res = await getSupplierById(supplierId);
                setSupplier(res.result);
            } catch (error: any) {
                message.error('Error obteniendo información del proveedor.');
            } finally {
                setLoading(false);
            }
        };
        getInfo();
    }, [supplierId]);

    const onStatusSelected = async (status: EnumTypeModel) => {
        try {
            const res = await updateSupplierStatus({ supplierId: supplier!.id, supplierStatusId: status.id });
            return res?.result ?? false;
        } catch (error: any) {
            message.error('Error actualizando el estado del proveedor.');
        }

        return true;
    }

    if (supplier === undefined || loading) {
        return (<LoadingSpinner />);
    }

    return (
        <Card>
            <PageTitle>Proveedor: {supplier.name}</PageTitle>

            <Row gutter={[50, 50]}>
                <Col sm={24} lg={12}>
                    <Card title="Proveedor" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <Row justify='end'>
                            <Col>
                                Estado
                                <SupplierStatusDropdown currentStatusId={supplier.supplierStatusId} onStatusSelected={onStatusSelected} />
                            </Col>
                        </Row>

                        <Row>
                            <Col span={7}><b>Fecha de registro</b></Col>
                            <Col>{formatDate(supplier.createdOn, "DateAndTime")}</Col>
                        </Row>

                        <Row>
                            <Col span={7}><b>Nombre</b></Col>
                            <Col>{supplier.name || supplier.supplierTypeName}</Col>
                        </Row>
                        <Row>
                            <Col span={7}>
                                <b>{supplier.identificationNumberTypeName}</b>
                            </Col>
                            <Col>
                                {supplier.identificationNumber}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={7}><b>Sitio web</b></Col>
                            <Col>{supplier.website || '--'}</Col>
                        </Row>
                        <Row>
                            <Col span={7}><b>Servicios</b></Col>
                            <Col span={17}>{supplier.serviceTypes.map(serviceType => serviceType.displayName).join(', ')}</Col>
                        </Row>
                        <Row>
                            <Col span={7}><b>Ciudades donde presta servicio</b></Col>
                            <Col span={17}>{supplier.cities.map(city => city.displayName).join(', ')}</Col>
                        </Row>


                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Direcciones" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <AddressList addresses={supplier.addresses ?? []} customerId={supplierId} tableSize="small" />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Contactos" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <ContactList contacts={supplier.contacts ?? []} customerId={supplierId} tableSize="small" />
                    </Card>
                </Col>

                <Col span={12}>

                    <Card title="Documentos" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <DocumentList documents={supplier.documents ?? []} customerId={supplierId} tableSize="small" />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Certificaciones" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <CertificationList certifications={supplier.certifications ?? []} customerId={supplierId} tableSize="small" />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Colaboradores" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <EmployeeList employees={supplier.employees} tableSize="small" />
                    </Card>
                </Col>
            </Row>
        </Card>

    );
}

export default SupplierDetailPage;
import { EslavonPageableResponse } from "shared/models/apiResponses/eslavonPageableResponse";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { Certification } from "shared/models/certificationModel";
import { CustomerOverview } from "shared/models/customerOverviewModel";
import { Document } from 'shared/models/documentModel';
import { GetCustomersBySearchPayload } from "shared/models/payloads/getCustomersBySearchPayload";
import { UpsertCustomerPayload } from "shared/models/payloads/upsertCustomerPayload";
import { coreApiInstance } from "../apiInstance";
import { Customer } from "shared/models/customerModel";
import { EnumTypeModel } from "shared/models/enumTypeModel";
import { UpdateCustomerStatusPayload } from "shared/models/payloads/updateCustomerStatusPayload";
import { Contact } from "shared/models/contactModel";
import { Address } from "shared/models/addressModel";
import { OrderOverview } from "shared/models/orderOverviewModel";
import { GetCustomerOrdersPayload } from "shared/models/payloads/getCustomerOrdersPayload";
import { toQueryString } from "utils/urlUtils";
import { SortingDirection } from "constants/common";

const customersResourceUri = 'customers';

export const getCustomerById  = async (customerId: string) : Promise<EslavonApiResponse<Customer>> => {
    try {
        const {data: result} = await coreApiInstance.get<EslavonApiResponse<Customer>>(`${customersResourceUri}/${customerId}`);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getMyCustomers  = async () : Promise<EslavonApiResponse<CustomerOverview[]>> => {
    try {
        const {data: customers} = await coreApiInstance.get<EslavonApiResponse<CustomerOverview[]>>(`${customersResourceUri}/getmycustomers`);
        return customers;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getCustomersBySearch  = async (payload: GetCustomersBySearchPayload) : Promise<EslavonPageableResponse<CustomerOverview>> => {
    try {
        const {data: customers} = await coreApiInstance.post<EslavonPageableResponse<CustomerOverview>>(`${customersResourceUri}/search`, payload);
        return customers;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const createCustomer  = async (payload: UpsertCustomerPayload) : Promise<EslavonApiResponse<CustomerOverview>> => {
    try {
        const {data: result} = await coreApiInstance.post<EslavonApiResponse<CustomerOverview>>(customersResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const editCustomer  = async (payload: UpsertCustomerPayload) : Promise<EslavonApiResponse<CustomerOverview>> => {
    try {
        const {data: result} = await coreApiInstance.put<EslavonApiResponse<CustomerOverview>>(customersResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getCustomerDocuments  = async (customerId: string) : Promise<EslavonApiResponse<Document[]>> => {
    try {
        const actionUri = `${customersResourceUri}/${customerId}/documents`;
        const {data: result} = await coreApiInstance.get<EslavonApiResponse<Document[]>>(actionUri);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getCustomerCertifications  = async (customerId: string) : Promise<EslavonApiResponse<Certification[]>> => {
    try {
        const actionUri = `${customersResourceUri}/${customerId}/certifications`;
        const {data: result} = await coreApiInstance.get<EslavonApiResponse<Certification[]>>(actionUri);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getCustomerContacts  = async (customerId: string) : Promise<EslavonApiResponse<Contact[]>> => {
    try {
        const actionUri = `${customersResourceUri}/${customerId}/contacts`;
        const {data: result} = await coreApiInstance.get<EslavonApiResponse<Contact[]>>(actionUri);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getCustomerOrders  = async (payload: GetCustomerOrdersPayload) : Promise<EslavonPageableResponse<OrderOverview>> => {
    try {
        payload.orderByDirection = SortingDirection.Descending;
        const actionUri = `${customersResourceUri}/${payload.customerId}/orders${toQueryString(payload)}`;
        const {data: result} = await coreApiInstance.get<EslavonPageableResponse<OrderOverview>>(actionUri);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getCustomerAddresses  = async (customerId: string) : Promise<EslavonApiResponse<Address[]>> => {
    try {
        const actionUri = `${customersResourceUri}/${customerId}/addresses`;
        const {data: result} = await coreApiInstance.get<EslavonApiResponse<Address[]>>(actionUri);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getCustomerStatuses  = async () : Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        const actionUri = `${customersResourceUri}/statuses`;
        const {data: types} = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>(actionUri);
        return types;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const updateCustomerStatus = async (payload: UpdateCustomerStatusPayload) : Promise<EslavonApiResponse<boolean>> => {
    try {
        const actionUri = `${customersResourceUri}/${payload.customerId}/status`;
        const {data: result} = await coreApiInstance.put<EslavonApiResponse<boolean>>(actionUri, payload);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}
import { User } from "shared/models/userModel";
import { identityApiInstance } from "../apiInstance";
import { GetUsersPayload } from "shared/models/payloads/getUsersPayload";
import { toQueryString } from "utils/urlUtils";
import { CreateUserPayload } from "shared/models/payloads/createUserPayload";
import { EditUserPayload } from "shared/models/payloads/editUserPayload";

const usersResourceUri = "users";

export const getUsers = async (payload: GetUsersPayload): Promise<User[]> => {
    var queryString = toQueryString(payload);
    const actionUri = `${usersResourceUri}${queryString}`;
    const { data: users } = await identityApiInstance.get<User[]>(actionUri);
    
    return users;
}

export const createUser = async (payload: CreateUserPayload) : Promise<User> => {
    try {
        const {data: result} = await identityApiInstance.post<User>(usersResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const updateUser = async (payload: EditUserPayload) : Promise<User> => {
    try {
        const {data: result} = await identityApiInstance.put<User>(usersResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}
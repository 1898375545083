import { Alert, Descriptions } from "antd";
import dayjs from 'dayjs';
import { OrderQuote } from "shared/models/orderQuoteModel";
import { formatMoney } from "utils/moneyUtils";

interface OrderQuoteInternalOverviewProps {
    orderQuote: OrderQuote,
}

const OrderQuoteInternalOverview: React.FC<OrderQuoteInternalOverviewProps> = ({ orderQuote }) => {

    return <>
        {orderQuote &&
            <Descriptions column={1} labelStyle={{ minWidth: 180 }}>
                <Descriptions.Item label="Fecha cotización">{dayjs(orderQuote?.createdOn).format('DD/MM/YYYY hh:mm A')}</Descriptions.Item>
                <Descriptions.Item label="Costo servicio">{formatMoney((orderQuote.cost ?? 0).toString())}</Descriptions.Item>
                <Descriptions.Item label="IVA">{formatMoney((orderQuote.supplierVat ?? 0).toString())}</Descriptions.Item>
                <Descriptions.Item label="Intermediación Eslavón">{formatMoney((orderQuote.spread ?? 0).toString())} {`(${(orderQuote.spreadPercentage ?? 0) * 100}%)`}</Descriptions.Item>
                <Descriptions.Item label="IVA Eslavón">{formatMoney((orderQuote.eslavonVat ?? 0).toString())}</Descriptions.Item>
                <Descriptions.Item label="Total">{formatMoney((orderQuote.total ?? 0).toString())}</Descriptions.Item>
                {orderQuote?.comments &&
                    <Descriptions.Item><Alert type="info" message='Información adicional del proveedor' description={orderQuote.comments} showIcon /></Descriptions.Item>
                }
            </Descriptions>
        }
    </>
}

export default OrderQuoteInternalOverview;
import { Card, Col, Row } from "antd";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import CreateEmployeeForm from "features/employees/createEmployeeForm";
import { useAppSelector } from "hooks/useAppSelector";

function CreateEmployeePage() {

    const currentUserSupplier = useAppSelector(store => store.currentUser?.supplier);

    if (!currentUserSupplier) {
        return (<></>);
    }

    return (
        <Row justify={"center"}>
            <Col md={{ span: 16 }} span={24}>
                <Card>
                    <PageTitle>Nuevo colaborador</PageTitle>
                    <CreateEmployeeForm supplierId={currentUserSupplier?.id}></CreateEmployeeForm>
                </Card>
            </Col>
        </Row>
    );

}

export default CreateEmployeePage;
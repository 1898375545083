import { PageTitle } from "components/common/pageTitle/pageTitle";
import { PermissionList } from "constants/permissions";
import usePermission from "features/auth/usePermission";
import { OrderList } from "features/orders/orderList";
import { useAppSelector } from "hooks/useAppSelector";

export function OrderListPage() {
    const currentUser = useAppSelector(state => state.currentUser);
    const userCanCreateDocument = usePermission([PermissionList.OrderCreate]);

    return (
        <>
            <PageTitle>Órdenes de servicio</PageTitle>
            <OrderList
                customerId={currentUser.customer?.id}
                supplierId={currentUser.supplier?.id}
                canCreate={userCanCreateDocument} />
        </>
    );
}

interface OverlayProps {
    show: boolean,
    onClick: () => void,
}

export default function Overlay(props:OverlayProps){
    return (
        <div style={{position:'absolute', zIndex: 1, height:0}} onClick={props.onClick}></div>
    );
}
import { coreApiInstance } from "api/apiInstance";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { EnumTypeModel } from "shared/models/enumTypeModel";
import { CreateDocumentPayload } from "shared/models/payloads/createDocumentPayload";
import { GetDocumentTypesPayload } from "shared/models/payloads/getDocumentTypesPayload";
import { toQueryString } from "utils/urlUtils";
import { Document } from 'shared/models/documentModel';

const documentsResourceUri = "documents";

export const createDocument  = async (payload: CreateDocumentPayload) : Promise<EslavonApiResponse<Document>> => {
    try {
        const actionUri = `${documentsResourceUri}`;
        let formData = new FormData();
        formData.append('formFile', payload.file);
        formData.append('fileName', payload.name);
        formData.append('documentTypeId', payload.documentTypeId.toString());

        if(payload.supplierId){
            formData.append('supplierId', payload.supplierId);
        }

        if(payload.customerId){
            formData.append('customerId', payload.customerId);
        }

        if(payload.employeeId){
            formData.append('employeeId', payload.employeeId);
        }
        
        const {data: result} = await coreApiInstance.post<EslavonApiResponse<Document>>(actionUri, formData);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getDocumentTypes  = async (payload: GetDocumentTypesPayload) : Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        var queryString = toQueryString(payload);
        const actionUri = `${documentsResourceUri}/types${queryString}`;        
        const {data: types} = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>(actionUri);
        return types;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const deleteDocument  = async (documentId: string) : Promise<EslavonApiResponse<boolean>> => {
    try {
        const actionUri = `${documentsResourceUri}/${documentId}`;        
        const {data: result} = await coreApiInstance.delete<EslavonApiResponse<boolean>>(actionUri);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getDownloadLink  = async (documentId: string) : Promise<EslavonApiResponse<string>> => {
    try {
        const actionUri = `${documentsResourceUri}/${documentId}/downloadlink`;        
        const {data: result} = await coreApiInstance.get<EslavonApiResponse<string>>(actionUri);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}


import { Alert, Card, Col, message, Progress, Row, Space, Tag, theme } from "antd";
import { getMySuppliers } from "api/coreapi/suppliers";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import { SupplierStatuses } from "constants/suppliers";
import SupplierForm from "features/suppliers/supplierForm";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SupplierOverview } from "shared/models/supplierOverviewModel";
import paths from "routes/paths";
import { useAuth } from "react-oidc-context";

function SupplierProfilePage() {
    const navigate = useNavigate();
    const [supplier, setSupplier] = useState<SupplierOverview | undefined>();
    const [loading, setLoading] = useState(true);
    const auth = useAuth();
    const [profileCreationPercent, setProfileCreationPercent] = useState(0);
    const [isCreatingProfile, setIsCreatingProfile] = useState(false);
    const [autoLogOutTimer, setAutoLogoutTimer] = useState(20);
    const {
        token: { colorPrimary },
    } = theme.useToken();

    useEffect(() => {
        const getSupplierInfo = async () => {
            try {
                setLoading(true);
                const res = await getMySuppliers();

                if (res?.result && res.result.length > 0) {
                    setSupplier(res.result[0]);
                }

            } catch (error: any) {
                message.error('Error obteniendo información del proveedor.');
            } finally {
                setLoading(false);
            }
        };
        getSupplierInfo();
    }, [navigate]);

    useEffect(() => {
        let interval: any;
        if (isCreatingProfile) {
            interval = setInterval(() => {
                setProfileCreationPercent((prevPercent) => {
                    const newPercent = prevPercent + 1;
                    if (newPercent >= 100) {
                        return 100;
                    }
                    return newPercent;
                });
            }, 60);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isCreatingProfile]);

    useEffect(() => {
        let interval: any;

        if (profileCreationPercent >= 100) {
            interval = setInterval(() => {
                setAutoLogoutTimer((prev) => {
                    const newValue = prev - 1;

                    if (newValue <= 0) {
                        auth.signoutRedirect();
                        return 0;
                    }

                    return newValue;
                });
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [profileCreationPercent, auth]);

    function getBadgeColor(customerStatusId: number): string {

        let color = '';

        switch (customerStatusId) {
            case SupplierStatuses.Active:
                color = 'green';
                break;
            case SupplierStatuses.Inactive:
                color = 'red';
                break;
            default:
                color = 'orange'
                break;
        }

        return color;
    }

    if (loading)
        return <LoadingSpinner></LoadingSpinner>

    if (isCreatingProfile)
        return (
            <Space direction="vertical" align="center" size="middle">
                <Progress type="circle" percent={profileCreationPercent} strokeColor={colorPrimary} />
                {profileCreationPercent < 100 &&
                    <span>Creando perfil... Por favor, espera un momento.</span>
                }
                {profileCreationPercent >= 100 &&
                    <>
                        <span>¡Felicidades! 🎉 Tu perfil ha sido creado con éxito. Debes iniciar sesión nuevamente para garantizar que tengas acceso a todas las funcionalidades.</span>
                        <strong>Tu sesión actual finalizará automáticamente en {autoLogOutTimer} segundos</strong>
                        <Link onClick={() => auth.signoutRedirect()} to="#">Cerrar sesión ahora</Link>
                    </>

                }
            </Space>
        );

    return (
        <Card>
            {!supplier &&
                <Row justify={"center"}>
                    <Alert
                        message="Crea el perfil"
                        description="Para acceder a todas las funcionalidades de la plataforma, debes completar el perfil de proveedor."
                        type="warning"
                        showIcon
                    />
                </Row>

            }

            {supplier && supplier.supplierStatusId === SupplierStatuses.Draft &&
                <Row justify={"center"}>
                    <Alert
                        message="Perfil incompleto"
                        description={<p>Asegurate de tener el perfil completo y realizar la cotización de los servicios en la sección <Link to={paths.SERVICE_QUOTES}>'Tarifas'</Link></p>}
                        type="warning"
                        showIcon
                    />
                </Row>
            }

            <PageTitle>
                Perfil de proveedor
                &nbsp;&nbsp;{supplier?.supplierStatusId &&
                    <Tag color={getBadgeColor(supplier?.supplierStatusId)}>{supplier?.supplierStatusName}</Tag>
                }
            </PageTitle>
            {
                supplier?.supplierStatusId === SupplierStatuses.Draft &&
                <div>

                </div>
            }

            <Row justify={"center"}>
                <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                    {!loading &&
                        <SupplierForm
                            supplier={supplier}
                            onCreate={(item: SupplierOverview) => {
                                setSupplier(item);
                                setIsCreatingProfile(true);
                            }} />}
                </Col>
            </Row>
        </Card>
    );
}

export default SupplierProfilePage;
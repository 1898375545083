import {
  BankOutlined,
  CalendarOutlined,
  ContactsOutlined,
  DashboardOutlined,
  DollarOutlined,
  FileOutlined,
  TeamOutlined, TrophyOutlined, UserOutlined
} from '@ant-design/icons';
import { PermissionList } from 'constants/permissions';
import React from 'react';
import PATHS from 'routes/paths';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  permissions?: string[];
  requiresActiveCustomer?: boolean;
  requiresActiveSupplier?: boolean;
  requiresActiveSupplierOrCustomer?: boolean;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Mi empresa',
    key: 'supplier-profile',
    url: '/supplier/profile',
    icon: <BankOutlined />,
    permissions: [PermissionList.SupplierCreate]
  },
  {
    title: 'Mi empresa',
    key: 'customer-profile',
    url: '/customer/profile',
    icon: <BankOutlined />,
    permissions: [PermissionList.CustomerCreate]
  },
  {
    title: 'Tarifas',
    key: 'service-quotes',
    url: PATHS.SERVICE_QUOTES,
    icon: <DollarOutlined />,
    permissions: [PermissionList.SupplierCreate],
  },
  {
    title: 'Colaboradores',
    key: 'employees',
    url: '/employees',
    icon: <TeamOutlined />,
    permissions: [PermissionList.EmployeeList],
    requiresActiveSupplier: true
  },
  {
    title: 'Contactos',
    key: 'contacts',
    url: PATHS.CONTACTS,
    icon: <ContactsOutlined />,
    permissions: [PermissionList.ContactList],
    requiresActiveSupplierOrCustomer: true
  },
  {
    title: 'Documentos',
    key: 'supplier-documents',
    url: '/supplier/documents',
    icon: <FileOutlined />,
    permissions: [PermissionList.SupplierListDocument],
  },
  {
    title: 'Documentos',
    key: 'customer-documents',
    url: '/customer/documents',
    icon: <FileOutlined />,
    permissions: [PermissionList.CustomerListDocument],
  },
  {
    title: 'Certificaciones',
    key: 'supplier-certifications',
    url: '/supplier/certifications',
    icon: <TrophyOutlined />,
    permissions: [PermissionList.SupplierListCertification],
    requiresActiveSupplier: true
  },
  // {
  //   title: 'Certificaciones',
  //   key: 'customer-certifications',
  //   url: '/customer/certifications',
  //   icon: <TrophyOutlined />,
  //   permissions:[PermissionList.CustomerListCertification],
  //   requiresActiveCustomer: true
  // }
  {
    title: 'Usuarios',
    key: 'supplier-users',
    url: '/users',
    icon: <UserOutlined />,
    permissions: [PermissionList.UserList],
    requiresActiveSupplier: true
  },
  {
    title: 'Usuarios',
    key: 'customer-users',
    url: '/users',
    icon: <UserOutlined />,
    permissions: [PermissionList.UserList],
    requiresActiveCustomer: true
  },
  {
    title: 'Clientes',
    key: 'customers',
    url: PATHS.CUSTOMER_LIST,
    icon: <UserOutlined />,
    permissions: [PermissionList.CustomerList],
  },
  {
    title: 'Proveedores',
    key: 'suppliers',
    url: PATHS.SUPPLIER_LIST,
    icon: <UserOutlined />,
    permissions: [PermissionList.SupplierList],
  },
  {
    title: 'Órdenes de servicio',
    key: 'orders',
    url: PATHS.ORDER_LIST,
    icon: <CalendarOutlined />,
    permissions: [PermissionList.OrderList],
    requiresActiveCustomer: true
  },
  {
    title: 'Órdenes de servicio',
    key: 'supplier-orders',
    url: PATHS.ORDER_LIST,
    icon: <CalendarOutlined />,
    permissions: [PermissionList.OrderList],
    requiresActiveSupplier: true,
  },
  {
    title: 'Tablero de Órdenes',
    key: 'orderboard',
    url: PATHS.ORDER_BOARD,
    icon: <DashboardOutlined />,
    permissions: [PermissionList.OrderBoard]
  },
];

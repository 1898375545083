import { coreApiInstance } from "api/apiInstance";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { UpsertServiceQuotePayload } from "shared/models/payloads/upsertServiceQuotePayload";
import { ServiceQuote } from "shared/models/serviceQuoteModel";

const serviceQuotesResourceUri = "serviceQuotes";

export const upsertServiceQuote = async (payload: UpsertServiceQuotePayload): Promise<EslavonApiResponse<ServiceQuote>> => {
    try {
        const { data: result } = await coreApiInstance.post<EslavonApiResponse<ServiceQuote>>(serviceQuotesResourceUri, payload);
        return result;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}
import { Alert, Button, Col, Form, Input, InputNumber, Modal, Row, message } from 'antd';
import { confirmOrderQuote } from 'api/coreapi/orders';
import { useState } from 'react';
import { ConfirmOrderQuotePayload } from 'shared/models/payloads/confirmOrderQuotePayload';
const { TextArea } = Input;

interface ConfirmOrderQuoteModalProps {
    orderQuoteId: string;
    onConfirmed?: () => void,
}

const ConfirmOrderQuoteModal = (props: ConfirmOrderQuoteModalProps) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const confirmQuote = (additionalCost?: number, additionalVat?: number, comments?: string) => {

        Modal.warning({
            title: 'Confirmación',
            content: 'Al confirmar esta orden de servicio, estoy aceptando formalmente las tarifas y condiciones especificadas. Además, estoy certificando la disponibilidad de personal para atender el servicio en el lugar y hora acordados.',
            okText: 'Confirmar',
            closable: true,
            cancelButtonProps: {},
            cancelText: null,
            onOk: async () => {
                const sendConfirmation = async (payload: ConfirmOrderQuotePayload) => {
                    setSubmitting(true);
                    try {
                        const result = await confirmOrderQuote(payload);
                        if (!result?.result) {
                            message.error("Ocurrió un error confirmando la cotización.");
                            return;
                        }

                        if (props.onConfirmed) {
                            props.onConfirmed();
                        }

                        setIsModalOpen(false);
                        message.success('¡Cotización confirmada exitosamente!');
                    } catch (error) {
                        message.error('Ocurrió un error confirmando la cotización.');

                    } finally {
                        setSubmitting(false);
                    }
                };

                let payload: ConfirmOrderQuotePayload = {
                    orderQuoteId: props.orderQuoteId,
                };

                if (additionalCost) {
                    payload.additionalCost = {
                        cost: additionalCost,
                        vat: additionalVat,
                        comments
                    }
                }

                sendConfirmation(payload);

            },
        });
    };

    const confirmWithAdditionalCost = () => {
        form.validateFields()
            .then((values: any) => {

                if (values.cost < 0 && values.vat && values.vat > 0) {
                    message.error('El valor del Iva debe ser negativo como el valor del ajuste.');
                    return;
                }


                if (values.cost > 0 && values.vat && values.vat < 0) {
                    message.error('El valor del Iva debe ser positivo como el valor del ajuste.');
                    return;
                }
                confirmQuote(values.cost, values.vat, values.comments);
            })
            .catch(() => {

            });
    };

    return (
        <>
            <Button type="primary" htmlType="button" style={{ margin: '10px' }} disabled={isModalOpen} onClick={() => confirmQuote()}>
                Confirmar Cotización
            </Button>

            <Button type="primary" htmlType="button" style={{ margin: '10px' }} disabled={isModalOpen} onClick={() => setIsModalOpen(true)}>
                Modificar Cotización
            </Button>

            <Modal
                title="Modificar valor de la cotización"
                open={isModalOpen}
                confirmLoading={submitting}
                onCancel={() => setIsModalOpen(false)}
                onOk={confirmWithAdditionalCost}
            >
                <Alert type='info'
                    message="Ingrese valores positivos para aumentar la tarifa y negativos para disminuirla."
                    showIcon
                    style={{ marginBottom: 20, marginTop: 20 }} />
                <Form form={form} layout="vertical" style={{ padding: 10 }}>
                    <Row gutter={[16, 16]} align="top" style={{ marginBottom: '10px' }}>
                        <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }}>
                            <Form.Item name="cost" label='Vr. Unitario'
                                rules={[{ required: true, message: 'Debe ingresar un valor' }]}>
                                <InputNumber controls={false} width={550} decimalSeparator='.' precision={2}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    style={{ width: 120 }} size="small"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 1 }}>
                            <Form.Item name="vat" label='Iva'
                                rules={[{ required: false, message: 'Debe ingresar un valor de IVA' }]}>
                                <InputNumber
                                    controls={false} width={550} decimalSeparator='.' precision={2}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    style={{ width: 120 }} size="small"

                                />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Form.Item name="comments" label="Cuál es el motivo del reajuste?">
                        <TextArea rows={3} />

                    </Form.Item>
                </Form>
            </Modal>
        </>

    );
};

export default ConfirmOrderQuoteModal;
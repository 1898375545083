import { coreApiInstance } from "api/apiInstance";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { WompiTransactionResult } from "shared/models/wompiTransactionModel";

const paymentsResourceUri = 'payments';

export const getWompiTransactionResult = async (transactionId?: string): Promise<EslavonApiResponse<WompiTransactionResult>> => {
    try {
        let actionUri = `${paymentsResourceUri}/wompi/transactions/${transactionId}`;
        const { data: transactionResult } = await coreApiInstance.get<EslavonApiResponse<WompiTransactionResult>>(actionUri);
        return transactionResult;
    }
    catch (error: any) {
        if (error.response.data) {
            throw error.response.data;
        }
        else {
            throw error;
        }
    }
}
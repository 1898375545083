import { coreApiInstance } from "api/apiInstance";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { Certification } from "shared/models/certificationModel";
import { EnumTypeModel } from "shared/models/enumTypeModel";
import { CreateCertificationPayload } from "shared/models/payloads/createCertificationPayload";
import { EditCertificationPayload } from "shared/models/payloads/editCertificationPayload";
import { GetCertificationTypesPayload } from "shared/models/payloads/getCertificationTypesPayload";
import { toQueryString } from "utils/urlUtils";

const certificationsResourceUri = 'certifications';

export const createCertification  = async (payload: CreateCertificationPayload) : Promise<EslavonApiResponse<Certification>> => {
    try {
        let formData = new FormData();
        formData.append('certificationTypeId', payload.certificationTypeId.toString());
        formData.append('issueDate', payload.issueDate.toString());

        if(payload.file){
            formData.append('formFile', payload.file);
        }

        if(payload.fileName){
            formData.append('fileName', payload.fileName);
        }

        if(payload.expirationDate){
            formData.append('expirationDate', payload.expirationDate.toString());
        }

        if(payload.issuer){
            formData.append('issuer', payload.issuer);
        }

        if(payload.supplierId){
            formData.append('supplierId', payload.supplierId);
        }

        if(payload.customerId){
            formData.append('customerId', payload.customerId);
        }

        if(payload.employeeId){
            formData.append('employeeId', payload.employeeId);
        }

        const {data: result} = await coreApiInstance.post<EslavonApiResponse<Certification>>(certificationsResourceUri, formData);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const editCertification  = async (payload: EditCertificationPayload) : Promise<EslavonApiResponse<Certification>> => {
    try {
        let formData = new FormData();
        formData.append('certificationTypeId', payload.certificationTypeId.toString());
        formData.append('issueDate', payload.issueDate.toString());
        formData.append('id', payload.id.toString());

        if(payload.file){
            formData.append('formFile', payload.file);
        }

        if(payload.fileName){
            formData.append('fileName', payload.fileName);
        }

        if(payload.expirationDate){
            formData.append('expirationDate', payload.expirationDate.toString());
        }

        if(payload.issuer){
            formData.append('issuer', payload.issuer);
        }

        const {data: result} = await coreApiInstance.put<EslavonApiResponse<Certification>>(certificationsResourceUri, formData);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const getCertificationTypes  = async (payload: GetCertificationTypesPayload) : Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        var queryString = toQueryString(payload);
        const actionUri = `${certificationsResourceUri}/types${queryString}`;        
        const {data: types} = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>(actionUri);
        return types;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}

export const deleteCertification  = async (certificationId: string) : Promise<EslavonApiResponse<boolean>> => {
    try {
        const actionUri = `${certificationsResourceUri}/${certificationId}`;        
        const {data: result} = await coreApiInstance.delete<EslavonApiResponse<boolean>>(actionUri);
        return result;
    }
    catch (error: any) {
        if(error.response.data){
            throw error.response.data;
        }
        else{
            throw error;
        }
    }
}
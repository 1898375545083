import React from 'react';
import PermissionContext from "./permissionContext";

type Props = {
    permissions: string[],
    children: React.ReactNode
}

const PermissionProvider: React.FunctionComponent<Props> = ({permissions, children}) => {
    const hasPermission = (permission: string) => permissions.includes(permission);
    const hasAllPermissions = (permissionList: string[]) => permissionList.every(r => hasPermission(r));
    const hasAtLeastOnePermission = (permissionList: string[]) => permissionList.some(r => hasPermission(r));
    
    // This component will render its children wrapped around a PermissionContext's provider whose
    // value is set to the method defined above
    return <PermissionContext.Provider value={{hasPermission, hasAllPermissions, hasAtLeastOnePermission, userPermissions: permissions}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
import { Empty, message } from "antd";
import { getWompiTransactionResult } from "api/coreapi/payments";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import ErrorMessage from "components/errorMessage";
import PaymentResult from "features/payments/paymentResult";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { WompiTransactionResult } from "shared/models/wompiTransactionModel";

const PaymentResultPage = () => {
    const [searchParams] = useSearchParams();
    const transactionId = searchParams.get("id")
    const [loading, setLoading] = useState(false);
    const [transactionResult, setTransactionResult] = useState<WompiTransactionResult>();

    useEffect(() => {
        const getInfo = async () => {
            if (!transactionId) {
                return;
            }

            try {
                setLoading(true);
                const res = await getWompiTransactionResult(transactionId);
                setTransactionResult(res.result);
            } catch (error: any) {
                if (error.errors) {
                    message.error(ErrorMessage(error.errors));
                } else {
                    message.error('Error obteniendo información de la transaccón.');
                }

            } finally {
                setLoading(false);
            }
        };

        getInfo();

    }, [transactionId])

    if (loading) {
        return <LoadingSpinner></LoadingSpinner>
    }

    if (!transactionResult) {
        return <Empty />
    }

    return (


        <PaymentResult transaction={transactionResult} />
    );

}

export default PaymentResultPage;
export function toQueryString(obj: any): string {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
      if (Array.isArray(obj[p])) {
        for (var val in obj[p]) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p][val]));
        }
      } else {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
  }

  return str.length > 0 ? "?" + str.join("&") : "";
};
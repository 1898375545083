import { Layout, Menu, MenuProps } from "antd";
import { Header } from "antd/es/layout/layout";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { useAuth } from "react-oidc-context";

const navItems: MenuProps['items'] = [
    {
        key: 'login',
        label: 'Iniciar sesión',
    },
    {
        key: 'registerSupplier',
        label: 'Registrarme como proveedor'
    },
    {
        key: 'registerCustomer',
        label: 'Registrarme como cliente'
    }
];
function ExternalLayout() {

    const auth = useAuth();
    const isAutoLoginEnabled = !window.location.href.includes('test') && !window.location.href.includes('localhost');
    if (isAutoLoginEnabled) {
        auth.signinRedirect({ state: window.location.href });
    }

    const onClick: MenuProps['onClick'] = (e) => {
        switch (e.key) {
            case 'login':
                auth.signinRedirect({ state: window.location.href });
                break;

            case 'registerSupplier':
                window.location.href = process.env.REACT_APP_ESLAVON_IDENTITYSVC_URI! + '/identity/account/register?userType=supplier';
                break;

            case 'registerCustomer':
                window.location.href = process.env.REACT_APP_ESLAVON_IDENTITYSVC_URI! + '/identity/account/register?userType=customer';
                break;
        }
    };

    if (isAutoLoginEnabled) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <Layout>
            <Header>
                <Menu theme="dark" onClick={onClick} mode="horizontal" defaultSelectedKeys={['2']} items={navItems} />
            </Header>

        </Layout>
    );

}

export default ExternalLayout;
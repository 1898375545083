import { UploadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, message, Row, Space, Typography, Upload } from "antd";
import { createCertification, editCertification } from "api/coreapi/certifications";
import CertificationTypeSelect from "components/certificationTypeSelect";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { Certification } from "shared/models/certificationModel";
import { CreateCertificationPayload } from "shared/models/payloads/createCertificationPayload";
import { EditCertificationPayload } from "shared/models/payloads/editCertificationPayload";

interface CertficationFormProps {
    supplierId?: string,
    customerId?: string,
    employeeId?: string,
    initialValue?: Certification,
    onCreate?: (certification?: Certification) => void;
    onEdit?: (certification?: Certification) => void;
    onCancel?: () => void;
}
const validDocTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

function CertificationForm(props: CertficationFormProps) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    let initialFormValue: any = {};

    if (props.initialValue) {
        initialFormValue = {
            ...props.initialValue,
            issueDate: dayjs(props.initialValue.issueDate),
            expirationDate: props.initialValue.expirationDate ? dayjs(props.initialValue.expirationDate) : undefined,
        };
    }

    useEffect(() => form.resetFields(), [form, props.initialValue]);

    const onFinish = async (values: any) => {

        try {
            setLoading(true);
            let isNewCertification = props.initialValue?.id === undefined;
            let response: EslavonApiResponse<Certification>;
            if (isNewCertification) {
                //Create certification
                let payload = values as CreateCertificationPayload;
                response = await createCertification({
                    ...payload,
                    file: values.file?.file,
                    fileName: values.file?.file?.name,
                    supplierId: props.supplierId,
                    employeeId: props.employeeId,
                    customerId: props.customerId
                });
            } else {
                //Edit existing certification
                let payload = values as EditCertificationPayload;
                response = await editCertification({
                    ...payload,
                    file: values.file?.file,
                    fileName: values.file?.file?.name,
                    id: props.initialValue!.id,
                });
            }

            message.success('Certificación guardada.');
            form.resetFields();

            if (isNewCertification && props.onCreate) {
                props.onCreate(response.result);
            } else if (!isNewCertification && props.onEdit) {
                props.onEdit(response.result);
            }
        } catch (error: any) {
            if (error.errors) {
                message.error((error.errors));
            }
            else {
                message.error('Ha ocurrido un error.');
            }

        } finally {
            setLoading(false);
        }
    };

    if (!props.customerId && !props.employeeId && !props.supplierId) {
        return (
            <Typography.Text type="danger">Invalid configuration for CreateCertificationForm component. </Typography.Text>
        );
    }

    return (
        <Form
            form={form}
            name="certificaitonForm"
            layout="vertical"
            initialValues={initialFormValue}
            onFinish={onFinish}>
            <Form.Item name="certificationTypeId" label="Tipo de certificación" rules={[{ required: true }]}>
                <CertificationTypeSelect
                    isForEmployees={props.employeeId !== undefined}
                    isForCustomers={props.customerId !== undefined}
                    isForSuppliers={props.supplierId !== undefined} />
            </Form.Item>
            <Form.Item name='issuer' label='Emitida por' rules={[{ max: 500 }]}>
                <Input type="text"></Input>
            </Form.Item>
            <Space>

                <Form.Item name="file" valuePropName="filelist">

                    <Upload
                        accept={validDocTypes.join(',')}
                        beforeUpload={() => false}>
                        <Button icon={<UploadOutlined />}>{props.initialValue?.documents?.length ? 'Reemplazar archivo' : 'Seleccionar archivo'}</Button>
                    </Upload>
                </Form.Item>
                {(props.initialValue?.documents?.length ?? 0)  > 0 
                    ? <span>{props.initialValue!.documents![0].title}</span>
                    : <span>{props.initialValue?.id ? 'No se adjuntó certificado' : ''}</span>
                }
            </Space>
            <Form.Item name='issueDate' label='Fecha de expedición' rules={[{ required: true }]}>
                <DatePicker />
            </Form.Item>
            <Form.Item name='expirationDate' label='Fecha de vencimiento'>
                <DatePicker />
            </Form.Item>
            <Row justify={"end"}>
                <Form.Item>
                    <Space>
                        <Button type="default" onClick={() => {
                            form.resetFields();
                            if (props.onCancel) {
                                props.onCancel();
                            }

                        }}>
                            Cancelar
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Guardar
                        </Button>

                    </Space>

                </Form.Item>

            </Row>


        </Form>
    );
}

export default CertificationForm;
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Space, Tooltip, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { getCustomerContacts } from 'api/coreapi/customers';
import { getCitiesWithCoverage } from 'api/coreapi/location';
import { createOrder } from 'api/coreapi/orders';
import OrderRequirementSelect from 'components/orderRequirementSelect';
import PaymentMethodSelector from 'components/paymentMethodSelector';
import ServiceTypesSelect from 'components/serviceTypesSelect';
import TruckTypeSelect from 'components/truckTypeSelect';
import { ServiceTypes } from 'constants/services';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import ContactSelector from 'features/contacts/contactSelector';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { default as PATHS, default as paths } from 'routes/paths';
import { City } from 'shared/models/cityModel';
import { Contact } from 'shared/models/contactModel';
import { Order } from 'shared/models/orderModel';
import { UpsertOrderPayload } from 'shared/models/payloads/upsertOrderPayload';
import { getDuration, getDurationAsHours } from 'utils/dateUtils';
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.locale('es');
const { TextArea } = Input;
const generalAdditionalInformationDescription = "Indicaciones puntuales del servicio. Por ejemplo especificar si la mercancía va para un piso 3, 4 o 5, si es estibada, si requiere separación y etiquetado,si requiere revisión previa al cargue y/o descargue, si el desplazamiento es superior a 30 mts, si es contenedor de 20' o de 40'  etc";
const logisticSupportAdditionalInformationDescription = "Breve descripción de las actividades o funciones a realizar.";

interface OrderFormProps {
    order?: Order
}

export const OrderForm = (props: OrderFormProps) => {
    const [selectedServiceType, setSelectedServiceType] = useState<string>();
    const [cities, setCities] = useState<City[]>();
    const [loading, setLoading] = useState(false);
    const [isNewOrder] = useState(props.order?.id === undefined);
    const [form] = useForm();
    const navigate = useNavigate();
    const [selectedStartDate, setSelectedStartDate] = useState(props.order?.startDate);
    const [selectedEndDate, setSelectedEndDate] = useState(props.order?.endDate);
    const [contacts, setContacts] = useState<Contact[]>();
    const currentUser = useAppSelector(state => state.currentUser);
    const [endDateValidationMessage, setEndDateValidationMessage] = useState<string>();
    let selectedContactIds: string[] = [];

    const initialValues = {
        ...props.order,
        serviceTypeId: props.order?.serviceTypeId?.toString(),
        startDate: props.order?.startDate ? dayjs(props.order.startDate) : null,
        endDate: props.order?.endDate ? dayjs(props.order.endDate) : null,
        shift: props.order?.shiftStartTime ? [dayjs(props.order.shiftStartTime, 'HH:mm:ss'), dayjs(props.order.shiftEndTime, 'HH:mm:ss')] : null,
        requirementIds: props.order?.requirements?.map(item => { return item.id.toString() }),
        paymentTypeId: isNewOrder ? 2 : props.order?.paymentTypeId,
        isLooseCargo: isNewOrder ? "true" : props.order?.isLooseCargo ? "true" : "false"
    }

    useEffect(() => {
        if (props.order?.serviceTypeId) {
            setSelectedServiceType(props.order.serviceTypeId.toString());
        }

        const getInfo = async () => {
            try {
                setLoading(true);
                const citiesResult = await getCitiesWithCoverage();
                setCities(citiesResult.result);
            } catch (error: any) {
                message.error('Error obteniendo información.');
            } finally {
                setLoading(false);
            }
        };
        const getContacts = async () => {
            if (!currentUser?.customer) {
                return;
            }

            try {
                const response = await getCustomerContacts(currentUser.customer.id);
                setContacts(response.result);
            } catch (error: any) {
            }
        };

        getContacts();
        getInfo();

    }, [props.order, currentUser]);
    const onFinish = async (values: any) => {
        if (selectedContactIds.length <= 0) {
            message.error('Debe seleccionar al menos una persona como contacto para este servicio.');
            return;
        }

        let payload = values as UpsertOrderPayload;
        payload = {
            ...values,
            contactIds: selectedContactIds,
            endDate: dayjs(selectedEndDate),
            shiftStartTime: values.shift ? values.shift[0].format('HH:mm') : null,
            shiftEndTime: values.shift ? values.shift[1].format('HH:mm') : null,
            isLooseCargo: values.isLooseCargo === "true",
        };
        try {
            setLoading(true);
            message.info('Guardando solicitud de servicio...');
            var result = await createOrder(payload);

            if (result.result?.id) {
                message.success('Información actualizada correctamente.');

                if (isNewOrder) {
                    navigate(PATHS.ORDER_DETAIL.replace(':orderNumber', result.result.orderNumber.toString()));
                }
            }
        } catch (error: any) {
            if (error.errors) {
                message.error(error.errors);
            }
            else {
                message.error('Ha ocurrido un error.');
            }
        }
        finally {
            setLoading(false);
        }
    };

    const onStartDateChange = (date: any) => {
        setSelectedStartDate(date);

        if (date) {
            let defaultDurationInHours = selectedServiceType === ServiceTypes.LogisticsAssistant.toString() ? 6 : 4;
            const endDate = dayjs(date).add(defaultDurationInHours, 'hours');
            setSelectedEndDate(endDate.toDate());
            form.setFieldsValue({ endDate });
            validateOrderDuration(date, selectedEndDate);
        }
    };

    const onEndDateChange = (date: any) => {
        if (!date)
            return;
        setSelectedEndDate(date);
        validateOrderDuration(selectedStartDate, date);

    };

    const validateOrderDuration = (startDate?: any, endDate?: any) => {
        let startDateToValidate = startDate ?? selectedStartDate;
        let endDateToValidate = endDate ?? selectedEndDate;

        if (!startDate || !endDate) {
            setEndDateValidationMessage(undefined);
            return;
        }

        let duration = getDurationAsHours(startDateToValidate, endDateToValidate);

        if (!duration || isNaN(duration) || duration > 12) {
            setEndDateValidationMessage("La duración máxima permitida es 12 horas.");
        }
        else {
            setEndDateValidationMessage(undefined);
        }
    }

    return (
        <Form onFinish={onFinish} form={form} initialValues={initialValues} disabled={!isNewOrder}
            labelWrap labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}>
            <Form.Item label="Forma de pago" name="paymentTypeId" rules={[{ required: true }]}>
                <PaymentMethodSelector onSelected={(paymentTypeId: number) => form.setFieldValue('paymentTypeId', paymentTypeId)}
                    disabled={!isNewOrder} ></PaymentMethodSelector>
            </Form.Item>
            <Form.Item label="Tipo servicio" name="serviceTypeId" rules={[{ required: true }]}>
                <ServiceTypesSelect onChange={(item) => (setSelectedServiceType(item?.toString()))}></ServiceTypesSelect>
            </Form.Item>
            <Form.Item label="Fecha y hora del servicio" name="startDate" rules={[{ required: true }]}>
                <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"
                    onChange={onStartDateChange} autoComplete="off" />
            </Form.Item>


            <Form.Item label="Fecha estimada finalización" name="endDate" validateStatus={endDateValidationMessage ? 'error' : 'success'}
                help={endDateValidationMessage}
                rules={[{ required: true }]}>
                <>
                    <Row>
                        <Col xxl={{ span: 8 }} lg={{ span: 14 }} md={{ span: 19 }} sm={{ span: 24 }}>
                            <DatePicker value={(selectedEndDate ? dayjs(selectedEndDate) : null)} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"
                                onChange={onEndDateChange} autoComplete="off" />
                        </Col>
                        <Col xxl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 5 }} sm={{ span: 20 }}>
                            <span>Duración estimada: {getDuration(selectedStartDate, selectedEndDate)}</span>
                        </Col>
                    </Row>
                    {selectedServiceType === ServiceTypes.LogisticsAssistant.toString() &&
                        selectedEndDate && selectedStartDate &&
                        (getDurationAsHours(selectedStartDate, selectedEndDate) ?? 0) < 6 &&
                        <Row>
                            <Alert message="Se aplicará cargo mínimo de 6 horas." type='warning'
                                style={{ marginTop: 20 }}></Alert>
                        </Row>
                    }
                </>
            </Form.Item>


            <Form.Item label="Ciudad" name="cityId" rules={[{ required: true }]}>
                <Select options={cities?.map((item) => ({ label: item.displayName, value: item.id }))} />
            </Form.Item>
            <Form.Item label="Dirección del servicio" name="addressLine1" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Requerimientos especiales" name="requirementIds">
                <OrderRequirementSelect mode='multiple'></OrderRequirementSelect>
            </Form.Item>
            <Form.Item label="# de hombres" name="peopleAmount"
                rules={[
                    { min: 1 },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            let convertedValue = Number(value);

                            if (isNaN(convertedValue)) {
                                return Promise.reject(new Error("Debe ingresar un número válido."));
                            }

                            return Promise.resolve();
                        },
                    }),
                    () => ({
                        validator(_, value) {
                            if (isNaN(value) || value < 1) {
                                return Promise.reject(new Error("Debe solicitar mínimo una persona."));
                            }

                            return Promise.resolve();
                        },
                    }),]}>
                <Input type='number'></Input>
            </Form.Item>

            {(selectedServiceType === '1' || selectedServiceType === '2') && (
                <>
                    <Form.Item name="isLooseCargo" label="Es carga suelta?"
                        rules={[{ required: true, message: 'Por favor indique si la mercancía es carga suelta o estibada.' }]}>
                        <Radio.Group optionType="button" buttonStyle="solid">
                            <Radio.Button value="true">Carga suelta</Radio.Button>
                            <Radio.Button value="false">Estibada</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name="requiresUpperFloorHandling" valuePropName='checked' label="Requiere desplazamiento a nivel(piso) superior."
                        tooltip="Marque esta casilla en caso de que requiera cargar o descargar mercancía desde un piso superior al primero.">
                        <Checkbox></Checkbox>
                    </Form.Item>

                    <Form.Item label="Mercancía" name="loadType" rules={[{ required: true, max: 100 }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Empaque" name="packageType" rules={[{ required: true, max: 100 }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Peso (Toneladas)" name="weight" rules={[{ required: true }]}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item label="Unidades" name="packagingUnits" rules={[{ required: false }]}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item label="Configuración vehículo" name="truckTypeId" rules={[{ required: true }]}>
                        <TruckTypeSelect />
                    </Form.Item>

                </>
            )}
            {/* {(selectedServiceType === '3' || selectedServiceType === '4') && (
                <>
                    <Form.Item label="Turno" name="shift" rules={[{ required: true }]}>
                        <TimePicker.RangePicker order={false} format='HH:mm' />
                    </Form.Item>
                </>
            )} */}
            <Form.Item
                label={
                    <Space>
                        Información complementaria
                        <Tooltip title={selectedServiceType === ServiceTypes.LogisticsAssistant.toString()
                            ? logisticSupportAdditionalInformationDescription
                            : generalAdditionalInformationDescription}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Space>}
                name="comments">
                <TextArea />
            </Form.Item>
            {isNewOrder && <>

                {contacts &&
                    <Row justify="center">
                        <Col>
                            <ContactSelector
                                contacts={contacts ?? []}
                                onChange={(contacts: string[]) => selectedContactIds = contacts}
                                canEdit={true}
                                label="Personas de contacto para este servicio"
                                empty={<Alert
                                    message="¡Atención!"
                                    description={
                                        <div>
                                            Para seleccionar los contactos que prestarán apoyo en el servicio, por favor registralos <Link to={paths.CONTACTS}>aquí</Link>.<br />
                                            Ingresa los detalles de los contactos para avanzar con la asignación del servicio.
                                            <br />
                                            <br />
                                            ¡Gracias por tu colaboración!
                                        </div>
                                    }
                                    type="warning"
                                />}></ContactSelector></Col>
                    </Row>
                }

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>{isNewOrder ? 'Crear solicitud' : 'Guardar'}</Button>
                </Form.Item>
            </>
            }

        </Form>
    );
};

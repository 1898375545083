import { Card, Typography } from "antd";

import SupplierForm from "features/suppliers/supplierForm";

function CreateSupplierPage(){
    return (
        <>
            <Typography.Title level={2} >Crear perfil de proveedor</Typography.Title>
            <Card style={{paddingLeft:50, paddingRight:50}}>
                <SupplierForm></SupplierForm>
            </Card>
            
        </>
    );
}

export default CreateSupplierPage;
import { Menu } from "antd";
import PermissionContext from "features/auth/permissionContext";
import { useAppSelector } from "hooks/useAppSelector";
import { useResponsive } from "hooks/useResponsive";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { LAYOUT } from "utils/layoutConstants";
import { SidebarNavigationItem, sidebarNavigation } from "./sideBarNavigation";
import { CustomerStatuses } from 'constants/customers';
import { SupplierStatuses } from "constants/suppliers";

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const filterMenuItems = (menuItems: SidebarNavigationItem[], userPermissions: string[]
  , currentCustomerStatusId?: number, currentSupplierStatusId?: number) => {

  return menuItems.filter((item) => {
    //check permissions
    return (!item.permissions || item.permissions.some(permission => userPermissions.includes(permission)))
      &&
      //check customer status    
      ((!item.requiresActiveCustomer || currentCustomerStatusId === CustomerStatuses.Active))
      &&
      //check supplier status    
      ((!item.requiresActiveSupplier || currentSupplierStatusId === SupplierStatuses.Active))
      &&
      //check supplier or customer status    
      ((!item.requiresActiveSupplierOrCustomer
        || currentSupplierStatusId === SupplierStatuses.Active
        || currentCustomerStatusId === CustomerStatuses.Active));
  });
}

export function SiderContent(props: SiderContentProps) {
  const { isTablet } = useResponsive();
  const location = useLocation();
  const { userPermissions } = useContext(PermissionContext);
  const currentUser = useAppSelector(state => state.currentUser);
  const filteredMenuItems = filterMenuItems(sidebarNavigation, userPermissions, currentUser?.customer?.customerStatusId, currentUser.supplier?.supplierStatusId);
  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];
  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];



  return (
    <div style={{
      maxHeight: `calc(100vh - ${isTablet ? LAYOUT.desktop.headerHeight : LAYOUT.mobile.headerHeight})`,
      overflowY: 'auto',
      overflowX: 'hidden'
    }}>
      <Menu
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        onClick={() => props.setCollapsed(true)}
        style={{ background: 'transparent', borderRight: 0 }}
        items={filteredMenuItems.map((nav) => {
          const isSubMenu = nav.children?.length;

          return {
            key: nav.key,
            title: (nav.title),
            label: isSubMenu ? (nav.title) : <Link to={nav.url || ''}>{(nav.title)}</Link>,
            icon: nav.icon,
            children:
              isSubMenu &&
              nav.children &&
              nav.children.map((childNav) => ({
                key: childNav.key,
                label: <Link to={childNav.url || ''}>{(childNav.title)}</Link>,
                title: (childNav.title),
              })),
          };
        })} />

    </div>
  );
}
import { Button, Form, Input, Modal, message } from 'antd';
import { rejectOrdertTenderRequest } from 'api/coreapi/orders';
import { useState } from 'react';
import { RejectOrderTenderRequestPayload } from 'shared/models/payloads/rejectOrderTenderRequestPayload';

const { TextArea } = Input;

interface RejectOrderTenderRequestModalProps {
    orderTenderRequestId: string;
    onRejected?: () => void,
}

const RejectOrderTenderRequestModal = (props: RejectOrderTenderRequestModalProps) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const rejectTender = () => {
        form.validateFields()
            .then((values: any) => {

                const reject = async (payload: RejectOrderTenderRequestPayload) => {
                    setSubmitting(true);
                    try {
                        const result = await rejectOrdertTenderRequest(payload);
                        if (!result?.result) {
                            message.error("Ocurrió un error rechazando la solicitud de cotización.");
                            return;
                        }

                        if (props.onRejected) {
                            props.onRejected();
                        }

                        setIsModalOpen(false);
                    } catch (error) {
                        message.error('Error obteniendo las órdenes.');

                    } finally {
                        setSubmitting(false);
                    }
                };

                reject({
                    rejectionReason: values.rejectionReason,
                    orderTenderRequestId: props.orderTenderRequestId
                });
            })
            .catch(() => {

            });
    };

    return (
        <>
            <Button type="primary" danger htmlType="button" disabled={isModalOpen} onClick={() => setIsModalOpen(true)}>
                Rechazar, no me interesa cotizar
            </Button>

            <Modal
                title="Rechazar solicitud de cotización de servicio"
                open={isModalOpen}
                onOk={rejectTender}
                confirmLoading={submitting}
                onCancel={() => setIsModalOpen(false)}
            >
                <Form layout="vertical" form={form}>
                    <Form.Item name="rejectionReason"
                        label="Nos gustaría saber la razón por la que no te parece interesante este servicio."
                        rules={[{ required: true, message: 'Por favor ingrese el motivo de rechazo.' }]}>
                        <TextArea rows={3}></TextArea>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    );
};

export default RejectOrderTenderRequestModal;
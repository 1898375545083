import { DeleteOutlined, MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, List, Popconfirm, Select, Space, Typography, message } from "antd";
import { getCustomerContacts } from "api/coreapi/customers";
import { addOrderContact, removeOrderContact } from "api/coreapi/orders";
import { getSupplierContacts } from "api/coreapi/suppliers";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import paths from 'routes/paths';
import { Contact } from "shared/models/contactModel";

interface OrderContactListProps {
    orderContacts?: Contact[],
    orderNumber: number,
    canEdit?: boolean,
    customerId?: string,
    supplierId?: string,
}

const OrderContactList = (props: OrderContactListProps) => {
    const [selectedContact, setSelectedContact] = useState('');
    const [selectedContacts, setSelectedContacts] = useState<Contact[]>(props.orderContacts ?? []);
    const [contacts, setContacts] = useState<Contact[]>();
    const [updatingList, setUpdatingList] = useState(false);
    const [loading, setLoading] = useState(false);

    const availableContacts = contacts?.filter(
        (contact: Contact) => !selectedContacts.some((e: Contact) => e.id === contact.id)
    );

    useEffect(() => {

        const getContacts = async () => {
            if (!props.customerId && !props.supplierId) {
                return;
            }

            try {
                setLoading(true);
                const res = props.customerId
                    ? await getCustomerContacts(props.customerId)
                    : await getSupplierContacts(props.supplierId!);
                setContacts(res.result);
            } catch (error: any) {
                message.error('Error cargando colaboradores.');
            }
            finally {
                setLoading(false);
            }
        };

        if (props.canEdit) {
            getContacts();
        }

    }, [props]);

    const handleAddContact = async () => {
        if (selectedContact !== '' && contacts) {
            const contact = contacts.find((e: Contact) => e.id === selectedContact);
            if (contact && !selectedContacts.some((e: Contact) => e.id === contact.id)) {
                setUpdatingList(true);
                try {
                    const response = await addOrderContact(props.orderNumber, contact.id);

                    if (response.isSuccess && response.result) {
                        setSelectedContacts([...selectedContacts, response.result]);
                    }
                }
                catch (error) {
                    message.error("Error agregando contacto a la orden de servicio.");
                }
                finally {
                    setUpdatingList(false);
                }
            }
            setSelectedContact('');
        }
    };

    const handleRemoveContact = async (contact: Contact) => {
        try {
            setUpdatingList(true);
            const result = await removeOrderContact(props.orderNumber, contact.id);

            if (result.isSuccess) {
                setSelectedContacts(selectedContacts.filter((e) => e.id !== contact.id));
            }
        } catch (error) {
            message.error("Ha ocurrido un error eliminando el contacto de la orden.");
        }
        finally {
            setUpdatingList(false);
        }
    };

    if (loading) {
        return <LoadingSpinner></LoadingSpinner>
    }

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4} >Contactos</Typography.Title>
            <Typography.Text type="secondary">Personas de contacto para este servicio.</Typography.Text>
            {(contacts?.length ?? 0) === 0 && props.canEdit && <Alert
                message="¡Atención!"
                description={
                    <div>
                        Para seleccionar los contactos que prestarán apoyo en el servicio, por favor registralos <Link to={paths.CONTACTS}>aquí</Link>.<br />
                        Ingresa los detalles de los contactos para avanzar con la asignación del servicio.
                        <br />
                        <br />
                        ¡Gracias por tu colaboración!
                    </div>
                }
                type="warning"
            />}
            {
                <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                    {contacts?.length && props.canEdit &&
                        <>
                            <Select
                                value={selectedContact}
                                onChange={(value) => setSelectedContact(value)}
                                placeholder="Seleccionar contacto"
                                style={{ width: '100%', marginBottom: '10px' }}
                                options={availableContacts?.map((item: Contact) => ({
                                    value: item.id.toString(),
                                    label: `${item.firstName} ${item.lastName}`
                                }))}
                            >
                            </Select>

                            <Button onClick={handleAddContact}
                                loading={updatingList}
                                style={{ width: '100%', marginBottom: '10px' }}>
                                Agregar Contacto
                            </Button>
                        </>
                    }

                    <List
                        dataSource={selectedContacts}
                        renderItem={(contact: Contact) => (
                            <List.Item key={contact.id}
                                actions={[
                                    props.canEdit
                                        ?
                                        <Popconfirm
                                            title="Atención"
                                            description="¿Seguro deseas eliminar el contacto de ésta orden de servicio?"
                                            onConfirm={() => handleRemoveContact(contact)}
                                            okButtonProps={{ loading: updatingList }}
                                        >
                                            <Button
                                                icon={<DeleteOutlined />}
                                            />
                                        </Popconfirm>
                                        : null
                                ]}
                                style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>


                                <div style={{ marginLeft: '10px' }}>
                                    <Typography.Title level={5}>{`${contact.firstName} ${contact.lastName}`}</Typography.Title>
                                    {contact.jobTitle && <div><Space><UserOutlined />{contact.jobTitle}</Space></div>}
                                    <div><Space><MailOutlined />{contact.emailAddress}</Space></div>
                                    <div><Space><PhoneOutlined />{contact.phoneNumber}</Space></div>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            }


        </Space>
    );
}

export default OrderContactList;
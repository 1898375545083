import { DeleteOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, List, Popconfirm, Select, Space, Typography } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import { Contact } from 'shared/models/contactModel';

interface ContactSelectorProps {
    contacts: Contact[],
    canEdit?: boolean,
    onChange: (selectedContacts: string[]) => void,
    empty?: ReactNode,
    label?: string,
}

const ContactSelector = (props: ContactSelectorProps) => {
    const [selectedContact, setSelectedContact] = useState<string>();
    const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
    const [updatingContactList, setUpdatingContactList] = useState(false);

    const { onChange } = props;

    useEffect(() => {
        if (typeof onChange === 'function') {
            setUpdatingContactList(true);
            onChange(selectedContacts.map((contact) => contact.id));
            setUpdatingContactList(false);

        }
    }, [selectedContacts, onChange]);

    const availableContacts = props.contacts?.filter(
        (contact: Contact) => !selectedContacts.some((e: Contact) => e.id === contact.id)
    );

    const handleSelect = (contactId: string | undefined) => {
        if (!contactId) {
            return;
        }

        const contact = props.contacts.find((e: Contact) => e.id === contactId);

        if (!contact) {
            return;
        }

        setSelectedContacts([...selectedContacts, contact]);
        setSelectedContact('');
    };

    const handleRemove = (contactId: string) => {
        const updatedItems = selectedContacts.filter(
            (contact: Contact) => contact.id !== contactId
        );
        setSelectedContacts(updatedItems);
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4} >Contactos</Typography.Title>
            <Typography.Text type="secondary">Personas de contacto para este servicio.</Typography.Text>
            {(props.contacts?.length ?? 0) === 0 && props.canEdit && <Alert
                message="¡Atención!"
                description={
                    <div>
                        Para seleccionar los contactos que prestarán apoyo en el servicio, por favor registralos <Link to={paths.CONTACTS}>aquí</Link>.<br />
                        Ingresa los detalles de los contactos para avanzar con la asignación del servicio.
                        <br />
                        <br />
                        ¡Gracias por tu colaboración!
                    </div>
                }
                type="warning"
            />}
            {
                <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                    {props.contacts?.length && props.canEdit &&
                        <>
                            <Select
                                value={selectedContact}
                                onChange={(value) => setSelectedContact(value)}
                                placeholder="Seleccionar contacto"
                                style={{ width: '100%', marginBottom: '10px' }}
                                options={availableContacts?.map((item: Contact) => ({
                                    value: item.id.toString(),
                                    label: `${item.firstName} ${item.lastName}`
                                }))}
                            >
                            </Select>

                            <Button onClick={() => handleSelect(selectedContact)}
                                loading={updatingContactList}
                                style={{ width: '100%', marginBottom: '10px' }}>
                                Agregar Contacto
                            </Button>
                        </>
                    }

                    <List
                        dataSource={selectedContacts}
                        renderItem={(contact: Contact) => (
                            <List.Item key={contact.id}
                                actions={[
                                    props.canEdit
                                        ?
                                        <Popconfirm
                                            title="Atención"
                                            description="¿Seguro deseas eliminar el contacto de ésta orden de servicio?"
                                            onConfirm={() => handleRemove(contact.id)}
                                            okButtonProps={{ loading: updatingContactList }}
                                        >
                                            <Button
                                                icon={<DeleteOutlined />}
                                            />
                                        </Popconfirm>
                                        : null
                                ]}
                                style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>


                                <div style={{ marginLeft: '10px' }}>
                                    <Typography.Title level={5}>{`${contact.firstName} ${contact.lastName}`}</Typography.Title>
                                    {contact.jobTitle && <div><Space><UserOutlined />{contact.jobTitle}</Space></div>}
                                    <div><Space><MailOutlined />{contact.emailAddress}</Space></div>
                                    <div><Space><PhoneOutlined />{contact.phoneNumber}</Space></div>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            }


        </Space>

        // <Space direction="vertical" style={{ width: '100%' }}>
        //     <Typography.Title level={4} >Contactos</Typography.Title>
        //     <Typography.Text type="secondary">{props.label}</Typography.Text>
        //     {(props.contacts?.length ?? 0) === 0 && props.canEdit && (props.empty ?? <Alert
        //         message="¡Atención!"
        //         description={
        //             <div>
        //                 No hay contactos para seleccionar.
        //             </div>
        //         }
        //         type="warning"
        //     />)}
        //     {
        //         <div style={{ maxWidth: '500px', margin: '0 auto' }}>
        //             {(props.contacts?.length ?? 0) > 0 && props.canEdit &&
        //                 <>
        //                     <Select
        //                         value={selectedContact}
        //                         onChange={setSelectedContact}
        //                         placeholder="Seleccionar contacto"
        //                         style={{ width: '100%', marginBottom: '10px' }}
        //                         options={availableEmployees?.map((item) => ({
        //                             value: item.id.toString(),
        //                             label: `${item.firstName} ${item.lastName}`
        //                         }))}
        //                     >
        //                     </Select>

        //                     <Button onClick={() => handleSelect(selectedContact)}
        //                         loading={updatingContactList}
        //                         style={{ width: '100%', marginBottom: '10px' }}>
        //                         Agregar Contacto
        //                     </Button>
        //                 </>
        //             }

        //             {((props.contacts?.length ?? 0) > 0 || (selectedContacts?.length ?? 0) > 0) &&
        //                 <List
        //                     dataSource={selectedContacts}
        //                     renderItem={(contact) => (
        //                         <List.Item key={contact.id}
        //                             actions={[
        //                                 props.canEdit
        //                                     ?
        //                                     <Popconfirm
        //                                         title="Atención"
        //                                         description="¿Seguro deseas eliminar el contacto de ésta orden de servicio?"
        //                                         onConfirm={() => handleRemove(contact.id)}
        //                                         okButtonProps={{ loading: updatingContactList }}
        //                                     >
        //                                         <Button
        //                                             icon={<DeleteOutlined />}
        //                                         />
        //                                     </Popconfirm>
        //                                     : null
        //                             ]}
        //                             style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>

        //                             <Avatar
        //                                 src={ contact.profilePictureUrl}
        //                                 size={60}
        //                                 icon={(contact.profilePictureUrl ? null : <UserOutlined />)} />
        //                             <div style={{ marginLeft: '10px' }}>
        //                                 <div>{`${contact.firstName} ${contact.lastName}`}</div>
        //                                 <div>{contact.identificationNumberTypeName}: {contact.identificationNumber}</div>
        //                             </div>
        //                         </List.Item>
        //                     )}
        //                 />}


        //         </div>
        //     }


        // </Space>
    );
};

export default ContactSelector;

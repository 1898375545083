import { Card, Col, message, Row } from "antd";
import { getMyCustomers } from "api/coreapi/customers";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import CustomerForm from "features/customers/customerForm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from 'routes/paths';
import { CustomerOverview } from "shared/models/customerOverviewModel";

function CustomerProfilePage() {
    const navigate = useNavigate();
    const [customer, setCustomer] = useState<CustomerOverview | undefined>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getCustomerInfo = async () => {
            try {
                setLoading(true);
                const res = await getMyCustomers();

                if (!res.result || res.result?.length === 0) {
                    navigate(paths.CUSTOMER_CREATE);
                    return;
                }

                setCustomer(res.result[0]);
            } catch (error: any) {
                message.error('Error obteniendo información del cliente.');
            } finally {
                setLoading(false);
            }
        };
        getCustomerInfo();
    }, [navigate]);

    return (
        <Row justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                <Card>
                    <PageTitle>Perfil del cliente</PageTitle>                    
                        {!loading && <CustomerForm customer={customer}></CustomerForm>}
                </Card>

            </Col>
        </Row>


    );
}

export default CustomerProfilePage;
export enum DocumentTypes {
    Rut = 1,
    CertificateOfExistence = 2, //Cámara de comercio
    Certificate = 3,
    Identification = 4,
    CompanyCard = 5, //Carné
    PersonalPicture = 6,
    SocialSecurity = 7,
    OrderDocument = 8,
    LegalRepresentativeIdentification = 9,
    Insurance = 10,
    ARL = 11,
    BankCertificate = 12,

}
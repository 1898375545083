import { User } from 'oidc-client-ts';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import Router from 'routes/router';
import { store } from 'store/store';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const onSigninCallback = (user: User | void): void => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  );

  if (user instanceof User) {
    let returnUrl = (user.state ?? '/') as string;
    window.location.href = returnUrl;
  }
}

const oidcConfig = {
  authority: process.env.REACT_APP_ESLAVON_IDENTITYSVC_URI!,
  client_id: 'eslavonspa',
  redirect_uri: window.location.origin + '/signin-oidc',
  post_logout_redirect_uri: window.location.origin + '/signout-callback-oidc',
  scope: 'openid profile eslavonapi',
  loadUserInfo: true,
  onSigninCallback: onSigninCallback,
};

root.render(
  <React.StrictMode>

    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <RouterProvider router={Router}></RouterProvider>
      </Provider>
    </AuthProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

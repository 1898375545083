import { CheckOutlined, GlobalOutlined } from "@ant-design/icons";
import { Alert, Button, Card, List, Popconfirm, message } from "antd";
import { acceptOrderQuote, getOrderQuotesForCustomerReview } from "api/coreapi/orders";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { OrderQuoteCustomerView } from "shared/models/orderQuoteCustomerViewModel";
import { formatMoney } from "utils/moneyUtils";

interface OrderQuotesReviewProps {
    orderNumber: string,
    onQuoteAccepted?: () => void,
}

const OrderQuotesReview = (props: OrderQuotesReviewProps) => {
    const [loading, setLoading] = useState(false);
    const { orderNumber } = props;
    const [orderQuotes, setOrderQuotes] = useState<OrderQuoteCustomerView[]>();
    const [confirmLoading, setConfirmLoading] = useState(false);


    const handleQuotationSelection = async (quote: OrderQuoteCustomerView) => {
        setConfirmLoading(true);

        try {
            const response = await acceptOrderQuote(quote.id);


            if (response?.result) {
                message.success('La cotización ha sido aceptada.');
                if (props.onQuoteAccepted) {
                    props.onQuoteAccepted();
                }
            } else {
                message.error(response.errors ?? 'Ha ocurrido un error aceptando la oferta.');
            }
        }
        catch (error: any) {

            message.error(error.errors ?? 'Ha ocurrido un error aceptando la oferta.');
        }
        finally {
            setConfirmLoading(false);
        }
    };

    useEffect(() => {
        const getInfo = async () => {
            if (!orderNumber) {
                return;
            }
            try {
                setLoading(true);
                const res = await getOrderQuotesForCustomerReview(orderNumber);
                setOrderQuotes(res.result);
            } catch (error: any) {
                message.error('Error obteniendo información de las cotizaciones.');
            } finally {
                setLoading(false);
            }
        };

        getInfo();
    }, [orderNumber]);

    if (loading) {
        return (<LoadingSpinner />);
    }

    if (!orderQuotes) {
        return (<Alert
            message="Atención"
            description="Aún estamos en la búsqueda de cotizaciones de diferentes proveedores."
            type="warning"
            showIcon
        />
        );
    }

    return (
        <>
            <p style={{ fontSize: '1.1em' }}>Te presentamos las cotizaciones disponibles de nuestros aliados para tu revisión. Acepta una de las ofertas para continuar con el proceso.<br /><br /></p>
            <List
                grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
                dataSource={orderQuotes}
                renderItem={(quotation, index) => (
                    <List.Item>
                        <Card
                            title={`Cotización # ${index + 1}`}
                            actions={[
                                <Popconfirm
                                    title={`Estás aceptando la oferta # ${index + 1}.`}
                                    description={
                                        <div>
                                            <div>Las demás ofertas serán rechazadas automáticamente.</div>
                                            <div> Deseas continuar?</div>
                                        </div>
                                    }
                                    onConfirm={() => handleQuotationSelection(quotation)}
                                    okButtonProps={{ loading: confirmLoading }}
                                >
                                    <Button
                                        type="primary"
                                        icon={<CheckOutlined />}
                                    >
                                        Aceptar oferta
                                    </Button>
                                </Popconfirm>

                            ]}
                        >
                            <p>Fecha cotización: {dayjs(quotation.createdOn).format('DD/MM/YYYY hh:mm A')}</p>
                            <p>Proveedor: {quotation.supplierName || quotation.supplierTypeName}</p>
                            <p>{quotation.supplierIdentificationTypeName}: {quotation.supplierIdentificationNumber}</p>
                            <p><GlobalOutlined /> Sitio web: {quotation.supplierWebsite ? <a target="blank" href={quotation.supplierWebsite}>Visitar</a> : <span>--</span>}</p>
                            <p><b>Total (IVA incluído): {formatMoney(quotation.total.toString())}</b></p>

                            {quotation?.comments &&
                                <Alert type="info" message='Información adicional del proveedor' description={quotation.comments} showIcon />

                            }
                        </Card>
                    </List.Item>
                )}
            />
        </>

    );
};

export default OrderQuotesReview;
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, InputNumber, message, Row, theme, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { createDocument } from 'api/coreapi/documents';
import { createSupplier, editSupplier } from 'api/coreapi/suppliers';
import CitiesAutoCompleteSelect, { ConvertToCityValue } from 'components/citiesAutoCompleteSelect';
import ErrorMessage from 'components/errorMessage';
import IdentificationNumbersSelect from 'components/identificationNumbersSelect';
import SupplierTypesSelect from 'components/supplierTypesSelect';
import { useEffect, useState } from 'react';
import { CreateDocumentPayload } from 'shared/models/payloads/createDocumentPayload';
import { UpsertSupplierPayload } from 'shared/models/payloads/upsertSupplierPayload';
import { SupplierOverview } from 'shared/models/supplierOverviewModel';
import { DocumentTypes } from 'constants/documents';

interface SupplierFormProps {
    supplier?: SupplierOverview,
    onCreate?: (newSupplier: SupplierOverview) => void,
}
const validDocTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

const SupplierForm: React.FC<SupplierFormProps> = (props) => {
    const { supplier } = props;
    const [supplierTypeId, setSupplierTypeId] = useState(props.supplier?.supplierTypeId);
    const [loading, setLoading] = useState(false);
    const [rutFileList, setRutFileList] = useState<UploadFile[]>([]);
    const [comercioFileList, setComercioFileList] = useState<UploadFile[]>([]);
    const [insuranceFileList, setInsuranceFileList] = useState<UploadFile[]>([]);
    const [identificationFileList, setIdentificationFileList] = useState<UploadFile[]>([]);
    const [bankCertificateFileList, setBankCertificateFileList] = useState<UploadFile[]>([]);
    const [form] = Form.useForm();
    const [isNewSupplier] = useState(supplier?.id === undefined);
    const {
        token: { colorInfo },
    } = theme.useToken();
    useEffect(() => form.resetFields(), [props.supplier, form]);

    const uploadProps: UploadProps = {
        accept: '.jpg,.png,.pdf',
        listType: 'picture-card',
        showUploadList: { showPreviewIcon: false },
    };

    const onFinish = async (values: any) => {

        let requestPayload: UpsertSupplierPayload = {
            supplierId: values.supplierId,
            identificationNumber: values.identificationNumber?.toString(),
            identificationNumberTypeId: values.identificationNumberTypeId,
            name: values.name,
            supplierTypeId: values.supplierTypeId,
            contact: values.contact,
            cityIds: values.cityIds?.map((item: any) => item.value as number),
            address: {
                id: values.address.id,
                address1: values.address.address1,
                cityId: values.address.cityId[0].value
            },
            website: values.website,
        }

        if (isNewSupplier && rutFileList.length <= 0) {
            form.setFields([{ name: 'rutFile', errors: ['Requerido'] }]);
            return;
        }

        if (isNewSupplier && bankCertificateFileList.length <= 0) {
            form.setFields([{ name: 'bankCertificateFile', errors: ['Requerido'] }]);
            return;
        }

        try {
            setLoading(true);

            var result = isNewSupplier
                ? await createSupplier(requestPayload)
                : await editSupplier(requestPayload);

            if (result.result?.id) {
                message.success('Información actualizada correctamente.');

                if (isNewSupplier) {
                    message.info('Cargando documentos. Esto puede tardar un par de minutos.');
                    await sendSupplierDocument(result.result?.id, DocumentTypes.Rut, rutFileList, setRutFileList);
                    await sendSupplierDocument(result.result?.id, DocumentTypes.BankCertificate, bankCertificateFileList, setBankCertificateFileList);


                    if (identificationFileList && identificationFileList.length > 0) {
                        await sendSupplierDocument(result.result?.id, DocumentTypes.LegalRepresentativeIdentification, identificationFileList, setIdentificationFileList);
                    }

                    if (comercioFileList && comercioFileList.length > 0) {
                        await sendSupplierDocument(result.result?.id, DocumentTypes.CertificateOfExistence, comercioFileList, setComercioFileList);
                    }

                    if (insuranceFileList && insuranceFileList.length > 0) {
                        await sendSupplierDocument(result.result?.id, DocumentTypes.Insurance, insuranceFileList, setInsuranceFileList);
                    }

                    if (props.onCreate) {
                        props.onCreate(result.result);
                    }
                }
            }

        } catch (error: any) {
            if (error.errors) {
                message.error(ErrorMessage(error.errors));
            }
            else {
                message.error('Ha ocurrido un error.');
            }
        }
        finally {
            setLoading(false);
        }
    };

    const onSupplierTypeChange = (value: any) => {
        setSupplierTypeId(value);
    }

    const sendSupplierDocument = async (supplierId: string,
        documentTypeId: number,
        fileList: UploadFile<any>[],
        setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>) => {
        let documentCreated = false;

        setFileList(fileList.map(item => {
            return { ...item, status: 'uploading', percent: 20 }
        }));

        const payload: CreateDocumentPayload = {
            file: fileList[0] as RcFile,
            name: fileList[0].name ?? '',
            documentTypeId: documentTypeId,
            supplierId: supplierId
        };

        try {
            documentCreated = (await createDocument(payload)).isSuccess ?? false;
        }
        catch {
            setFileList(fileList.map(item => {
                return { ...item, status: 'error', percent: 0 }
            }));
        }

        if (documentCreated) {
            setFileList(fileList.map(item => {
                return { ...item, status: 'success', percent: 0 }
            }));
        }
    }

    return (
        <Form
            form={form}
            name="customized_form_controls"
            layout="vertical"
            onFinish={onFinish}
            initialValues={supplier ? {
                supplierId: supplier.id,
                identificationNumber: supplier.identificationNumber,
                identificationNumberTypeId: supplier.identificationNumberTypeId,
                website: supplier.website,
                supplierTypeId: supplier.supplierTypeId,
                serviceTypeIds: supplier.serviceTypes.map(item => { return item.id.toString() }),
                cityIds: supplier.cities.map(item => ConvertToCityValue(item)),
                name: supplier.name,
                address: {
                    id: supplier.address?.id,
                    address1: supplier.address?.address1,
                    cityId: supplier.address?.cityId
                        ? [ConvertToCityValue({
                            ...supplier.address,
                            id: supplier.address.cityId.toString(),
                            displayName: supplier.address.cityName,
                            name: supplier.address.cityName
                        })]
                        : null
                },
                contact: {
                    id: supplier.contact?.id,
                    firstName: supplier.contact?.firstName,
                    lastName: supplier.contact?.lastName,
                    phoneNumber: supplier.contact?.phoneNumber,
                    emailAddress: supplier.contact?.emailAddress,
                    jobTitle: supplier.contact?.jobTitle
                }
            } : {}}

        >
            <Divider orientation='left' style={{ borderColor: colorInfo }}>Información general</Divider>
            <Form.Item hidden name="supplierId">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name="supplierTypeId" label="Tipo de proveedor" rules={[{ required: true }]}>
                <SupplierTypesSelect onChange={onSupplierTypeChange} />
            </Form.Item>

            <Row gutter={24} align='middle'>
                <Col xs={{ order: 2, span: 24 }} sm={{ order: 2, span: 12 }} md={{ order: 1 }} lg={{ order: 1, span: 8 }}>
                    <Form.Item name="identificationNumberTypeId" label="Tipo de identificación" rules={[{ required: true }]}>
                        <IdentificationNumbersSelect />
                    </Form.Item>
                </Col>
                <Col xs={{ order: 3, span: 24 }} sm={{ order: 3, span: 12 }} md={{ order: 2 }} lg={{ order: 2, span: 8 }}>
                    <Form.Item name="identificationNumber" label="Número identificación" rules={[{ required: true }]}>
                        <InputNumber controls={false} style={{ width: '100%' }} maxLength={15} />
                    </Form.Item>
                </Col>
            </Row>

            {supplierTypeId === 1 &&
                <>
                    <Form.Item name="name" label="Nombre comercial" rules={[{ required: true }]}>
                        <Input></Input>
                    </Form.Item>

                    <Form.Item name="website" label="Sitio web" rules={[{ max: 500, type: "url" }]}>
                        <Input></Input>
                    </Form.Item>
                </>
            }

            <Row gutter={24}>
                <Col lg={{ span: 16 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    <Form.Item hidden name={['address', 'id']}>
                        <Input type="hidden" />
                    </Form.Item>

                    <Form.Item name={['address', 'address1']} label="Dirección" rules={[{ required: true }]}>
                        <Input type='text' />
                    </Form.Item>
                </Col>
                <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                    <Form.Item name={['address', 'cityId']} label="Ciudad" rules={[{ required: true }]}>
                        <CitiesAutoCompleteSelect max={1} />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item name="cityIds" label="Ciudades donde presta servicio" rules={[{ required: true }]}>
                        <CitiesAutoCompleteSelect />
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation='left' style={{ borderColor: colorInfo, marginTop: 50 }}>Información de contacto</Divider>

            <Row gutter={24}>
                <Col lg={{ span: 8 }}>
                    <Form.Item hidden name={['contact', 'id']}>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name={['contact', 'firstName']} label="Nombres" rules={[{ required: true }]}>
                        <Input maxLength={200}></Input>
                    </Form.Item>
                </Col>
                <Col lg={{ span: 8 }}>
                    <Form.Item name={['contact', 'lastName']} label="Apellidos">
                        <Input maxLength={200}></Input>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col lg={{ span: 8 }}>
                    <Form.Item name={['contact', 'phoneNumber']} label="Número celular" rules={[{ required: true }]}>
                        <Input type="number"></Input>
                    </Form.Item>
                </Col>
                <Col lg={{ span: 8 }}>
                    <Form.Item name={['contact', 'emailAddress']} label="Correo electrónico" rules={[{ required: true }]}>
                        <Input type="email"></Input>
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Form.Item name={['contact', 'jobTitle']} label="Cargo" rules={[{ required: true }]}>
                    <Input maxLength={200}></Input>
                </Form.Item>

            </Row>

            {isNewSupplier &&
                <>
                    <Divider orientation='left' style={{ borderColor: colorInfo, marginTop: 50 }}>Documentos</Divider>
                    <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                            <Form.Item name="rutFile" label="RUT" rules={[{ required: true }]}>
                                <Upload {...uploadProps}
                                    maxCount={1}
                                    beforeUpload={(file) => {
                                        const isValidType = validDocTypes.includes(file.type);
                                        if (!isValidType) {
                                            message.error(`${file.name} no es un tipo de archivo válido.`);
                                            return false;
                                        }
                                        setRutFileList([...rutFileList, file]);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        const index = rutFileList.indexOf(file);
                                        const newFileList = rutFileList.slice();
                                        newFileList.splice(index, 1);
                                        setRutFileList(newFileList);
                                    }}
                                    fileList={rutFileList}
                                    className="upload-list-inline">
                                    {rutFileList.length === 0 && <Button icon={<PlusOutlined />}></Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                            <Form.Item name="bankCertificateFile" label="Certificación bancaria" rules={[{ required: true }]}>
                                <Upload
                                    {...uploadProps}
                                    beforeUpload={(file) => {
                                        const isValidType = validDocTypes.includes(file.type);
                                        if (!isValidType) {
                                            message.error(`${file.name} no es un tipo de archivo válido.`);
                                            return false;
                                        }
                                        setBankCertificateFileList([...bankCertificateFileList, file]);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        const index = bankCertificateFileList.indexOf(file);
                                        const newFileList = bankCertificateFileList.slice();
                                        newFileList.splice(index, 1);
                                        setBankCertificateFileList(newFileList);
                                    }}
                                    fileList={bankCertificateFileList}
                                    className="upload-list-inline">
                                    {bankCertificateFileList.length === 0 && <Button icon={<PlusOutlined />}></Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                        {supplierTypeId === 1 &&
                            <>
                                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                    <Form.Item name="comercioFile" label="Cámara de comercio" rules={[{ required: false }]}>
                                        <Upload
                                            {...uploadProps}
                                            beforeUpload={(file) => {
                                                const isValidType = validDocTypes.includes(file.type);
                                                if (!isValidType) {
                                                    message.error(`${file.name} no es un tipo de archivo válido.`);
                                                    return false;
                                                }
                                                setComercioFileList([...comercioFileList, file]);
                                                return false;
                                            }}
                                            onRemove={(file) => {
                                                const index = comercioFileList.indexOf(file);
                                                const newFileList = comercioFileList.slice();
                                                newFileList.splice(index, 1);
                                                setComercioFileList(newFileList);
                                            }}
                                            fileList={comercioFileList}
                                            className="upload-list-inline">
                                            {comercioFileList.length === 0 && <Button icon={<PlusOutlined />}></Button>}
                                        </Upload>
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                    <Form.Item name="insuranceFile" label="Póliza de responsabilidad civil" rules={[{ required: false }]}>
                                        <Upload
                                            {...uploadProps}
                                            beforeUpload={(file) => {
                                                const isValidType = validDocTypes.includes(file.type);
                                                if (!isValidType) {
                                                    message.error(`${file.name} no es un tipo de archivo válido.`);
                                                    return false;
                                                }
                                                setInsuranceFileList([...insuranceFileList, file]);
                                                return false;
                                            }}
                                            onRemove={(file) => {
                                                const index = insuranceFileList.indexOf(file);
                                                const newFileList = insuranceFileList.slice();
                                                newFileList.splice(index, 1);
                                                setInsuranceFileList(newFileList);
                                            }}
                                            fileList={insuranceFileList}
                                            className="upload-list-inline">
                                            {insuranceFileList.length === 0 && <Button icon={<PlusOutlined />}></Button>}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </>
                        }
                        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                            <Form.Item name="identificationFile" label="Documento identidad R.L" rules={[{ required: false }]}>
                                <Upload
                                    {...uploadProps}
                                    beforeUpload={(file) => {
                                        const isValidType = validDocTypes.includes(file.type);
                                        if (!isValidType) {
                                            message.error(`${file.name} no es un tipo de archivo válido.`);
                                            return false;
                                        }
                                        setIdentificationFileList([...identificationFileList, file]);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        const index = identificationFileList.indexOf(file);
                                        const newFileList = identificationFileList.slice();
                                        newFileList.splice(index, 1);
                                        setIdentificationFileList(newFileList);
                                    }}
                                    fileList={identificationFileList}
                                    className="upload-list-inline">
                                    {identificationFileList.length === 0 && <Button icon={<PlusOutlined />}></Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            }

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                </Button>
            </Form.Item>
        </Form>
    );

}

export default SupplierForm;
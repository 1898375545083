import { Card, message } from "antd";
import { getCustomerCertifications } from "api/coreapi/customers";
import { getSupplierCertifications } from "api/coreapi/suppliers";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import usePermission from "features/auth/usePermission";
import CertificationList from "features/certifications/certificationList";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { Certification } from "shared/models/certificationModel";
import { PermissionList } from "constants/permissions";

export interface CertificationListPageProps{
    OwnerType: 'customer' |'supplier',
}

export default function CertificationListPage(props: CertificationListPageProps){
    const [loading, setLoading] = useState(false);
    const [certifications, setCertifications] = useState<Certification[]>([]);
    const currentUser = useAppSelector(state => state.currentUser);

    const userCanCreateCertification = usePermission([PermissionList.SupplierCreateCertification,
                                                    PermissionList.CustomerCreateCertification]);
    const userCanEditCertification= usePermission([PermissionList.SupplierEditCertification
                                                    ,PermissionList.CustomerEditCertification]);
    const userCanDeleteCertification= usePermission([PermissionList.SupplierDeleteCertification
                                                    ,PermissionList.CustomerDeleteCertification]);

    useEffect(() => {
        const getInfo = async () => {

            try {
                setLoading(true);
                let response :EslavonApiResponse<Certification[]> | null = null;

                if(props.OwnerType === 'supplier' && currentUser?.supplier){
                    response = await getSupplierCertifications(currentUser.supplier.id);
                }

                if(props.OwnerType === 'customer' && currentUser?.customer){
                    response = await getCustomerCertifications(currentUser.customer.id);
                }

                if(response?.result){
                    setCertifications(response.result);
                }
                
            } catch (error: any) {
                message.error('Error obteniendo lista de certificaiones.');
            } finally {
                setLoading(false);
            }
        };
        getInfo();
    }, [props.OwnerType, currentUser]);


    if(loading){
        return <LoadingSpinner></LoadingSpinner>
    }

    return(
        
        <Card>
            <PageTitle>Certificaciones</PageTitle>
            <CertificationList 
                certifications={certifications}
                supplierId={props.OwnerType === 'supplier' ? currentUser?.supplier?.id : undefined}
                customerId={props.OwnerType === 'customer' ? currentUser?.customer?.id : undefined}
                canCreate={userCanCreateCertification}
                canEdit={userCanEditCertification}
                canDelete={userCanDeleteCertification}
                />
        </Card>
    );
}
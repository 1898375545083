import { UserOutlined } from "@ant-design/icons";
import { Col, Popover, Row } from "antd";
import { useResponsive } from "hooks/useResponsive";
import { useAuth } from "react-oidc-context";
import { ProfileOverlay } from "./profileOverlay";

export function ProfileDropdown() {
    const { isTablet } = useResponsive();
    const auth = useAuth();

    return auth?.user ? (
        <Popover content={<ProfileOverlay />} trigger="click">
            <Row gutter={[10, 10]} align="middle"
                style={{
                    cursor: 'pointer',
                    borderRadius: isTablet ? 50 : 'unset',
                    padding: isTablet ? '0.3125rem 0.2rem' : 'unset',
                }}>
                <Col>
                    {/* <Avatar src={auth.user.profile.nickname} alt="User" shape="circle" size={40} /> */}
                    <UserOutlined size={40} style={{ fontSize: 25, color: isTablet ? 'black' : 'white' }} />
                </Col>
                {isTablet && (
                    <Col>
                        {auth.user.profile?.name}
                        {/* <Typography.Title level={5} style={{fontWeight: 500, fontSize: isTablet ? '0.875rem' :'0.75rem', marginBottom: 0}}>
                            {auth.user.profile?.name}
                        </Typography.Title> */}
                    </Col>
                )}

            </Row>

        </Popover>
    ) : null;
}
import { ConfigProvider } from 'antd';
import esES from 'antd/locale/es_ES';
import { getMyCustomers } from 'api/coreapi/customers';
import { getMySuppliers } from 'api/coreapi/suppliers';
import { getCurrentUserPermissions } from 'api/identityapi/permissions';
import LoadingSpinner from 'components/common/loadingSpinner/loadingSpinner';
import { setCustomer, setSupplier } from 'features/auth/currentUserSlice';
import PermissionProvider from 'features/auth/permissionProvider';
import { useAppDispatch } from 'hooks/useAppDispatch';
import ExternalLayout from 'pages/externalLayout';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import PATHS from 'routes/paths';
import { EslavonDefaultThemeConfig } from 'themes/eslavonDefaultTheme';
import './App.css';
import InternalLayout from './components/layouts/internal/internalLayout';

function App() {
  const auth = useAuth();
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {

    if (auth.isAuthenticated && !permissions.length) {
      setLoadingPermissions(true);
      const getUserPermissions = async () => {
        try {
          const permissionsResult = await getCurrentUserPermissions();

          if (auth.user?.profile["eslavon.supplierid"]) {
            const suppliersResult = await getMySuppliers();

            if (suppliersResult.result && suppliersResult.result.length !== 0) {
              let supplier = suppliersResult.result[0];
              dispatcher(setSupplier({ ...supplier }));
            } else {
              navigate(PATHS.SUPPLIER_PROFILE);
            }
          } else if (auth.user?.profile["eslavon.customerid"]) {
            const customersResult = await getMyCustomers();

            if (customersResult.result && customersResult.result.length !== 0) {
              let customer = customersResult.result[0];
              dispatcher(setCustomer({ ...customer }));
            } else {
              navigate(PATHS.CUSTOMER_CREATE);
            }

          }
          setPermissions(permissionsResult);
        } catch (error: any) {
        }
        finally { setLoadingPermissions(false); }
      };

      getUserPermissions();
    }

  }, [auth.isAuthenticated, permissions.length, dispatcher, navigate, auth.user?.profile]);



  if (auth.isLoading || loadingPermissions) {
    return <LoadingSpinner></LoadingSpinner>
  }

  if (auth.error) {
    return <div>Ooops...{auth.error.message}</div>
  }

  return (<ConfigProvider
    locale={esES}
    theme={EslavonDefaultThemeConfig}
  >
    {auth.isAuthenticated
      ? <PermissionProvider permissions={permissions}>
        <InternalLayout />
      </PermissionProvider>
      : <ExternalLayout></ExternalLayout>}

  </ConfigProvider>);
}

export default App;

import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Row, Space, Typography, Upload } from "antd";
import { createDocument } from "api/coreapi/documents";
import DocumentTypeSelect from "components/documentTypeSelect";
import ErrorMessage from "components/errorMessage";
import { useState } from "react";
import { Document } from 'shared/models/documentModel';
import { CreateDocumentPayload } from "shared/models/payloads/createDocumentPayload";

interface DocumentFormProps {
    supplierId?: string,
    customerId?: string,
    employeeId?: string,
    onCreate?: (document: Document) => void;
    onCancel?: () => void;
}

const validDocTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

export default function DocumentForm(props: DocumentFormProps) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {

        try {
            setLoading(true);

            const payload: CreateDocumentPayload = {
                file: values.file.file,
                name: values.file.file.name,
                documentTypeId: values.documentTypeId,
                supplierId: props.supplierId,
                employeeId: props.employeeId,
                customerId: props.customerId
            };

            var response = await createDocument(payload);

            if (!response.result) {
                throw new Error('Error creando el documento.');
            }

            message.success('Documento creado correctamente');
            form.resetFields();

            if (props.onCreate) {
                props.onCreate(response.result);
            }
        } catch (error: any) {
            if (error.errors) {
                message.error(ErrorMessage(error.errors));
            }
            else {
                message.error('Ha ocurrido un error.');
            }

        } finally {
            setLoading(false);
        }
    };

    if (!props.customerId && !props.employeeId && !props.supplierId) {
        return (
            <Typography.Text type="danger">Invalid configuration for CreateDocumentForm component. </Typography.Text>
        );
    }

    return (
        <Form
            form={form}
            name="documentForm"
            layout="vertical"
            onFinish={onFinish}>
            <Form.Item name="documentTypeId" label="Tipo de documento" rules={[{ required: true }]}>
                <DocumentTypeSelect
                    isForEmployees={props.employeeId !== undefined ? true : undefined}
                    isForCustomers={props.customerId !== undefined ? true : undefined}
                    isForSuppliers={props.supplierId !== undefined ? true : undefined} />
            </Form.Item>
            <Form.Item name="file" rules={[{ required: true }]} valuePropName="filelist">
                <Upload
                    accept={validDocTypes.join(',')}
                    beforeUpload={() => false}>
                    <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
                </Upload>
            </Form.Item>
            <Row justify={"end"}>
                <Form.Item>
                    <Space>
                        <Button type="default" onClick={() => {
                            form.resetFields();
                            if (props.onCancel) {
                                props.onCancel();
                            }

                        }}>
                            Cancelar
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Guardar
                        </Button>

                    </Space>

                </Form.Item>

            </Row>


        </Form>
    );
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserState {
  currentUserId?: string,
  supplier?: {
    id: string,
    supplierStatusId: number
  },
  customer?: {
    id: string,
    customerStatusId: number
  },
}

const initialState: UserState = {

};

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    logout: state => {
      return { ...initialState };
    },
    setSupplier: (state, action: PayloadAction<{ id: string, supplierStatusId: number }>) => {
      return { ...state, supplier: action.payload };
    },
    setCustomer: (state, action: PayloadAction<{ id: string, customerStatusId: number }>) => {
      return { ...state, customer: action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const { logout, setSupplier, setCustomer } = currentUserSlice.actions

export default currentUserSlice.reducer
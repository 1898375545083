export enum SupplierStatuses {
    Active = 1,
    Inactive = 2,
    Unverified = 3,
    Draft = 4
}

export enum SupplierTypes {
    Company = 1,
    Crew = 2
}
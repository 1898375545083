import { Button, Col, Form, Input, InputNumber, message, Row } from "antd";
import { createEmployee } from "api/coreapi/employees";
import ErrorMessage from "components/errorMessage";
import IdentificationNumbersSelect from "components/identificationNumbersSelect";
import { useAppSelector } from "hooks/useAppSelector";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from 'routes/paths';
import { CreateEmployeePayload } from "shared/models/payloads/createEmployeePayload";

interface CreateEmployeeFormProps {
    supplierId: string;
}

function CreateEmployeeForm(props: CreateEmployeeFormProps) {

    const [loading, setLoading] = useState(false);
    const currentUserSupplierId = useAppSelector(state => state.currentUser?.supplier?.id);
    const navigate = useNavigate();
    const onFinish = async (values: any) => {

        try {
            setLoading(true);
            if (!currentUserSupplierId) {
                message.error("Acción inválida.");
                return;
            }

            let payload = values as CreateEmployeePayload;
            const response = await createEmployee({ ...payload, supplierId: currentUserSupplierId });
            message.success('Colaborador creado correctamente');

            if (response.result?.lockReasonId) {
                navigate(paths.EMPLOYEES_DETAIL.replace(':id', response.result.id));
            } else {
                navigate(paths.EMPLOYEES);
            }

        } catch (error: any) {
            if (error.errors) {
                message.error(ErrorMessage(error.errors));
            }
            else {
                message.error('Ha ocurrido un error.');
            }

        } finally {
            setLoading(false);
        }
    };

    return (
        <Form
            name="employeeForm"
            layout="vertical"
            onFinish={onFinish}>
            <Row gutter={24}>
                <Col md={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item name="identificationNumberTypeId" label="Tipo de identificación" rules={[{ required: true }]}>
                        <IdentificationNumbersSelect />
                    </Form.Item>
                </Col>
                <Col md={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item name="identificationNumber" label="Número identificación" rules={[{ required: true }]}>
                        <InputNumber controls={false} style={{ width: 200 }} maxLength={15} />
                    </Form.Item>
                </Col>
            </Row>


            <Row gutter={24}>
                <Col md={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item name={['firstName']} label="Nombres" rules={[{ required: true }]}>
                        <Input maxLength={200}></Input>
                    </Form.Item>
                </Col>
                <Col md={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item name={['lastName']} label="Apellidos" rules={[{ required: true }]}>
                        <Input maxLength={200}></Input>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col md={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item name={['phoneNumber']} label="Número celular">
                        <Input type="number"></Input>
                    </Form.Item>
                </Col>
                <Col md={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item name={['emailAddress']} label="Correo electrónico" rules={[{ max: 200 }]}>
                        <Input type="email"></Input>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={"end"}>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Guardar
                    </Button>
                </Form.Item>
            </Row>
        </Form>
    );
}

export default CreateEmployeeForm;
import { Button, Card, Form, InputNumber, message, Space, Table, Typography } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { getOrderBoard, getOrderStatuses } from "api/coreapi/orders";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import OrderStatusSelect from "components/orderStatusSelect";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerOverview } from "shared/models/customerOverviewModel";
import { EnumTypeModel } from "shared/models/enumTypeModel";
import { OrderBoard } from "shared/models/orderBoardModel";
import { GetOrderBoardPayload } from "shared/models/payloads/getOrderBoardPayload";
import { SupplierOverview } from "shared/models/supplierOverviewModel";
import { getDuration } from "utils/dateUtils";
import PATHS from "routes/paths";
import ServiceTypesSelect from "components/serviceTypesSelect";
import { DatePicker } from 'antd';
import CitiesAutoCompleteSelect, { CityValue } from "components/citiesAutoCompleteSelect";

const { RangePicker } = DatePicker;



function OrderBoardPage() {
    const [loading, setLoading] = useState(false);
    const [statuses, setStatuses] = useState<EnumTypeModel[]>();
    const [pagination, setPagination] = useState({ current: 1, pageSize: 15, total: 0 });
    const [orders, setOrders] = useState<OrderBoard[]>();
    const [selectedCityValues, setSelectedCityValues] = useState<CityValue[]>();
    const [filters, setFilters] = useState<GetOrderBoardPayload>({
        orderStatusIds: [1, 2, 3, 4, 5, 6, 7, 8],
        serviceTypeIds: [],
        orderByColumns: [],
        orderByDirection: 0,
        pageNumber: pagination.current,
        pageSize: pagination.pageSize
    });
    const navigate = useNavigate();

    const columns = [

        {
            title: "# Orden",
            dataIndex: "orderNumber",
            key: "orderNumber",
            render: (orderNumber: number, record: OrderBoard) =>
                <Button type="link" onClick={() => navigateToOrderDetail(orderNumber)}>{orderNumber}</Button>
        },
        {
            title: "Fecha servicio",
            dataIndex: "startDate",
            key: "startDate",
            sorter: (a: OrderBoard, b: OrderBoard) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
            render: (text: Date, record: OrderBoard) =>
                <Space direction="vertical">
                    {dayjs(record.startDate).format('DD-MM-YYYY hh:mm A')}
                    {record.endDate &&
                        getDuration(record.startDate, record.endDate)
                    }
                </Space>,
        },
        {
            title: "Tipo servicio",
            dataIndex: "serviceTypeName",
            key: "serviceTypeName",
            sorter: (a: OrderBoard, b: OrderBoard) => a.serviceTypeName.localeCompare(b.serviceTypeName),
        },
        {
            title: "Dirección",
            dataIndex: "addressLine1",
            key: "address",
            sorter: (a: OrderBoard, b: OrderBoard) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
            render: (text: string, record: OrderBoard) =>
                <Space direction="vertical">
                    {record.addressLine1}
                    {record.addressLine2}
                    {`${record.cityName}, ${record.stateName}`}
                </Space>,
        },
        {
            title: "Estado",
            dataIndex: "orderStatusName",
            key: "orderStatusName",
            sorter: (a: OrderBoard, b: OrderBoard) => a.orderStatusName.localeCompare(b.orderStatusName),
        },
        {
            title: "Cliente",
            dataIndex: "customer",
            key: "customerName",
            sorter: (a: OrderBoard, b: OrderBoard) => a.customer?.name.localeCompare(b.customer?.name),
            render: (customer: CustomerOverview, record: OrderBoard) =>
                <Space direction="vertical">
                    {customer.name}
                    <span>{customer.identificationNumberTypeName}: {customer.identificationNumber}</span>
                </Space>,
        },
        {
            title: "Proveedor",
            dataIndex: "supplier",
            key: "supplierName",
            sorter: (a: OrderBoard, b: OrderBoard) => a.supplier?.name?.localeCompare(b.supplier?.name ?? '') ?? 0,
            render: (supplier: SupplierOverview, record: OrderBoard) =>
                <Space direction="vertical">
                    {supplier?.name ?? supplier?.supplierTypeName}
                    <span>{supplier?.identificationNumberTypeName}: {supplier?.identificationNumber}</span>
                </Space>,
        },
        {
            title: "Creado el",
            dataIndex: "createdOn",
            key: "createdOn",
            sorter: (a: OrderBoard, b: OrderBoard) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
            render: (text: Date, record: OrderBoard) =>
                <Space direction="vertical">
                    {dayjs(record.createdOn).format('DD-MM-YYYY hh:mm A')}
                </Space>,
        },
        {
            title: "Info",
            dataIndex: "statusAdditionalInfo",
            key: "statusAdditionalInfo",
            render: (additonalInfo: string, record: OrderBoard) =>
                <div style={{ fontSize: 'x-small' }} > {additonalInfo}</div >

        },
    ];

    const navigateToOrderDetail = (orderNumber: number) => {
        navigate(PATHS.ORDER_DETAIL.replace(':orderNumber', orderNumber.toString()))
    }

    const handleTableChange = (
        pagination: any,
        tableFilters: any,
        sorter: SorterResult<OrderBoard> | SorterResult<OrderBoard>[]) => {
        let orderByColumns: string[] = [];
        let orderByDirection: 0 | 1 = 0;

        setPagination({
            ...pagination,
            current: pagination.current,
            pageSize: pagination.pageSize
        });

        if (Array.isArray(sorter)) {
            orderByColumns = sorter.map((s) => s.columnKey ? s.columnKey.toString() : '');
            orderByDirection = sorter[0].order === 'descend' ? 1 : 0;
        } else if (sorter?.columnKey) {
            orderByColumns = [sorter.columnKey.toString()];
            orderByDirection = sorter.order === 'descend' ? 1 : 0;
        }

        setFilters({
            ...filters,
            orderByColumns: orderByColumns,
            orderByDirection: orderByDirection,
        });
    };

    const onSubmit = async (values: any) => {
        setFilters({
            ...filters,
            orderStatusIds: values.orderStatusIds,
            orderNumber: values.orderNumber,
            serviceTypeIds: values.serviceTypeIds,
            startDateFrom: values.startDate ? values.startDate[0].format("YYYY-MM-DD") : undefined,
            startDateTo: values.startDate ? values.startDate[1].format("YYYY-MM-DD") : undefined,
            cityIds: selectedCityValues?.map((item: any) => item.value as number),
        });
        setPagination({ ...pagination, current: 1 });
    }

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await getOrderBoard({ ...filters, pageSize: pagination.pageSize, pageNumber: pagination.current });
                setPagination({ ...pagination, total: result.result.totalCount });
                setOrders(result.result.data);
            } catch (error) {
                message.error('Error obteniendo información de órdenes.');

            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [filters, pagination.pageSize, pagination.current]);

    useEffect(() => {

        const getInfo = async () => {
            try {
                const res = await getOrderStatuses();
                setStatuses(res.result);

            } catch (error: any) {
            }
        };

        getInfo();
    }, []);

    if (orders === undefined || loading) {
        return (<LoadingSpinner />);
    }

    return (
        <Card>
            <PageTitle>Tablero de órdenes</PageTitle>
            <div style={{ marginBottom: 16 }}>
                <Form
                    initialValues={{
                        orderStatusIds: filters.orderStatusIds.map(item => item.toString()),
                        serviceTypeIds: filters.serviceTypeIds.map(item => item.toString()),
                        orderNumber: filters.orderNumber,
                        startDate: filters.startDateFrom ? [dayjs(filters.startDateFrom), dayjs(filters.startDateTo)] : null,
                        cityIds: selectedCityValues
                    }}
                    onFinish={onSubmit}
                    layout="inline">
                    <Form.Item name="startDate" label="Fecha del servicio"
                        style={{ width: 250, marginRight: 20, marginBottom: 25 }}
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <RangePicker />
                    </Form.Item>
                    <Form.Item name='orderStatusIds' label="Estado"
                        style={{ width: 250, marginRight: 20, marginBottom: 25 }}
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <OrderStatusSelect
                            mode="tags"
                            statuses={statuses}
                            placeholder="Filtrar por estado"
                            style={{ width: 250, marginRight: 16 }} />

                    </Form.Item>
                    <Form.Item name='serviceTypeIds' label="Tipo de servicio"
                        labelCol={{ span: 24 }}
                        style={{ width: 250, marginRight: 20, marginBottom: 25 }}>
                        <ServiceTypesSelect
                            mode="tags"
                            style={{ width: 250, marginRight: 16 }}
                            maxTagCount={'responsive'} />

                    </Form.Item>
                    <Form.Item name='cityIds' label="Ciudad"
                        style={{ width: 250, marginRight: 20, marginBottom: 25 }}
                        labelCol={{ span: 24 }}>
                        <CitiesAutoCompleteSelect
                            max={20}
                            onChange={(values: CityValue | CityValue[] | undefined) => setSelectedCityValues(values as CityValue[])} />
                    </Form.Item>
                    <Form.Item name="orderNumber" label="# Orden"
                        style={{ width: 250, marginRight: 20, marginBottom: 25 }}
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <InputNumber
                            controls={false}
                            style={{ width: 250, marginRight: 16 }}
                            maxLength={15} />
                    </Form.Item>
                    <Form.Item style={{ width: 250, paddingTop: 35 }}>
                        <Button type="primary" htmlType="submit" loading={loading}>Buscar</Button>
                    </Form.Item>

                </Form>

            </div>

            <Typography.Title level={4}>{pagination.total} órdenes</Typography.Title>

            <Table
                rowKey='orderNumber'
                dataSource={orders}
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                style={{ paddingTop: 25 }}
                className="orderboard_table"
            />
        </Card>
    );
}

export default OrderBoardPage;
import { Select } from "antd";
import { getRoles } from "api/identityapi/roles";
import { useEffect, useState } from "react";
import { Role } from "shared/models/role";

interface UserSelectProps {
    value?: string;
    onChange?: (value?: string) => void;
    isForCustomers?: boolean,
    isForSuppliers?: boolean,
}

const DocumentTypeSelect: React.FC<UserSelectProps> = (props) => {
    const {
        value,
        onChange        
    } = props;
    
    var [roles, setRoles] = useState<Role[]>();
    const triggerChange = (changedValue?: string) => {
        onChange?.(changedValue);
      };

      const onSelectedItemChange = (newType: string) => {
        triggerChange(newType);
      };

    useEffect(() => {
        const getInfo = async () => {
            try {
                const res = await getRoles({
                    isForCustomers:props.isForCustomers,
                    isForSuppliers: props.isForSuppliers,
                });
                setRoles(res);
            } catch (error: any) {
            }
        };
        getInfo();
    }, [props]);


    return (
        <Select
            defaultValue={value}
            onChange={onSelectedItemChange}
            options={roles?.map((item) => ({
                value: item.id,
                label: item.displayName ?? item.id,
            }))}
        />

    );
}

export default DocumentTypeSelect;
import { Button, Divider, Modal, Popover, Row, Space, Table, Typography, message } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import Column from "antd/es/table/Column";
import { useState } from "react";
import { Contact } from "shared/models/contactModel";
import ContactForm from "./contactForm";
import { ExclamationCircleFilled, MoreOutlined } from "@ant-design/icons";
import { deleteContact } from "api/coreapi/contacts";

interface ContactListProps {
    contacts: Contact[];
    supplierId?: string,
    customerId?: string,
    canCreate?: boolean,
    canEdit?: boolean,
    canDelete?: boolean,
    onDelete?: (contactId: string) => void,
    tableSize?: SizeType
}

const { confirm } = Modal;

function ContactList(props: ContactListProps) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState<Contact>();
    const [contacts, setContacts] = useState<Contact[]>(props.contacts);

    function onCreate(contact?: Contact) {
        if (contact) {
            setContacts([...contacts, contact]);
        }

        setIsModalOpen(false);
    }

    function onEdit(contact?: Contact) {
        if (!contact) {
            setIsModalOpen(false);
            return;
        }

        const nextContacts = contacts.map(item => {
            if (item.id === contact.id) {
                return {
                    ...contact,
                };
            } else {
                return item;
            }
        });

        setContacts(nextContacts);
        setIsModalOpen(false);
    }

    function onContactCancel() {
        setIsModalOpen(false);
    }

    function editContact(contact: Contact) {
        setSelectedContact(contact);
        setIsModalOpen(true);
    }

    function onDelete(contact: Contact) {
        confirm({
            title: 'Seguro desea eliminar el contacto?',
            icon: <ExclamationCircleFilled />,
            content: <Space direction="vertical">
                {`Nombre: ${contact.firstName} ${contact.lastName}`}
                {`Correo: ${contact.emailAddress}`}
                {`Teléfono: ${contact.phoneNumber}`}
            </Space>,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {

                try {
                    const res = await deleteContact(contact.id);
                    if (res.result) {
                        setContacts(contacts.filter(function (item: Contact) {
                            return item.id !== contact.id
                        }));
                    }

                } catch (error: any) {
                    message.error('Error eliminando el contacto.');
                }
            },
        });
    }

    if (!props.customerId && !props.supplierId) {
        return (
            <Typography.Text type="danger">Invalid configuration for ContactList component. </Typography.Text>
        );
    }

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Row justify={"end"}>
                {props.canCreate &&
                    <Button type="dashed" onClick={() => {
                        setSelectedContact(undefined);
                        setIsModalOpen(true);
                    }}
                        disabled={isModalOpen} size="small">
                        Nuevo contacto
                    </Button>}
            </Row>
            <Modal footer={null}
                title="Nuevo contacto"
                closable={false}
                open={isModalOpen}>
                <ContactForm
                    contact={selectedContact}
                    customerId={props.customerId}
                    supplierId={props.supplierId}
                    onCancel={() => setIsModalOpen(false)}
                    onCreate={onCreate}
                    onEdit={onEdit}></ContactForm>
            </Modal>

            <Table dataSource={contacts} rowKey="id" size={props.tableSize}
                showHeader={contacts.length ? true : false}
                pagination={{ hideOnSinglePage: true }}>
                <Column
                    key="action"
                    width={50}
                    render={(_: any, record: Contact) => (
                        <Popover
                            placement="right"
                            trigger="click"
                            content={
                                <Space direction="vertical">
                                    <Space.Compact direction="vertical">
                                        {props.canEdit &&
                                            <>
                                                <Typography.Text>
                                                    <Button type="link" onClick={() => { editContact(record); return true; }}>Editar</Button>
                                                </Typography.Text>
                                                <Divider style={{ margin: 0 }} />
                                            </>
                                        }

                                        {props.canDelete &&
                                            <>
                                                <Typography.Text>
                                                    <Button type="link" onClick={() => onDelete(record)}>Eliminar</Button>
                                                </Typography.Text>
                                                <Divider style={{ margin: 0 }} />
                                            </>
                                        }
                                    </Space.Compact>
                                </Space>
                            }>
                            <Button type="link"><MoreOutlined style={{ fontSize: 18, cursor: 'pointer', fontWeight: 'bold' }} /></Button>
                        </Popover>
                    )}
                />
                <Column title="Nombre" dataIndex="typeName" key="typeName"
                    render={(_: any, record: Contact) => (
                        `${record.firstName} ${record.lastName}`)}
                />
                <Column title="Correo" dataIndex="emailAddress" key="emailAddress" />
                <Column title="Cargo" dataIndex="jobTitle" key="jobTitle" />
                <Column title="Teléfono" dataIndex="phoneNumber" key="phoneNumber" />
            </Table>

        </Space>

    );

}

export default ContactList;
import { Alert, Card, Col, Row, Space, message } from "antd";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import OrderQuoteForm from "features/orders/orderQuoteForm";
import OrderTenderRequestCard from "features/orders/orderTenderRequestCard";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderTenderRequest } from "shared/models/orderTenderRequestModel";
import dayjs from 'dayjs';
import RejectOrderTenderRequestModal from "features/orders/rejectOrderTenderRequestModal";
import { OrderStatuses, OrderTenderRequestStatuses } from "constants/orders";
import { getOrderTenderRequestById } from "api/coreapi/orders";
import { OrderQuote } from "shared/models/orderQuoteModel";

const OrderTenderRequestPage = () => {
    const [loading, setLoading] = useState(false);
    const { tenderRequestId } = useParams();
    const [orderTenderRequest, setOrderTenderRequest] = useState<OrderTenderRequest>();
    const [missedDeadline, setMissedDeadline] = useState<boolean>(false);
    const [isRejected, setIsRejected] = useState<boolean>(false);

    const getInfo = async () => {
        if (!tenderRequestId) {
            return;
        }
        try {
            setLoading(true);
            const res = await getOrderTenderRequestById(tenderRequestId);
            setMissedDeadline(dayjs(res.result?.quoteDeadline).isBefore(dayjs(Date.now())));
            setIsRejected(res.result?.statusId === OrderTenderRequestStatuses.Rejected);
            setOrderTenderRequest(res.result);
        } catch (error: any) {
            message.error('Error obteniendo información de la solicitud de cotización.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getInfo();
    }, [tenderRequestId]);

    if (loading) {
        return (<LoadingSpinner />);
    }

    if (orderTenderRequest?.order.orderStatusId !== OrderStatuses.Quoting
        && !orderTenderRequest?.orderQuote) {
        return (<Alert
            message="Atención"
            description="La orden no está abierta para recibir cotizaciones."
            type="error"
            showIcon
        />
        );

    }

    if (missedDeadline) {
        return (<Alert
            message="Atención"
            description={`La licitación se ha cerrado. Se recibían ofertas hasta el ${dayjs(orderTenderRequest?.quoteDeadline).format('DD-MM-YYYY HH:mm A')}`}
            type="error"
            showIcon
        />
        );
    }

    if (isRejected) {
        return (<Alert
            message="Atención"
            description={`Ésta solicitud de cotización ha sido rechazada. Motivo: ${orderTenderRequest?.rejectionReason}.`}
            type="error"
            showIcon
        />
        );
    }

    return (
        <Row justify={"center"}>
            <Col md={{ span: 16 }} span={24}>
                <Card>
                    <PageTitle>Solicitud de cotización de servicio</PageTitle>

                    {orderTenderRequest &&
                        <Space direction="vertical">
                            <OrderTenderRequestCard orderTenderRequest={orderTenderRequest}></OrderTenderRequestCard>
                            <Card title="Información de la cotización">
                                <OrderQuoteForm
                                    orderTenderRequest={orderTenderRequest}
                                    onQuoted={(quote: OrderQuote) => setOrderTenderRequest({ ...orderTenderRequest, orderQuote: quote })} />
                                {!orderTenderRequest.orderQuote && <RejectOrderTenderRequestModal
                                    orderTenderRequestId={orderTenderRequest.id}
                                    onRejected={() => getInfo()} />}
                            </Card>
                        </Space>
                    }
                </Card>
            </Col>
        </Row>
    );
};

export default OrderTenderRequestPage;
import { ExclamationCircleFilled, MoreOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, message, Modal, Popover, Row, Space, Table, Typography } from "antd";
import Column from "antd/es/table/Column";
import { deleteDocument } from "api/coreapi/documents";
import { DocumentDownloadButton } from "components/common/documentDownloadButton/documentDownloadButton";
import { useState } from "react";
import { Document } from "shared/models/documentModel";
import { formatDate } from "utils/dateUtils";
import CreateDocumentForm from "./documentForm";
import { SizeType } from "antd/lib/config-provider/SizeContext";
const { confirm } = Modal;

interface DocumentListProps {
    documents: Document[];
    supplierId?: string,
    customerId?: string,
    employeeId?: string,
    canCreate?: boolean,
    canEdit?: boolean,
    canDelete?: boolean,
    onDelete?: (documentId: string) => void,
    onCreate?: (newDocument: Document) => void,
    tableSize?: SizeType
}

function DocumentList(props: DocumentListProps) {

    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
    const [documents, setDocuments] = useState<Document[]>(props.documents);

    function onDocumentCreate(document: Document) {
        if (document) {
            setDocuments([...documents, document]);
        }

        if (props.onCreate) {
            props.onCreate(document);
        }

        setIsDocumentModalOpen(false);
    }

    function onDocumentCancel() {
        setIsDocumentModalOpen(false);
    }

    function onDocumentDelete(document: Document) {
        confirm({
            title: 'Seguro desea eliminar el documento?',
            icon: <ExclamationCircleFilled />,
            content: <Space direction="vertical">
                {'Tipo: ' + document.typeName}
                {'Nombre: ' + document.title}
            </Space>,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {

                try {
                    const res = await deleteDocument(document.id);
                    if (res.result) {
                        setDocuments(documents.filter(function (item: Document) {
                            return item.id !== document.id
                        }));
                    }

                } catch (error: any) {
                    message.error('Error eliminando el documento.');
                }
            },
        });
    }

    if (!props.customerId && !props.employeeId && !props.supplierId) {
        return (
            <Row justify={"center"}>
                <Alert type="error"
                    message="Atención"
                    description="Para acceder a esta funcionalidad, debes crear el perfil en el menú 'Mi empresa'."
                    showIcon />
            </Row>

        );
    }

    return (
        <Space direction="vertical">
            <Row justify={"end"}>
                {props.canCreate &&
                    <Button type="dashed" onClick={() => setIsDocumentModalOpen(true)}
                        disabled={isDocumentModalOpen} size="small">
                        Nuevo documento
                    </Button>}
            </Row>
            <Modal footer={null}
                title="Nuevo documento"
                closable={false}
                open={isDocumentModalOpen}
                onCancel={() => setIsDocumentModalOpen(false)}>
                <CreateDocumentForm
                    employeeId={props.employeeId}
                    supplierId={props.supplierId}
                    customerId={props.customerId}
                    onCreate={onDocumentCreate}
                    onCancel={onDocumentCancel}></CreateDocumentForm>
            </Modal>



            <Table dataSource={documents} rowKey="id" size={props.tableSize} showHeader={documents.length ? true : false}
                pagination={{ hideOnSinglePage: true }}>
                <Column
                    key="action"
                    width={50}
                    render={(_: any, record: Document) => (
                        <Popover
                            placement="right"
                            trigger="click"
                            content={
                                <Space direction="vertical">
                                    <Space.Compact direction="vertical">
                                        {props.canDelete &&
                                            <>
                                                <Typography.Text>
                                                    <Button type="link" onClick={() => onDocumentDelete(record)}>Eliminar</Button>
                                                </Typography.Text>
                                                <Divider style={{ margin: 0 }} />
                                            </>
                                        }

                                        <Typography.Text>
                                            <DocumentDownloadButton type="link" documentId={record.id}>Descargar</DocumentDownloadButton>
                                        </Typography.Text>
                                    </Space.Compact>
                                </Space>
                            }>
                            <Button type="link"><MoreOutlined style={{ fontSize: 18, cursor: 'pointer' }} /></Button>
                        </Popover>
                    )}
                />
                <Column title="Tipo" dataIndex="typeName" key="typeName" width={200} />
                <Column title="Nombre" dataIndex="title" key="title" ellipsis={true} />
                <Column title="Fecha" dataIndex="createdOn" key="createdOn" width={150}
                    render={(createdOn: Date) =>
                    (<Row justify={"center"}>
                        <div>{formatDate(createdOn, "DateOnly")}</div>
                        {formatDate(createdOn, "TimeOnly")}
                    </Row>)}
                    defaultSortOrder='ascend'
                    sorter={(a: Document, b: Document) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()}
                    sortDirections={['ascend', 'descend', 'ascend']}
                />
            </Table>

        </Space>

    );

}

export default DocumentList;
import { Select } from "antd";
import { EnumTypeModel } from "shared/models/enumTypeModel";

interface OrderStatusSelectProps {
    value?: number;
    statuses?: EnumTypeModel[],
    mode?: 'multiple' | 'tags' | undefined;
    style?: React.CSSProperties | undefined;
    placeholder?: React.ReactNode;
    onChange?: (value?: number) => void;
}

const OrderStatusSelect: React.FC<OrderStatusSelectProps> = (props) => {
    const {
        value,
        mode,
        style,
        onChange
    } = props;

    const triggerChange = (changedValue?: number) => {
        onChange?.(changedValue);
    };

    const onSelectedItemChange = (newType: number) => {
        triggerChange(newType);
    };

    return (
        <Select
            style={style}
            mode={mode}
            showArrow={true}
            showSearch={false}
            allowClear={true}
            maxTagCount={'responsive'}
            dropdownMatchSelectWidth={false}
            defaultValue={value}
            onChange={onSelectedItemChange}
            placeholder={props.placeholder}
            options={props.statuses?.map((item) => ({
                value: item.id.toString(),
                label: item.displayName ?? item.name,
            }))}
        />

    );
}

export default OrderStatusSelect;
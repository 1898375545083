import React from 'react';

type PermissionContextType = {
    hasPermission: (permission: string) => boolean;
    hasAllPermissions: (permissionList: string[]) => boolean;
    hasAtLeastOnePermission: (permissionList: string[]) => boolean;
    userPermissions: string[];
}

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider
// The permission will not be granted if no provider says otherwise
const defaultBehaviour: PermissionContextType = {
    hasPermission: () => false,
    hasAllPermissions: () => false,
    hasAtLeastOnePermission: () => false,
    userPermissions: []
}

// Create the context
const PermissionContext = React.createContext<PermissionContextType>( defaultBehaviour);

export default PermissionContext;
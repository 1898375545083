export enum OrderTenderRequestStatuses {
    PendingNotification = 1,
    Quoting = 2,
    Rejected = 3,
    Quoted = 4,
    Expired = 5,
}

export enum OrderStatuses {
    Draft = 1,
    Pending = 2,
    Quoting = 3,
    ConfirmingAvailability = 4,
    PendingSupplierSelection = 5,
    PendingPaymentConfirmation = 6,
    Scheduled = 7,
    InProgress = 8,
    Finished = 9,
    Canceled = 10

}

export enum OrderQuoteStatuses {
    PendingSupplierNotification = 1,
    ConfirmingAvailability = 2,
    SupplierApproved = 3,
    CustomerReview = 4,
    Accepted = 5,
    Rejected = 6

}

export enum OrderCancellationReasons {
    Other = 8
}
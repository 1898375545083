import { CreditCardOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useState } from 'react';

interface PaymentMethodSelectorProps {
    value?: number,
    onSelected?: (paymentTypeId: number) => void;
    disabled?: boolean
}

const PaymentMethodSelector = (props: PaymentMethodSelectorProps) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(props.value ?? 2);

    const handlePaymentMethodChange = (paymentMethod: number) => {

        if (props.disabled)
            return;

        setSelectedPaymentMethod(paymentMethod);

        if (props.onSelected) {
            props.onSelected(paymentMethod);
        }
    };

    const iconStyle = {
        fontSize: '30px',
        padding: '8px',
        cursor: props.disabled ? 'not-allowed' : 'pointer',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
    };

    const textStyle = {
        fontSize: '14px',
    };


    return (
        <div className="payment-method-selector">
            <Space size={30}>

                <Space direction='vertical' align='center' style={{ color: selectedPaymentMethod === 1 ? 'green' : 'inherit', }}>
                    <span
                        style={{
                            ...iconStyle,
                        }}
                        onClick={() => handlePaymentMethodChange(1)}
                    >
                        <DollarCircleOutlined />

                    </span>
                    <div style={textStyle}>Efectivo</div>
                </Space>

                <Space direction='vertical' align='center' style={{ color: selectedPaymentMethod === 2 ? 'green' : 'inherit', }}>
                    <span
                        style={{
                            ...iconStyle,
                        }}
                        onClick={() => handlePaymentMethodChange(2)}
                    >
                        <CreditCardOutlined />

                    </span>
                    <div style={textStyle}>Pago electrónico</div>
                </Space>
            </Space>
        </div>
    );
};

export default PaymentMethodSelector;

import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { EnumTypeModel } from "shared/models/enumTypeModel";
import { coreApiInstance } from "../apiInstance";

export const getIdentificationNumberTypes  = async () : Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        const {data: types} = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>('identificationnumbertypes');
        return types;
    }
    catch (error: any) {
        if(error.response.data){
            return error.response.data;
        }
        else{
            throw error;
        }
    }
}


export const getTruckTypes  = async () : Promise<EslavonApiResponse<EnumTypeModel[]>> => {
    try {
        const {data: types} = await coreApiInstance.get<EslavonApiResponse<EnumTypeModel[]>>('trucktypes');
        return types;
    }
    catch (error: any) {
        if(error.response.data){
            return error.response.data;
        }
        else{
            throw error;
        }
    }
}
import { ExclamationCircleFilled, MoreOutlined } from "@ant-design/icons";
import { Button, Divider, message, Modal, Popover, Row, Space, Table, Typography } from "antd";
import Column from "antd/es/table/Column";
import { deleteCertification } from "api/coreapi/certifications";
import { DocumentDownloadButton } from "components/common/documentDownloadButton/documentDownloadButton";
import { useState } from "react";
import { Certification } from "shared/models/certificationModel";
import { formatDate } from "utils/dateUtils";
import CertificationForm from "./certificationForm";
import { SizeType } from "antd/es/config-provider/SizeContext";
const { confirm } = Modal;

interface CertificationListProps {
    certifications: Certification[];
    supplierId?: string,
    customerId?: string,
    employeeId?: string,
    canCreate?: boolean,
    canEdit?: boolean,
    canDelete?: boolean,
    onDelete?: (certificaitonId: string) => void,
    tableSize?: SizeType
}

function CertificationList(props: CertificationListProps) {

    const [isCertificationModalOpen, setIsCertificationModalOpen] = useState(false);
    const [certifications, setCertifications] = useState<Certification[]>(props.certifications);
    const [selectedCertification, setSelectedCertification] = useState<Certification>();

    function onCertificationCreate(certification?: Certification) {
        if (certification) {
            setCertifications([...certifications, certification]);
        }

        setIsCertificationModalOpen(false);
    }

    function onCertificationEdit(certification?: Certification) {
        if (!certification) {
            setIsCertificationModalOpen(false);
            return;
        }

        const nextCertificaitons = certifications.map(item => {
            if (item.id === certification.id) {
                return {
                    ...certification,
                    typeName: item.typeName
                };
            } else {
                return item;
            }
        });

        setCertifications(nextCertificaitons);
        setIsCertificationModalOpen(false);
    }

    function onCertificationCancel() {
        setIsCertificationModalOpen(false);
    }

    function editCertification(certification: Certification) {
        setSelectedCertification(certification);
        setIsCertificationModalOpen(true);
    }

    function onCertificationDelete(certification: Certification) {
        confirm({
            title: 'Seguro desea eliminar la certificación?',
            icon: <ExclamationCircleFilled />,
            content: <Space direction="vertical">
                {'Tipo: ' + certification.typeName}
                {'Fecha: ' + certification.issueDate}
                {certification.expirationDate && 'Expira: ' + certification.expirationDate}
            </Space>,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {

                try {
                    const res = await deleteCertification(certification.id);
                    if (res.result) {
                        setCertifications(certifications.filter(function (item: Certification) {
                            return item.id !== certification.id
                        }));
                    }

                } catch (error: any) {
                    message.error('Error eliminando la certificación.');
                }
            },
        });
    }

    if (!props.customerId && !props.employeeId && !props.supplierId) {
        return (
            <Typography.Text type="danger">Invalid configuration for CertificationList component. </Typography.Text>
        );
    }

    return (
        <Space direction="vertical">
            <Row justify={"end"}>
                {props.canCreate &&
                    <Button type="dashed" onClick={() => {
                        setSelectedCertification(undefined);
                        setIsCertificationModalOpen(true);}}
                        disabled={isCertificationModalOpen} size="small">
                        Nueva certificación
                    </Button>}
            </Row>
            <Modal footer={null}
                title="Nueva certificación"
                closable={false}
                open={isCertificationModalOpen}
                onCancel={() => setIsCertificationModalOpen(false)}>
                <CertificationForm
                    employeeId={props.employeeId}
                    supplierId={props.supplierId}
                    customerId={props.customerId}
                    initialValue={selectedCertification}
                    onCreate={onCertificationCreate}
                    onCancel={onCertificationCancel}
                    onEdit={onCertificationEdit}></CertificationForm>
            </Modal>

            <Table dataSource={certifications} rowKey="id" size={props.tableSize} showHeader={certifications.length ? true: false}
                pagination={{hideOnSinglePage: true}}>
                <Column
                    key="action"
                    width={50}
                    render={(_: any, record: Certification) => (
                        <Popover
                            placement="right"
                            trigger="click"
                            content={
                                <Space direction="vertical">
                                    <Space.Compact direction="vertical">
                                    {props.canEdit &&
                                        <>
                                            <Typography.Text>
                                                <Button type="link" onClick={() => {editCertification(record); return true;}}>Editar</Button>
                                            </Typography.Text>
                                            <Divider style={{ margin: 0 }} />
                                        </>
                                    }

                                    {props.canDelete &&
                                        <>
                                            <Typography.Text>
                                                <Button type="link" onClick={() => onCertificationDelete(record)}>Eliminar</Button>
                                            </Typography.Text>
                                            <Divider style={{ margin: 0 }} />
                                        </>
                                    }

                                    {(record?.documents?.length ?? 0) > 0 &&
                                        <Typography.Text>
                                            <DocumentDownloadButton type="link" documentId={record.documents![0].id} >Descargar</DocumentDownloadButton>
                                        </Typography.Text>
                                    }
                                    </Space.Compact>
                                </Space>
                            }>
                            <Button type="link"><MoreOutlined style={{ fontSize: 18, cursor: 'pointer', fontWeight:'bold' }} /></Button>
                        </Popover>
                    )}
                />
                <Column title="Tipo" dataIndex="typeName" key="typeName" width={200} />
                <Column title="Certificador" dataIndex="issuer" key="issuer" ellipsis={true} />
                <Column title="Fecha" dataIndex="issueDate" key="issueDate"
                    render={(issueDate: Date) =>
                    (<Row justify={"center"}>
                        {formatDate(issueDate, "DateOnly")}
                    </Row>)}
                    defaultSortOrder='ascend'
                    sorter={(a: Certification, b: Certification) => new Date(a.issueDate).getTime() - new Date(b.issueDate).getTime()}
                    sortDirections={['ascend', 'descend', 'ascend']}
                />

                <Column title="Fecha expiración" dataIndex="expirationDate" key="expirationDate"
                    render={(expirationDate: Date) =>
                    (<Row justify={"center"}>
                        <div>{formatDate(expirationDate, "DateOnly")}</div>
                    </Row>)}
                    defaultSortOrder='ascend'
                    sorter={(a: Certification, b: Certification) => new Date(a.expirationDate ?? '').getTime() - new Date(b.expirationDate ?? '').getTime()}
                    sortDirections={['ascend', 'descend', 'ascend']}
                />
            </Table>

        </Space>

    );

}

export default CertificationList;
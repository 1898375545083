import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Avatar, Button, List, Popconfirm, Select, Space, Typography } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { Employee } from 'shared/models/employeeModel';

interface EmployeeSelectorProps {
    employees: Employee[],
    canEdit?: boolean,
    onChange: (selectedEmployees: string[]) => void,
    empty?: ReactNode,
    label?: string,
}

const EmployeeSelector = (props: EmployeeSelectorProps) => {
    const [selectedEmployee, setSelectedEmployee] = useState<string>();
    const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
    const [updatingEmployeeList, setUpdatingEmployeeList] = useState(false);

    const { onChange } = props;

    useEffect(() => {
        // Call the callback onChange with the selected employee ids whenever selectedEmployees change
        if (typeof onChange === 'function') {
            setUpdatingEmployeeList(true);
            onChange(selectedEmployees.map((employee) => employee.id));
            setUpdatingEmployeeList(false);

        }
    }, [selectedEmployees, onChange]);

    const availableEmployees = props.employees?.filter(
        (employee: Employee) => !selectedEmployees.some((e: Employee) => e.id === employee.id)
    );

    const handleEmployeeSelect = (employeeId: string | undefined) => {
        if (!employeeId) {
            return;
        }

        const employee = props.employees.find((e: Employee) => e.id === employeeId);

        if (!employee) {
            return;
        }

        setSelectedEmployees([...selectedEmployees, employee]);
        setSelectedEmployee('');
    };

    const handleRemoveEmployee = (employeeId: string) => {
        const updatedSelectedEmployees = selectedEmployees.filter(
            (employee: Employee) => employee.id !== employeeId
        );
        setSelectedEmployees(updatedSelectedEmployees);
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4} >Colaboradores</Typography.Title>
            <Typography.Text type="secondary">{props.label}</Typography.Text>
            {(props.employees?.length ?? 0) === 0 && props.canEdit && (props.empty ?? <Alert
                message="¡Atención!"
                description={
                    <div>
                        No hay colaboradores para seleccionar.
                    </div>
                }
                type="warning"
            />)}
            {
                <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                    {(props.employees?.length ?? 0) > 0 && props.canEdit &&
                        <>
                            <Alert type='info' message="No se muestran colaboradores bloqueados." showIcon />
                            <Select
                                value={selectedEmployee}
                                onChange={setSelectedEmployee}
                                placeholder="Seleccionar empleado"
                                style={{ width: '100%', marginBottom: '10px', marginTop: 10 }}
                                options={availableEmployees?.map((item) => ({
                                    value: item.id.toString(),
                                    label: `${item.firstName} ${item.lastName}`
                                }))}
                            >
                            </Select>
                            <Button onClick={() => handleEmployeeSelect(selectedEmployee)}
                                loading={updatingEmployeeList}
                                style={{ width: '100%', marginBottom: '10px' }}>
                                Seleccionar Empleado
                            </Button>
                        </>
                    }

                    {((props.employees?.length ?? 0) > 0 || (selectedEmployees?.length ?? 0) > 0) &&
                        <List
                            dataSource={selectedEmployees}
                            renderItem={(employee) => (
                                <List.Item key={employee.id}
                                    actions={[
                                        props.canEdit
                                            ?
                                            <Popconfirm
                                                title="Atención"
                                                description="¿Seguro deseas eliminar el colaborador de ésta orden de servicio?"
                                                onConfirm={() => handleRemoveEmployee(employee.id)}
                                                okButtonProps={{ loading: updatingEmployeeList }}
                                            >
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                />
                                            </Popconfirm>
                                            : null
                                    ]}
                                    style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>

                                    <Avatar
                                        src={employee.profilePictureUrl}
                                        size={60}
                                        icon={(employee.profilePictureUrl ? null : <UserOutlined />)} />
                                    <div style={{ marginLeft: '10px' }}>
                                        <div>{`${employee.firstName} ${employee.lastName}`}</div>
                                        <div>{employee.identificationNumberTypeName}: {employee.identificationNumber}</div>
                                    </div>
                                </List.Item>
                            )}
                        />}


                </div>
            }


        </Space>
    );
};

export default EmployeeSelector;

import { Card, message } from "antd";
import { getCustomerContacts } from "api/coreapi/customers";
import { getSupplierContacts } from "api/coreapi/suppliers";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import { PermissionList } from "constants/permissions";
import usePermission from "features/auth/usePermission";
import ContactList from "features/contacts/contactList";

import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { Contact } from "shared/models/contactModel";


export interface ContactListPageProps {

}

export default function ContactListPage(props: ContactListPageProps) {
    const [loading, setLoading] = useState(false);
    const [contacts, setContacts] = useState<Contact[]>([]);
    const currentUser = useAppSelector(state => state.currentUser);
    const ownerType = currentUser.customer?.id ? 'customer' : currentUser.supplier?.id ? 'supplier' : undefined;

    const userCanCreate = usePermission(PermissionList.ContactCreate);
    const userCanEdit = usePermission(PermissionList.ContactEdit);
    const userCanDelete = usePermission(PermissionList.ContactDelete);

    useEffect(() => {
        const getInfo = async () => {

            try {
                setLoading(true);
                let response: EslavonApiResponse<Contact[]> | null = null;

                if (ownerType === 'supplier' && currentUser?.supplier) {
                    response = await getSupplierContacts(currentUser.supplier.id);
                }

                if (ownerType === 'customer' && currentUser?.customer) {
                    response = await getCustomerContacts(currentUser.customer.id);
                }

                if (response?.result) {
                    setContacts(response.result);
                }

            } catch (error: any) {
                message.error('Error obteniendo lista de contactos.');
            } finally {
                setLoading(false);
            }
        };
        getInfo();
    }, [ownerType, currentUser]);


    if (loading) {
        return <LoadingSpinner></LoadingSpinner>
    }

    return (

        <Card>
            <PageTitle>Contactos</PageTitle>
            <ContactList
                contacts={contacts}
                supplierId={ownerType === 'supplier' ? currentUser?.supplier?.id : undefined}
                customerId={ownerType === 'customer' ? currentUser?.customer?.id : undefined}
                canCreate={userCanCreate}
                canEdit={userCanEdit}
                canDelete={userCanDelete}
            />
        </Card>
    );
}
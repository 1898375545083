export const PermissionList = {
    //Contact
    ContactCreate: 'contact.create',
    ContactEdit: 'contact.edit',
    ContactList: 'contact.list',
    ContactDelete: 'contact.delete',


    //Customer
    CustomerCreate: 'customer.create',
    CustomerList: 'customer.list',
    CustomerEditStatus: 'customer.editstatus',
    CustomerCreateCertification: 'customer.createcertification',
    CustomerEditCertification: 'customer.editcertification',
    CustomerListCertification: 'customer.listcertification',
    CustomerDeleteCertification: 'customer.deletecertification',
    CustomerCreateDocument: 'customer.createdocument',
    CustomerEditDocument: 'customer.editdocument',
    CustomerListDocument: 'customer.listdocument',
    CustomerDeleteDocument: 'customer.deletedocument',


    //Employee
    EmployeeCreate: 'employee.create',
    EmployeeEdit: 'employee.edit',
    EmployeeList: 'employee.list',
    EmployeeDelete: 'employee.delete',
    EmployeeVerify: 'employee.verify',
    EmployeeCreateCertification: 'employee.createcertification',
    EmployeeEditCertification: 'employee.editcertification',
    EmployeeListCertification: 'employee.listcertification',
    EmployeeDeleteCertification: 'employee.deletecertification',
    EmployeeCreateDocument: 'employee.createdocument',
    EmployeeEditDocument: 'employee.editdocument',
    EmployeeListDocument: 'employee.listdocument',
    EmployeeDeleteDocument: 'employee.deletedocument',

    //Order
    OrderCreate: 'order.create',
    OrderList: 'order.list',
    OrderCancel: 'order.cancel',
    OrderListQuotes: 'order.listquotes',
    OrderListTenders: 'order.listtenders',
    OrderBoard: 'order.board',
    AllOrdersAccess: 'order.allaccess',
    OrderListPayments: 'order.listpayments',
    OrderInternalView: 'order.internalview',

    //OrderQuote
    OrderQuoteCreate: 'orderquote.create',
    OrderQuoteList: 'orderquote.list',
    OrderQuoteReject: 'orderquote.reject',
    OrderQuoteAccept: 'orderquote.accept',
    OrderQuoteCustomerView: "orderquote.customerview",
    OrderQuoteSupplierView: "orderquote.supplierview",
    OrderQuoteInternalView: "orderquote.internalview",

    //OrderTedner
    OrderTenderCreate: 'ordertender.create',

    //Supplier
    SupplierCreate: 'supplier.create',
    SupplierList: 'supplier.list',
    SupplierEditStatus: 'supplier.editstatus',
    SupplierCreateCertification: 'supplier.createcertification',
    SupplierEditCertification: 'supplier.editcertification',
    SupplierListCertification: 'supplier.listcertification',
    SupplierDeleteCertification: 'supplier.deletecertification',
    SupplierCreateDocument: 'supplier.createdocument',
    SupplierEditDocument: 'supplier.editdocument',
    SupplierListDocument: 'supplier.listdocument',
    SupplierDeleteDocument: 'supplier.deletedocument',


    //User
    UserCreate: 'user.create',
    UserList: 'user.list',
    UserEdit: 'user.edit'

}
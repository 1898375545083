import { Card, Divider, Grid, List, Row, Space, Table, TableProps, Tag } from "antd";
import { OrderQuoteStatuses } from "constants/orders";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { OrderQuoteLine } from "shared/models/orderQuoteLineModel";
import { OrderQuoteSupplierView } from "shared/models/orderQuoteSupplierViewModel";
import { formatMoney } from "utils/moneyUtils";
const { useBreakpoint } = Grid;

interface OrderQuoteSupplierOverviewProps {
    orderQuoteSupplierView: OrderQuoteSupplierView,
}


const OrderQuoteSupplierOverview: React.FC<OrderQuoteSupplierOverviewProps> = ({ orderQuoteSupplierView }) => {

    const [orderQuoteLines, setOrderQuoteLines] = useState<OrderQuoteLine[]>();
    const screens = useBreakpoint();

    useEffect(() => {
        setOrderQuoteLines(orderQuoteSupplierView.orderQuoteLines);
    }, [orderQuoteSupplierView]);

    const columns: TableProps<OrderQuoteLine>['columns'] = [
        {
            title: 'Concepto',
            dataIndex: 'chargeTypeName',
            key: 'chargeTypeName',
        },
        {
            title: 'Valor',
            dataIndex: 'cost',
            key: 'cost',
            align: 'right',
            render: (cost: number) => cost ? formatMoney(cost) : '',
        },
        {
            title: 'IVA',
            dataIndex: 'vat',
            key: 'vat',
            align: 'right',
            render: (vat: number) => vat ? formatMoney(vat) : '',
        },
        {
            title: 'Cantidad',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
            render: (quantity: number) => quantity ? quantity.toFixed(2) : '',
        },
        {
            title: 'Total',
            align: 'right',
            render: (record: OrderQuoteLine) => { return formatMoney(record.totalCost + record.totalVAT) },
        }
    ];

    const footer = () => (
        <Row justify={"end"}>
            <strong>Total: {formatMoney(orderQuoteSupplierView.cost + orderQuoteSupplierView.supplierVat)}</strong>
        </Row>
    );

    return <>
        {orderQuoteSupplierView &&
            <Card title={screens.xs ? "Cotización" : "Información de la cotización"}>
                <Row justify={"center"}>
                    Fecha cotización: {dayjs(orderQuoteSupplierView?.createdOn).format('DD-MM-YYYY hh:mm A')}
                </Row>
                {orderQuoteSupplierView.statusId === OrderQuoteStatuses.ConfirmingAvailability &&
                    <Row justify={"center"}>
                        Confirmar hasta: {dayjs(orderQuoteSupplierView.order.quoteDeadline).format('DD-MM-YYYY hh:mm A')}
                    </Row>
                }

                {orderQuoteSupplierView.statusId !== OrderQuoteStatuses.ConfirmingAvailability &&
                    <Row justify={"center"} style={{ margin: 20 }}>
                        <Tag color={orderQuoteSupplierView.statusId === OrderQuoteStatuses.Rejected ? 'error' :
                            orderQuoteSupplierView.statusId === OrderQuoteStatuses.SupplierApproved ? 'processing' :
                                orderQuoteSupplierView.statusId === OrderQuoteStatuses.CustomerReview ? 'warning' :
                                    orderQuoteSupplierView.statusId === OrderQuoteStatuses.Accepted ? 'success' : 'default'
                        }>
                            {orderQuoteSupplierView.statusName}
                        </Tag>
                    </Row>
                }
                <Row justify={"center"}>
                    {!screens.xs &&
                        <Table
                            dataSource={orderQuoteLines}
                            columns={columns}
                            footer={footer}
                            pagination={false}
                            rowKey={"id"}
                        />}

                    {screens.xs &&
                        <>
                            <List
                                itemLayout="horizontal"
                                style={{ width: "100%" }}
                                rowKey={"id"}
                                dataSource={orderQuoteLines}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={item.chargeTypeName}
                                            description={
                                                <Space direction="vertical" key={item.id}>
                                                    {`Valor unitario: ${formatMoney(item.cost)}`}
                                                    {`IVA: ${formatMoney(item.vat)}`}
                                                    {`Cantidad: ${item.quantity.toFixed(2)}`}
                                                    {`Total: ${formatMoney(item.totalCost + item.totalVAT)}`}
                                                </Space>
                                            }
                                        />
                                    </List.Item>
                                )}
                            />
                            <Divider></Divider>
                            <Space direction="vertical" align="start" style={{ width: "100%", paddingLeft: '25px' }}>
                                {`Costo: ${formatMoney(orderQuoteSupplierView.cost)}`}
                                {`IVA: ${formatMoney(orderQuoteSupplierView.supplierVat)}`}
                                <strong>{`Total: ${formatMoney(orderQuoteSupplierView.supplierVat + orderQuoteSupplierView.cost)}`}</strong>

                            </Space>

                        </>
                    }

                    {orderQuoteSupplierView?.comments &&
                        <p>Información adicional: {orderQuoteSupplierView.comments ?? '--'}</p>
                    }
                </Row>

            </Card>

        }
    </>
}

export default OrderQuoteSupplierOverview;
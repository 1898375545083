import { Layout, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import MainHeader from "components/layouts/internal/mainHeader";
import { useResponsive } from "hooks/useResponsive";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { LAYOUT } from "utils/layoutConstants";
import MainSider from './mainSider';

function InternalLayout() {
    const [siderCollapsed, setSiderCollapsed] = useState(true);
    const toggleSider = () => setSiderCollapsed(!siderCollapsed);
    const { tabletOnly, isMobile } = useResponsive();
    const contentPadding = isMobile
        ? `${LAYOUT.mobile.paddingVertical} ${LAYOUT.mobile.paddingHorizontal}`
        : `${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal}`;
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Layout className="masterLayout" style={{ height: '100vh', background: colorBgContainer }} >
            <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
            <Layout className="mainLayout" style={{ marginLeft: tabletOnly ? '80px' : 'unset' }}>
                <MainHeader toggleSider={toggleSider} isSiderOpened={!siderCollapsed} />
                <Content id='main-content' style={{
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    //justifyContent: 'space-around',
                    padding: contentPadding,
                }}>
                    <Outlet></Outlet>
                </Content>
            </Layout>
        </Layout>
    );
}

export default InternalLayout;
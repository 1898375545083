import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Button, Row, Col, Typography, Divider, message, Collapse, Space, Switch, Alert, Grid } from 'antd';
import { ServiceType } from 'shared/models/serviceTypeModel';
import { ServiceChargeType } from 'shared/models/serviceChargeTypeModel';
import { upsertServiceQuote } from 'api/coreapi/serviceQuotes';
import ErrorMessage from 'components/errorMessage';
import { UpsertServiceQuotePayload } from 'shared/models/payloads/upsertServiceQuotePayload';
import { ServiceQuote } from 'shared/models/serviceQuoteModel';
import { ServiceQuoteLine } from 'shared/models/serviceQuoteLineModel';

const { Panel } = Collapse;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

const convertToCharges = (array: ServiceQuoteLine[] | undefined): Record<number, { value: number; vat: number }> => {
    if (!array) {
        return {};
    }

    return array.reduce((acc, curr) => {
        acc[curr.chargeTypeId] = { value: curr.cost, vat: curr.vat };
        return acc;
    }, {} as Record<number, { value: number; vat: number }>);
};

const convertToInitialValues = (array: ServiceQuoteLine[] | undefined) => {
    if (!array) {
        return {};
    }

    return array.reduce((acc, curr) => {
        acc[`value_${curr.chargeTypeId}`] = curr.cost;
        acc[`vat_${curr.chargeTypeId}`] = curr.vat;
        return acc;
    }, {} as Record<string, number>);
};

interface ServiceQuoteFormProps {
    serviceType: ServiceType;
    onSubmit?: (charges: Record<number, { value: number; vat: number }>) => void;
    serviceQuote?: ServiceQuote;
    //supplierId: string
}

const ServiceQuoteForm: React.FC<ServiceQuoteFormProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [serviceQuote, setServiceQuote] = useState(props.serviceQuote);
    const [charges, setCharges] = useState<Record<number, { value: number; vat: number }>>(convertToCharges(props.serviceQuote?.serviceQuoteLines));
    const [isActive, setIsActive] = useState<boolean>((props.serviceQuote && !props.serviceQuote.isDeleted) || false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const screens = useBreakpoint();
    let previousServiceTypeGroupId: number | null = null;

    useEffect(() => {
        const handleRouteChange = (event: any) => {
            if (unsavedChanges && !window.confirm('¿Estás seguro de que deseas abandonar esta página? Los cambios no guardados se perderán.')) {
                event.preventDefault();
            }
        };

        window.addEventListener('beforeunload', handleRouteChange);

        return () => {
            window.removeEventListener('beforeunload', handleRouteChange);
        };
    }, [unsavedChanges]);

    const handleStatusChange = async (checked: boolean) => {
        if (!serviceQuote) {
            //ServiceQuote does not exist yet
            setUnsavedChanges(true);
            setIsActive(checked);
            return;
        }

        try {
            if (checked) {
                setIsActive(checked);
                form
                    .validateFields()
                    .then(async () => {
                        await updateServiceQuote(!checked);
                    })
                    .catch((reason: any) =>
                        message.error("Ooops...parece que tienes información incompleta. Corrígela y presiona el botón 'Guardar'"));
            }
            else {
                await updateServiceQuote(!checked);
                setIsActive(checked);
            }
        }
        catch {
        }
        finally {
            setLoading(false);
        }
    }

    const handleChange = (chargeTypeId: number, key: 'value' | 'vat', value: number) => {
        setCharges({
            ...charges,
            [chargeTypeId]: {
                ...charges[chargeTypeId],
                [key]: value,
            },
        });
        setUnsavedChanges(true);
    };

    const handleSubmit = async () => {
        await updateServiceQuote(!isActive);
    };


    const updateServiceQuote = async (isDeleted: boolean) => {
        try {

            let payload: UpsertServiceQuotePayload = {
                serviceTypeId: props.serviceType.id,
                isDeleted: isDeleted,
                chargeTypeValues: Object.entries(charges).map(([chargeTypeId, { value, vat }]) => ({
                    chargeTypeId: parseInt(chargeTypeId),
                    value,
                    vat
                }))
            };

            setLoading(true);
            var result = await upsertServiceQuote(payload);

            if (result.result) {
                message.success('Información actualizada correctamente.');
                setUnsavedChanges(false);
                setServiceQuote(result.result);
            }

        } catch (error: any) {
            if (error.errors) {
                message.error(ErrorMessage(error.errors));
            }
            else {
                message.error('Ha ocurrido un error.');
            }
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <Collapse key={`serviceQuoteCollapse${props.serviceType.id}`} style={{ marginBottom: 20 }}
            // collapsible={(serviceQuoteStatuses[serviceType.id] ? 'icon' : 'disabled')}
            activeKey={(isActive ? [`serviceQuotePanel${props.serviceType.id}`] : [''])}
            collapsible="icon"
            expandIconPosition="end"
            expandIcon={(props) =>
                <Space>
                    <Switch
                        // checkedChildren="Desactivar"
                        // unCheckedChildren="Activar"

                        checked={isActive}
                        disabled={loading}
                        loading={loading}
                        onChange={handleStatusChange}
                    />
                </Space>
            }>
            <Panel key={`serviceQuotePanel${props.serviceType.id}`}
                showArrow={true}
                header={
                    <span style={{ fontSize: '15px' }}>{props.serviceType.displayName}</span>
                }>
                {
                    (props.serviceType?.id === 1 || props.serviceType?.id === 2) &&
                    <span>
                        Cotización para el servicio '{props.serviceType.displayName}' de mercancía estibada.
                    </span>
                }
                {

                    !serviceQuote &&
                    <Row justify="center" style={{ marginBottom: 40, marginTop: 20 }}>
                        <Alert
                            message="Atención"
                            description="Para completar la activación del servicio, debes cotizar los conceptos del siguiente formulario y presionar el botón 'Guardar'."
                            type="info"
                            showIcon
                        />
                    </Row>
                }

                <Form form={form} layout="vertical" onFinish={handleSubmit}
                    initialValues={convertToInitialValues(props.serviceQuote?.serviceQuoteLines)}>
                    {screens.lg &&
                        <Row gutter={[16, 16]} align="top" style={{ marginBottom: '16px' }}>
                            <Col xs={{ span: 24, order: 1 }} lg={{ span: 7, order: 1 }}></Col>
                            <Col xs={{ span: 12, order: 3 }} lg={{ span: 5, order: 2 }}><strong>Vr. Unitario</strong></Col>
                            <Col xs={{ span: 12, order: 4 }} lg={{ span: 5, order: 3 }}><strong>Iva</strong></Col>
                            <Col xs={{ span: 24, order: 2 }} lg={{ span: 7, order: 4 }}></Col>
                        </Row>
                    }

                    {props.serviceType.serviceChargeTypes.map((charge: ServiceChargeType) => {
                        const { chargeType } = charge;
                        const { chargeTypeId } = charge;
                        let shouldRenderGroupSection = previousServiceTypeGroupId !== charge.serviceChargeTypeGroupId;
                        previousServiceTypeGroupId = charge.serviceChargeTypeGroupId;

                        return (
                            <div key={chargeTypeId}>
                                {shouldRenderGroupSection &&
                                    <Row key={`chargeGroup${charge.serviceChargeTypeGroupId}`} style={{ marginBottom: 40 }}>
                                        <Title level={5}>{charge.serviceChargeTypeGroup?.displayName}</Title>
                                        <Divider ></Divider>
                                        <Text type='secondary'>{charge.serviceChargeTypeGroup?.description}</Text>
                                    </Row>
                                }
                                <Row gutter={[16, 16]} align="top" style={{ marginBottom: '16px' }}>
                                    <Col xs={{ span: 24, order: 1 }} lg={{ span: 7, order: 1 }}>
                                        <span>{chargeType.displayName}</span>
                                    </Col>
                                    <Col xs={{ span: 24, order: 3 }} sm={{ span: 12, order: 4 }} lg={{ span: 5, order: 2 }}>
                                        <Form.Item name={`value_${chargeTypeId}`} label={screens.lg ? undefined : 'Vr. Unitario'}
                                            rules={[{ required: !charge.isValueRequired, message: 'Debe ingresar un valor' }]}>
                                            <InputNumber controls={false} width={550} decimalSeparator='.' precision={2}
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: 120 }} size="small"
                                                value={charges[chargeTypeId]?.value}
                                                onChange={e => handleChange(chargeTypeId, 'value', parseFloat(e?.toString() ?? '0'))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24, order: 4 }} sm={{ span: 12, order: 4 }} lg={{ span: 5, order: 3 }}>
                                        <Form.Item name={`vat_${chargeTypeId}`} label={screens.lg ? undefined : 'Iva'}
                                            rules={[{ required: charge.isVatRequired, message: 'Debe ingresar un valor de IVA' }]}>
                                            <InputNumber
                                                controls={false} width={550} decimalSeparator='.' precision={2}
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: 120 }} size="small"
                                                value={charges[chargeTypeId]?.vat}
                                                onChange={e => handleChange(chargeTypeId, 'vat', parseFloat(e?.toString() ?? '0'))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24, order: 2 }} lg={{ span: 7, order: 4 }}>
                                        <Text type="secondary">{chargeType.description}</Text>
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Panel>
        </Collapse>
    );
};

export default ServiceQuoteForm;
import { Card, Descriptions, Grid } from "antd";
import { Order } from "shared/models/orderModel";
import dayjs from 'dayjs';
import { getDuration } from 'utils/dateUtils';
import { ServiceTypes } from "constants/services";

const { useBreakpoint } = Grid;

interface OrderDetailProps {
    order: Order,
}

const OrderDetail = (props: OrderDetailProps) => {

    const { order } = props;
    const screens = useBreakpoint();
    const columns = screens.xs ? 1 : 2;

    return (
        <Card title="Información del servicio">

            <Descriptions column={columns} layout={screens.xs ? 'vertical' : 'horizontal'} bordered>
                <Descriptions.Item label="Orden #">{order.orderNumber}</Descriptions.Item>
                <Descriptions.Item label="Tipo de servicio">
                    {order.serviceTypeName}
                    {(order.serviceTypeId === ServiceTypes.Load || order.serviceTypeId === ServiceTypes.Unload
                        ? order.isLooseCargo ? ' (Carga suelta)' : ' (Mercancía estibada)'
                        : '')}
                </Descriptions.Item>
                <Descriptions.Item label="Fecha inicio">{dayjs(order.startDate).format('DD-MM-YYYY hh:mm A')}</Descriptions.Item>
                {order.endDate &&
                    <Descriptions.Item label="Fecha fin">
                        {dayjs(order.endDate).format('DD-MM-YYYY hh:mm A')}
                    </Descriptions.Item>
                }

                {order.endDate &&
                    <Descriptions.Item label="Duración estimada">
                        {getDuration(order.startDate, order.endDate)}
                    </Descriptions.Item>
                }
                <Descriptions.Item label="Dirección">{order.addressLine1} {order.cityName}, {order.stateName}</Descriptions.Item>

                {order.peopleAmount && <Descriptions.Item label="# de personas">{order.peopleAmount}</Descriptions.Item>}
                {order.loadType && <Descriptions.Item label="Tipo de carga">{order.loadType}</Descriptions.Item>}
                {order.packageType && <Descriptions.Item label="Empaque">{order.packageType}</Descriptions.Item>}
                {order.packagingUnits && <Descriptions.Item label="Unidades">{order.packagingUnits}</Descriptions.Item>}
                {order.weight && <Descriptions.Item label="Peso (TON)">{order.weight}</Descriptions.Item>}
                {order.truckTypeName && <Descriptions.Item label="Tipo de vehículo">{order.truckTypeName}</Descriptions.Item>}
                {order.shiftStartTime && order.shiftStartTime !== "00:00:00" &&
                    <Descriptions.Item label="Turno">{order.shiftStartTime} - {order.shiftEndTime}</Descriptions.Item>
                }
                {(order.serviceTypeId === ServiceTypes.Load || order.serviceTypeId === ServiceTypes.Unload) &&
                    <Descriptions.Item label="Requiere desplazamiento a piso superior?">{order.requiresUpperFloorHandling ? 'Si' : 'No'}</Descriptions.Item>
                }
                <Descriptions.Item label="Requerimientos especiales">
                    {order.requirements.map((req) => req.name).join(', ')}
                </Descriptions.Item>
                {order.comments && <Descriptions.Item label="Información complementaria">{order.comments}</Descriptions.Item>}
            </Descriptions>
            {/* <Descriptions column={1} layout={screens.xs ? 'vertical' : 'horizontal'} bordered>
                
            </Descriptions> */}
        </Card>
    );

};

export default OrderDetail;
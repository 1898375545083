import { Select } from "antd";
import { getCustomerStatuses } from "api/coreapi/customers";
import { useEffect, useState } from "react";
import { EnumTypeModel } from "shared/models/enumTypeModel";

interface CustomerStatusSelectProps {
    value?: number;
    onChange?: (value?: number) => void;
    mode?: 'multiple' | 'tags';
    placeholder?: React.ReactNode;
    style?:React.CSSProperties;
}

const CustomerStatusSelect: React.FC<CustomerStatusSelectProps> = (props) => {
    const {
        value,
        onChange        
    } = props;
    
    var [statuses, setStatuses] = useState<EnumTypeModel[]>();
    const triggerChange = (changedValue?: number) => {
        onChange?.(changedValue);
      };

      const onSelectedItemChange = (newType: number) => {
        triggerChange(newType);
      };

    useEffect(() => {
        const getInfo = async () => {
            try {
                const res = await getCustomerStatuses();
                setStatuses(res.result);
            } catch (error: any) {
            }
        };
        getInfo();
    }, []);


    return (
        <Select
        placeholder={props.placeholder}
        style={props.style}
        mode={props.mode}
            defaultValue={value}
            onChange={onSelectedItemChange}
            options={statuses?.map((item) => ({
                value: item.id,
                label: item.displayName ?? item.name,
            }))}
        />

    );
}

export default CustomerStatusSelect;
import { Result, Button } from 'antd';
import { WompiTransactionStatuses } from 'constants/payments';
import { useNavigate } from 'react-router-dom';
import { WompiTransactionResult } from 'shared/models/wompiTransactionModel';
import PATHS from 'routes/paths';


interface PaymentResultProps {
    transaction: WompiTransactionResult
}

const PaymentResult = (props: PaymentResultProps) => {
    const { id, amountInCents, reference, currency, paymentMethodType, status } = props.transaction;
    const navigate = useNavigate();

    const goToOrder = () => {
        navigate(PATHS.ORDER_DETAIL.replace(':orderNumber', reference));
    }

    return (
        <Result
            status={status === WompiTransactionStatuses.Approved ? 'success' : 'error'}
            title={status === WompiTransactionStatuses.Approved ? 'Pago Aprobado' : 'Pago Rechazado'}
            subTitle={status === WompiTransactionStatuses.Approved ? 'Su pago ha sido procesado exitosamente.' : 'Lo sentimos, su pago fue rechazado.'}
            extra={[
                <p key="amount">Monto: {amountInCents / 100} {currency}</p>,
                <p key="reference">Referencia: {reference}</p>,
                <p key="email">Número Transacción: {id}</p>,
                <p key="method">Método de pago: {paymentMethodType}</p>,
                <Button type="primary" key="return" onClick={goToOrder}>
                    Ir a la order de servicio
                </Button>,
            ]}
        />
    );
};

export default PaymentResult;
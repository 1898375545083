import { message } from "antd";
import axios from "axios";
import { User } from "oidc-client-ts";
import paths from "routes/paths";

function getUser() {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_ESLAVON_IDENTITYSVC_URI}:${process.env.REACT_APP_ESLAVON_SPA_CLIENT_ID}`);

    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

export const coreApiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_ESLAVON_CORESVC_URI}/api/`,
})

export const identityApiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_ESLAVON_IDENTITYSVC_URI}/api/`,
})

coreApiInstance.interceptors.request.use(
    (request) => {
        request.headers['Authorization'] = `Bearer ${getUser()?.access_token}`
        return request;
    },
    (err) => console.error('Interceptor Token' + err)
)

identityApiInstance.interceptors.request.use(
    (request) => {
        request.headers['Authorization'] = `Bearer ${getUser()?.access_token}`
        return request;
    },
    (err) => console.error('Interceptor Token' + err)
)

coreApiInstance.interceptors.response.use(response => {
    return response;
}, error => {

    if (error.response.status === 403) {
        message.error("No tienes autorización para acceder a este recurso.");
        window.location.href = paths.FORBIDDEN;
        //place your reentry code
    }

    throw error;
});
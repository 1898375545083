import { message } from "antd";
import { getOrderQuote, getOrderQuoteCustomerView, getOrderQuoteSupplierView } from "api/coreapi/orders";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PermissionList } from "constants/permissions";
import usePermission from "features/auth/usePermission";
import { useEffect, useState } from "react";
import { OrderQuoteCustomerView } from "shared/models/orderQuoteCustomerViewModel";
import { OrderQuoteSupplierView } from "shared/models/orderQuoteSupplierViewModel";
import OrderQuoteCustomerOverview from "./orderQuoteCustomerOverview";
import OrderQuoteSupplierOverview from "./orderQuoteSupplierOverview";
import OrderQuoteInternalOverview from "./orderQuoteInternalOverview";
import { OrderQuote } from "shared/models/orderQuoteModel";

interface OrderQuoteDetailProps {
    orderQuoteId: string,
    orderQuote?: OrderQuote
}

const OrderQuoteDetail: React.FC<OrderQuoteDetailProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const userHasCustomerView = usePermission(PermissionList.OrderQuoteCustomerView);
    const userHasSupplierView = usePermission(PermissionList.OrderQuoteSupplierView);
    const userHasInternalView = usePermission(PermissionList.OrderQuoteInternalView);
    const [orderQuoteCustomerView, setOrderQuoteCustomerView] = useState<OrderQuoteCustomerView>();
    const [orderQuoteSupplierView, setOrderQuoteSupplierView] = useState<OrderQuoteSupplierView>();
    const [orderQuote, setOrderQuote] = useState<OrderQuote>();

    useEffect(() => {
        const getInfo = async () => {

            try {
                setLoading(true);

                if (userHasCustomerView) {
                    const response = await getOrderQuoteCustomerView(props.orderQuoteId);
                    setOrderQuoteCustomerView(response.result);
                } else if (userHasSupplierView) {
                    const response = await getOrderQuoteSupplierView(props.orderQuoteId);
                    setOrderQuoteSupplierView(response.result);
                } else if (userHasInternalView) {
                    const response = await getOrderQuote(props.orderQuoteId);
                    setOrderQuote(response.result);
                }
            } catch (error: any) {
                message.error('Error obteniendo la cotización.');
            } finally {
                setLoading(false);
            }
        };

        if (props.orderQuote) {
            setOrderQuote(props.orderQuote);
        }
        else {
            getInfo();
        }
    }, [props.orderQuoteId, props.orderQuote, userHasCustomerView, userHasSupplierView, userHasInternalView]);

    if (loading) {
        return <LoadingSpinner></LoadingSpinner>
    }

    if (orderQuoteCustomerView) {
        return <OrderQuoteCustomerOverview orderQuoteCustomerView={orderQuoteCustomerView} />
    }

    if (orderQuoteSupplierView) {
        return <OrderQuoteSupplierOverview orderQuoteSupplierView={orderQuoteSupplierView} />
    }

    if (orderQuote) {
        return <OrderQuoteInternalOverview orderQuote={orderQuote} />
    }

    return <></>;
}

export default OrderQuoteDetail;
import { Space, Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { Employee } from "shared/models/employeeModel";
import paths from 'routes/paths';
import Restricted from "features/auth/restricted";
import { PermissionList } from "constants/permissions";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { StopOutlined } from "@ant-design/icons";

const { Column } = Table;
interface EmployeeListProps {
  employees: Employee[];
  tableSize?: SizeType;
}

function EmployeeList(props: EmployeeListProps) {

  return (
    <>
      <Table dataSource={props.employees} scroll={{ x: 800 }} rowKey="id" size={props.tableSize}>
        <Column title="Identificación" dataIndex="identificationNumber" key="identificationNumber" />
        <Column title="Nombres" dataIndex="firstName" key="firstName" />
        <Column title="Apellidos" dataIndex="lastName" key="lastName" />
        <Column title="Estado" key="statusName"
          render={(_: any, record: Employee) => {

            return record.lockReasonId
              ? <Tag color="error" icon={<StopOutlined />}>Bloqueado</Tag>
              : record.statusName
          }} />

        <Column
          title=""
          key="action"
          render={(_: any, record: Employee) => (
            <Space size="middle">
              <Restricted to={PermissionList.EmployeeEdit}>
                <Link to={`${paths.EMPLOYEES}/${record.id}`}>Editar</Link>
              </Restricted>
              <Restricted to={PermissionList.EmployeeVerify}>
                <Link to={`${paths.EMPLOYEES}/${record.id}`}>Ver</Link>
              </Restricted>
            </Space>
          )}
        />
      </Table>
    </>
  );


}

export default EmployeeList;
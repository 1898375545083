import { CaretRightOutlined } from "@ant-design/icons";
import { Alert, Collapse, Space, message, theme } from "antd";
import { getEmployeeById } from "api/coreapi/employees";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PermissionList } from "constants/permissions";
import usePermission from "features/auth/usePermission";
import CertificationList from "features/certifications/certificationList";
import DocumentList from "features/documents/documentList";
import EmployeePersonalForm from "features/employees/employeePersonalForm";
import LockEmployeeButton from "features/employees/lockEmployeeButton";
import UnlockEmployeeButton from "features/employees/unlockEmployeeButton";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Employee } from "shared/models/employeeModel";
const { Panel } = Collapse;

function EmployeeDetailPage() {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [employee, setEmployee] = useState<Employee>();
    const [employeeIsLocked, setEmployeeIsLocked] = useState<boolean>(false);

    const employeeId = params.id;
    const { token } = theme.useToken();
    const userCanEditEmployee = usePermission(PermissionList.EmployeeEdit);
    const userCanCreateDocument = usePermission(PermissionList.EmployeeCreateDocument);
    const userCanEditDocument = usePermission(PermissionList.EmployeeEditDocument);
    const userCanDeleteDocument = usePermission(PermissionList.EmployeeDeleteDocument);
    const userCanCreateCertification = usePermission(PermissionList.EmployeeCreateCertification);
    const userCanEditCertification = usePermission(PermissionList.EmployeeEditCertification);
    const userCanDeleteCertification = usePermission(PermissionList.EmployeeDeleteCertification);
    const userCanVerifyEmployee = usePermission(PermissionList.EmployeeVerify);

    useEffect(() => {
        const getEmployeeInfo = async () => {
            if (!employeeId) {
                return;
            }

            try {
                setLoading(true);
                const res = await getEmployeeById(employeeId);
                setEmployee(res.result);
                setEmployeeIsLocked(res.result?.lockReasonId !== null);
            } catch (error: any) {
                message.error('Error obteniendo información del colaborador.');
            } finally {
                setLoading(false);
            }
        };
        getEmployeeInfo();
    }, [employeeId]);

    if (employee === undefined || loading) {
        return (<LoadingSpinner />);
    }

    return (
        <>
            <h1>Detalle colaborador</h1>

            {employeeIsLocked &&
                <Alert
                    showIcon
                    message="El colaborador está bloqueado temporalmente por razones de seguridad y para garantizar un entorno de trabajo seguro para todos."
                    description={

                        <div>
                            {employee.lockComment &&
                                <div dangerouslySetInnerHTML={{ __html: `<strong>Motivo</strong>: ${employee.lockComment}` }}>
                                </div>
                            }
                            {userCanVerifyEmployee &&
                                <Space direction="horizontal" style={{ marginTop: 20 }}>
                                    <LockEmployeeButton text="Actualizar motivo de bloqueo" employee={employee} onLocked={(lockReason?: string) => setEmployeeIsLocked(true)} />
                                    <UnlockEmployeeButton employee={employee} onUnlocked={() => setEmployeeIsLocked(false)}></UnlockEmployeeButton>
                                </Space>
                            }
                        </div>
                    }
                    type="warning"
                />
            }
            {!employeeIsLocked && userCanVerifyEmployee &&
                <div>
                    <LockEmployeeButton employee={employee} onLocked={(lockReason?: string) => setEmployeeIsLocked(true)} />
                </div>
            }

            <Collapse
                bordered={false}
                defaultActiveKey={['personal']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                style={{ background: token.colorBgContainer, marginTop: 20 }}
            >
                <Panel header="Información personal" key="personal">
                    <EmployeePersonalForm employee={employee} canEdit={userCanEditEmployee}></EmployeePersonalForm>
                </Panel>

                <Panel header="Documentos" key="documents">

                    <DocumentList
                        employeeId={employee.id}
                        documents={employee.documents}
                        canCreate={userCanCreateDocument}
                        canEdit={userCanEditDocument}
                        canDelete={userCanDeleteDocument} />

                </Panel>

                <Panel header="Certificaciones" key="certifications">
                    <CertificationList
                        employeeId={employee.id}
                        certifications={employee.certifications}
                        canCreate={userCanCreateCertification}
                        canEdit={userCanEditCertification}
                        canDelete={userCanDeleteCertification} />
                </Panel>
            </Collapse>
        </>

    );
}

export default EmployeeDetailPage;
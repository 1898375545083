import { Alert, Button, Form, Input, InputNumber, Space, Typography, message } from 'antd';
import { createOrderQuote } from 'api/coreapi/orders';
import { getEmployees, getSupplierContacts } from 'api/coreapi/suppliers';
import ErrorMessage from 'components/errorMessage';
import { SupplierTypes } from 'constants/suppliers';
import ContactSelector from 'features/contacts/contactSelector';
import EmployeeSelector from 'features/employees/employeeSelector';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import { Contact } from 'shared/models/contactModel';
import { Employee } from 'shared/models/employeeModel';
import { OrderQuote } from 'shared/models/orderQuoteModel';
import { OrderTenderRequest } from 'shared/models/orderTenderRequestModel';
import { formatMoney } from 'utils/moneyUtils';

const { TextArea } = Input;

interface OrderQuoteFormProps {
    orderTenderRequest: OrderTenderRequest
    onQuoted?: (quote: OrderQuote) => void,
}

const OrderQuoteForm = (props: OrderQuoteFormProps) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [isNew, setIsNew] = useState(props.orderTenderRequest.orderQuote?.id === undefined);
    const [supplierEmployees, setSupplierEmployees] = useState<Employee[]>();
    const [supplierContacts, setSupplierContacts] = useState<Contact[]>();
    const [total, setTotal] = useState<number>(0);
    const [cost, setCost] = useState<number>(props.orderTenderRequest.orderQuote?.cost ?? 0);
    const [vat, setVat] = useState<number>(props.orderTenderRequest.orderQuote?.supplierVat ?? 0);
    let selectedEmployeeIds: string[] = [];
    let selectedContactIds: string[] = [];

    const initialValues = {
        totalCost: props.orderTenderRequest.orderQuote?.cost,
        totalVat: props.orderTenderRequest.orderQuote?.supplierVat,
        comments: props.orderTenderRequest.orderQuote?.comments,
    }

    useEffect(() => {
        const getSupplierEmployees = async () => {
            try {
                const res = await getEmployees(props.orderTenderRequest.supplierId);
                setSupplierEmployees(res.result);
            } catch (error: any) {
            }
        };

        const getContacts = async () => {
            try {
                const res = await getSupplierContacts(props.orderTenderRequest.supplierId);
                setSupplierContacts(res.result);
            } catch (error: any) {
            }
        };

        getSupplierEmployees();
        getContacts();
    }, [props.orderTenderRequest.supplierId]);

    useEffect(() => {
        calculateTotal();
    }, [cost, vat]);

    const onFinish = async (values: any) => {
        try {
            if (selectedEmployeeIds.length <= 0) {
                message.error('Debe seleccionar los colaboradores que prestarían el servicio.');
                return;
            }

            if (selectedContactIds.length <= 0) {
                message.error('Debe seleccionar los contactos para este servicio.');
                return;
            }

            setSubmitting(true);
            var result = await createOrderQuote({
                orderTenderRequestId: props.orderTenderRequest.id,
                cost: values.totalCost,
                supplierVat: values.totalVat ?? 0,
                comments: values.comments,
                employeeIds: selectedEmployeeIds,
                contactIds: selectedContactIds,
            });

            if (result.errors) {
                message.error(result.errors);
            }

            message.success('Cotización guardada correctamente.')
            setIsNew(false);

            if (props.onQuoted && result.result) {
                props.onQuoted(result.result);
            }

        } catch (error: any) {
            if (error.errors) {
                message.error(ErrorMessage(error.errors));
            }
            else {
                message.error('Ha ocurrido un error guardando la cotización.');
            }
        }
        finally {
            setSubmitting(false);
        }
    };

    const calculateTotal = () => {
        setTotal(Math.ceil((cost ?? 0) + (vat ?? 0)));
    }

    const onCostChange = (value: any) => {
        setCost(value);
        calculateTotal();
    };

    const onVatChange = (value: any) => {
        setVat(value);
        calculateTotal();
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
            disabled={!isNew}>
            <Form.Item>
                <EmployeeSelector
                    employees={supplierEmployees ?? []}
                    onChange={(employees: string[]) => selectedEmployeeIds = employees}
                    canEdit={true}
                    label="Identifica las personas que prestarían el servicio"
                    empty={<Alert
                        message="¡Atención proveedor!"
                        description={
                            <div>
                                Aún no tienes colaboradores registrados en la aplicación. Por favor regístralos <Link to={paths.EMPLOYEES}>aquí</Link>.<br />
                                <br />
                                <br />
                                ¡Gracias por tu colaboración!
                            </div>
                        }
                        type="warning"
                    />}></EmployeeSelector>
            </Form.Item>
            <Form.Item>
                <ContactSelector
                    contacts={supplierContacts ?? []}
                    onChange={(contacts: string[]) => selectedContactIds = contacts}
                    canEdit={true}
                    label="Personas de contacto para este servicio"
                    empty={<Alert
                        message="¡Atención!"
                        description={
                            <div>
                                Para seleccionar los contactos que prestarán apoyo en el servicio, por favor registralos <Link to={paths.CONTACTS}>aquí</Link>.<br />
                                Ingresa los detalles de los contactos para avanzar con la asignación del servicio.
                                <br />
                                <br />
                                ¡Gracias por tu colaboración!
                            </div>
                        }
                        type="warning"
                    />}></ContactSelector>
            </Form.Item>
            <Form.Item
                name="totalCost"
                label="Costo del servicio"
                rules={[{ required: true, message: 'Por favor ingrese el valor total' }]}
            >
                <InputNumber controls={false} width={550} decimalSeparator='.' precision={2}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    style={{ width: 200 }}
                    onBlur={calculateTotal}
                    onChange={onCostChange}
                //parser={(value?:string) => value?.replace(/\$\s?|(,*)/g, '')} 
                />
            </Form.Item>
            <Form.Item
                name="totalVat"
                label="IVA"
                extra={props.orderTenderRequest.supplierTypeId === SupplierTypes.Crew ? "Únicamente si eres responsable de IVA" : ""}
                rules={[{ required: props.orderTenderRequest.supplierTypeId === SupplierTypes.Company, message: 'Por favor ingrese el valor de IVA' }]}
            >
                <InputNumber controls={false} width={550} decimalSeparator='.' precision={2}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    style={{ width: 200 }}
                    onBlur={calculateTotal}
                    onChange={onVatChange}
                />
            </Form.Item>
            <Form.Item label="Total">
                {formatMoney(total.toString())}
            </Form.Item>
            <Form.Item name="comments" label="Detalles">
                <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
                <Space direction='vertical'>
                    <Typography.Text type="secondary">Tenga presente que la cotización no se puede modificar una vez enviada.</Typography.Text>
                    <Button type="primary" htmlType="submit" loading={submitting}>Cotizar</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default OrderQuoteForm;
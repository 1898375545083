import { Space, Typography } from "antd";
import { getCities } from "api/coreapi/location";
import { useState } from "react";
import { City } from "shared/models/cityModel";
import DebounceSelect from "./debounceSelect";
const { Text } = Typography;

export interface CityValue {
  label: string;
  value: string;
}

interface CitiesAutoCompleteSelectProps {
  value?: CityValue | CityValue[];
  onChange?: (value?: CityValue | CityValue[]) => void;
  max?: number
}

export function ConvertToCityValue(city: City): CityValue{
  return {
    label: `${city.displayName} (${city.stateName})`,
    value: city.id,
  }
}

async function fetchCityList(name: string): Promise<CityValue[]> {
  var data = await getCities(name);

  var result = data.result ?? [];

  return result.map(
    (city: City) => ({
      label: `${city.displayName} (${city.stateName})`,
      value: city.id,
    }));
}

const CitiesAutoCompleteSelect: React.FC<CitiesAutoCompleteSelectProps> = (props) => {
  const {
    value,
    onChange,
    max //Max number of selection allowed
  } = props;

  const [enableSelection, setEnableSelection] = useState(true);
  const [maxReached, setMaxReached] = useState(false);
  const [maxSelectionTextType, setMaxSelectionTextType] = useState<'secondary' | 'danger'>('secondary');


  const triggerChange = (changedValue?: CityValue | CityValue[]) => {
    onChange?.(changedValue);
  };

  const onSelectedItemChange = (newType: CityValue | CityValue[]) => {
    if (isCityValueArray(newType)) {
      let hasReachedMaxSelection = max !== undefined && newType.length >= max;
      setMaxReached(hasReachedMaxSelection);
      setEnableSelection(!hasReachedMaxSelection);
    }
    triggerChange(newType);
  };

  const onFocus = () =>{
    setEnableSelection(!maxReached);
    setMaxSelectionTextType(enableSelection ? 'secondary': 'danger');
  }

  const onBlur = () =>{
    setEnableSelection(false);
    setMaxSelectionTextType('secondary');
  }

  function isCityValueArray(arg: any): arg is CityValue[] {
    return arg.length !== undefined;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
    <DebounceSelect
      open={enableSelection}
      mode='multiple'
      value={value}
      placeholder="Seleccione ciudades"
      fetchOptions={fetchCityList}
      onChange={(newValue) => {
        onSelectedItemChange(newValue);
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      style={{ width: '100%' }}
    />
    {maxReached && <Text type={maxSelectionTextType} style={{fontSize: 'small'}}>{`Máximo ${max} opciones permitidas.`}</Text>} 
    </Space>
    
  );
}

export default CitiesAutoCompleteSelect;
import { Alert, Card, Col, Row, message } from "antd";
import { getServiceTypes } from "api/coreapi/services";
import { getSupplierServiceQuotes } from "api/coreapi/suppliers";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import ServiceQuoteForm from "features/serviceQuotes/serviceQuoteForm";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { ServiceQuote } from "shared/models/serviceQuoteModel";
import { ServiceType } from "shared/models/serviceTypeModel";

const ServiceQuoteListPage = () => {

    const [areActiveServicesReady, setAreActiveServicesReady] = useState(false);
    const [areServiceQuotesReady, setAreServiceQuotesReady] = useState(false);

    const [serviceTypes, setServiceTypes] = useState<ServiceType[]>([]);
    const currentUser = useAppSelector(state => state.currentUser);
    const [serviceQuotes, setServiceQuotes] = useState<ServiceQuote[]>([]);

    useEffect(() => {
        const getActiveServiceTypes = async () => {
            try {
                const res = await getServiceTypes();

                if (res?.result && res.result.length > 0) {
                    setServiceTypes(res.result);
                    setAreActiveServicesReady(true);
                }

            } catch (error: any) {
                message.error('Error obteniendo la lista de servicios.');
            } finally {

            }
        };

        const getServiceQuotes = async () => {

            if (!currentUser?.supplier?.id) {
                setAreServiceQuotesReady(true);
                return;
            }

            try {

                const res = await getSupplierServiceQuotes({ supplierId: currentUser.supplier.id, includeDeleted: true });

                if (res?.result) {
                    setServiceQuotes(res.result);
                    setAreServiceQuotesReady(true);
                }

            } catch (error: any) {
                message.error('Error obteniendo la lista de cotizaciones vigentes del proveedor.');
            } finally {

            }
        }

        getActiveServiceTypes();
        getServiceQuotes();

    }, []);

    const handleFormSubmit = (values: any) => {

        // Aquí puedes enviar los datos al API
    };


    if (!areActiveServicesReady || !areServiceQuotesReady)
        return <LoadingSpinner></LoadingSpinner>;

    // return (
    //     <div>
    //         {serviceTypes?.map(serviceType => (
    //             <div key={serviceType.id}>
    //                 <h2>{serviceType.displayName}</h2>
    //                 <ServiceQuoteForm serviceType={serviceType} onSubmit={handleFormSubmit} formData={formData} setFormData={setFormData} />
    //             </div>
    //         ))}
    //         <Button type="primary" onClick={() => handleFormSubmit(formData)}>
    //             Guardar
    //         </Button>
    //     </div>
    // );
    return (
        <Card>
            <PageTitle>Tarifas de servicios</PageTitle>

            {!currentUser.supplier?.id &&
                <Row justify={"center"}>
                    <Alert type="error"
                        message="Atención"
                        description="Para acceder a esta funcionalidad, debes crear el perfil en el menú 'Mi empresa'."
                        showIcon />
                </Row>
            }
            <Row>
                {currentUser.supplier &&
                    // <Space direction="vertical" align="center">
                    serviceTypes?.map((serviceType: ServiceType) => {
                        let serviceQuote = serviceQuotes.find(sq => sq.serviceTypeId == serviceType.id);
                        return (
                            <Col key={`serviceQuoteCol${serviceType.id}`} xs={{ span: 24 }} xl={{ span: 22, offset: 1 }} xxl={{ span: 20, offset: 2 }}>
                                <ServiceQuoteForm
                                    serviceType={serviceType}
                                    onSubmit={handleFormSubmit}
                                    serviceQuote={serviceQuote} />
                            </Col>
                        )
                    }
                    )}

                {/* </Space> */}

            </Row>
        </Card >
    );
}

export default ServiceQuoteListPage;
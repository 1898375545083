import { Card, Col, Row, message, theme } from "antd";
import { getCustomerById, updateCustomerStatus } from "api/coreapi/customers";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import { CustomerStatusDropdown } from "components/customerStatusDropdown";
import AddressList from "features/addresses/addressList";
import CertificationList from "features/certifications/certificationList";
import ContactList from "features/contacts/contactList";
import DocumentList from "features/documents/documentList";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Customer } from "shared/models/customerModel";
import { EnumTypeModel } from "shared/models/enumTypeModel";
import { formatDate } from "utils/dateUtils";

function CustomerDetailPage() {
    const { customerId } = useParams();
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState<Customer>();    
    
    const {
        token: { colorPrimaryBg },
    } = theme.useToken();
    const headerStyle = { background: colorPrimaryBg };

    useEffect(() => {
        const getInfo = async () => {
            if (!customerId) {
                return;
            }

            try {
                setLoading(true);
                const res = await getCustomerById(customerId);
                setCustomer(res.result);
            } catch (error: any) {
                message.error('Error obteniendo información del cliente.');
            } finally {
                setLoading(false);
            }
        };
        getInfo();
    }, [customerId]);

    const onStatusSelected = async (status: EnumTypeModel) => {
        try {
            const res = await updateCustomerStatus({ customerId: customer!.id, customerStatusId: status.id });
            return res?.result ?? false;
        } catch (error: any) {
            message.error('Error actualizando el estado del cliente.');
        }

        return true;
    }

    if (customer === undefined || loading) {
        return (<LoadingSpinner />);
    }

    return (
        <Card>
            <PageTitle>Cliente: {customer.name}</PageTitle>

            <Row gutter={[50,50]}>
                <Col sm={24} lg={12}>
                    <Card title="Cliente" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <Row justify='end'>                            
                            <Col>
                                Estado
                                <CustomerStatusDropdown currentStatusId={customer.customerStatusId} onStatusSelected={onStatusSelected}></CustomerStatusDropdown>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col span={5}><b>Nombre</b></Col>
                            <Col>{customer.name}</Col>

                        </Row>
                        <Row>
                            <Col span={5}>
                            <b>{customer.identificationNumberTypeName}</b>
                                
                            </Col>
                            <Col>
                                {customer.identificationNumber}
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col span={5}><b>Fecha de registro</b></Col>
                            <Col>{formatDate(customer.createdOn, "DateAndTime")}</Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Direcciones" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <AddressList addresses={customer.addresses ?? []} customerId={customerId} tableSize="small" />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Contactos" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <ContactList contacts={customer.contacts ?? []} customerId={customerId} tableSize="small" />
                    </Card>
                </Col>

                <Col span={12}>

                    <Card title="Documentos" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <DocumentList documents={customer.documents ?? []} customerId={customerId} tableSize="small" />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Certificaciones" type="inner" bordered={true} size="small" headStyle={headerStyle}>
                        <CertificationList certifications={customer.certifications ?? []} customerId={customerId} tableSize="small" />
                    </Card>
                </Col>
                
                
            </Row>


        </Card>

    );
}

export default CustomerDetailPage;
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/es';
import { start } from 'repl';
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.locale('es');

const dateAndTimeFormat = new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
});

const dateOnlyFormat = new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
});

const timeOnlyFormat = new Intl.DateTimeFormat('default', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
});

type DateFormat = 'DateAndTime' | 'DateOnly' | 'TimeOnly';

export function formatDate(date?: Date, format: DateFormat = "DateAndTime") {

    if (!date) {
        return '';
    }

    let dateToFormat = date;
    if (date instanceof String || typeof (date) === 'string') {
        let isUTC = date.includes('Z');
        dateToFormat = isUTC ? new Date(date) : new Date(new Date(date).getTime() + new Date().getTimezoneOffset() * 60000);
    }

    try {

        if (format === 'DateAndTime') {
            return dateAndTimeFormat.format(dateToFormat)
        }

        if (format === 'DateOnly') {
            return dateOnlyFormat.format(dateToFormat)
        }

        if (format === 'TimeOnly') {
            return timeOnlyFormat.format(dateToFormat)
        }
    } catch {
        return '';
    }
}

export function getDuration(startDate?: Date, endDate?: Date) {
    if (startDate && endDate) {
        const start = dayjs(startDate);
        const end = dayjs(endDate);
        const duration = dayjs.duration(end.diff(start));
        let format = duration.months() > 0 ? 'M[m] ' : '';
        format += duration.days() > 0 ? 'D[d] ' : '';
        format += duration.hours() > 0 ? 'H[h] ' : '';
        format += duration.minutes() > 0 ? 'm[m] ' : '';
        const durationFormatted = duration.format(format);

        return durationFormatted;
    }

    return '';
};

export function getDurationAsHours(startDate?: Date, endDate?: Date): number | undefined {
    if (!startDate || !endDate)
        return;

    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const duration = dayjs.duration(end.diff(start));

    return duration.asHours();
};
import { Select } from "antd";
import { getDocumentTypes } from "api/coreapi/documents";
import { useEffect, useState } from "react";
import { EnumTypeModel } from "shared/models/enumTypeModel";

interface DocumentTypeSelectProps {
    value?: number;
    onChange?: (value?: number) => void;
    isForCustomers?: boolean,
    isForEmployees?: boolean,
    isForSuppliers?: boolean,
    includeDeleted?: boolean
}

const DocumentTypeSelect: React.FC<DocumentTypeSelectProps> = (props) => {
    const {
        value,
        onChange        
    } = props;
    
    var [types, setTypes] = useState<EnumTypeModel[]>();
    const triggerChange = (changedValue?: number) => {
        onChange?.(changedValue);
      };

      const onSelectedItemChange = (newType: number) => {
        triggerChange(newType);
      };

    useEffect(() => {
        const getInfo = async () => {
            try {
                const res = await getDocumentTypes({
                    isForCustomers:props.isForCustomers,
                    isForEmployees: props.isForEmployees,
                    isForSuppliers: props.isForSuppliers,
                    includeDeleted: props.includeDeleted
                });
                setTypes(res.result);
            } catch (error: any) {
            }
        };
        getInfo();
    }, [props]);


    return (
        <Select
            style={{ width: 220 }}
            defaultValue={value}
            onChange={onSelectedItemChange}
            options={types?.map((item) => ({
                value: item.id,
                label: item.displayName ?? item.name,
            }))}
        />

    );
}

export default DocumentTypeSelect;
import { Alert, Col, Empty, Row, Space, Tabs, TabsProps, message } from "antd";
import { getOrderByNumber } from "api/coreapi/orders";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import ErrorMessage from "components/errorMessage";
import { CustomerStatuses } from "constants/customers";
import { OrderStatuses } from "constants/orders";
import { OrderPaymentStatuses, PaymentTypes } from "constants/payments";
import { PermissionList } from "constants/permissions";
import { SupplierStatuses } from "constants/suppliers";
import usePermission from "features/auth/usePermission";
import CancelOrderButton from "features/orders/cancelOrderButton";
import OrderCustomerDetail from "features/orders/orderCustomerDetail";
import { OrderForm } from "features/orders/orderForm";
import OrderQuoteDetail from "features/orders/orderQuoteDetail";
import OrderQuotesReview from "features/orders/orderQuotesReview";
import OrderSupplierDetail from "features/orders/orderSupplierDetail";
import OrderPaymentButton from "features/payments/orderPaymentButton";
import PaymentList from "features/payments/paymentList";
import { useAppSelector } from "hooks/useAppSelector";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Order } from "shared/models/orderModel";
import dayjs from 'dayjs';

function OrderDetailPage() {

    const { orderNumber } = useParams();
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState<Order>();
    const userCanEditEmployees = usePermission([PermissionList.EmployeeList]);
    const userCanCreateOrder = usePermission([PermissionList.OrderCreate]);
    const userCanAcceptOrder = usePermission([PermissionList.OrderQuoteAccept]);
    const userCanCancelOrder = usePermission([PermissionList.OrderCancel]);
    const currentUser = useAppSelector(state => state.currentUser);
    const isCustomerUser = currentUser?.customer?.customerStatusId === CustomerStatuses.Active;
    const isSupplierUser = currentUser?.supplier?.supplierStatusId === SupplierStatuses.Active;
    const isInternalUser = usePermission([PermissionList.OrderInternalView]);


    const getInfo = useCallback(async () => {
        if (!orderNumber) {
            return;
        }

        try {
            setLoading(true);
            const res = await getOrderByNumber(orderNumber);
            setOrder(res.result);
        } catch (error: any) {
            if (error.errors) {
                message.error(ErrorMessage(error.errors));
            } else {
                message.error('Error obteniendo información de la orden de servicio.');
            }

        } finally {
            setLoading(false);
        }
    }, [orderNumber]);

    useEffect(() => {
        getInfo();
    }, [orderNumber, getInfo]);

    if (loading) {
        return (<LoadingSpinner />);
    }

    let items: TabsProps['items'] = [
        {
            key: 'service',
            label: `Servicio`,
            children: <Row justify={"center"}>
                <Col md={{ span: 16 }} span={24}>
                    <OrderForm order={order} />
                </Col>
            </Row>,
        },
    ];

    if (order?.orderStatusId !== OrderStatuses.Canceled) {
        if (order?.supplier &&
            (
                isInternalUser ||
                isSupplierUser ||
                (isCustomerUser && order.isSupplierInfoVisible)
            )) {
            items = [...items, {
                key: 'supplier',
                label: `Proveedor`,
                children: <Row justify={"center"}>
                    <Col md={{ span: 16 }} span={24}>
                        <OrderSupplierDetail
                            orderSupplier={order.supplier}
                            canEdit={userCanEditEmployees}
                            orderNumber={order.orderNumber} />
                    </Col>
                </Row>,
            }]
        }

        if (order &&
            (isInternalUser ||
                isCustomerUser ||
                (order.supplier && isSupplierUser)
            )
        ) {
            items = [...items, {
                key: 'customer',
                label: `Cliente`,
                children: <Row justify={"center"}>
                    <Col md={{ span: 16 }} span={24}>
                        <OrderCustomerDetail
                            canEdit={userCanCreateOrder}
                            orderNumber={order!.orderNumber} />
                    </Col>
                </Row>,
            }]
        }

        if (order?.acceptedOrderQuoteId ||
            (order?.orderStatusId === OrderStatuses.PendingSupplierSelection && userCanAcceptOrder)) {
            items = [...items, {
                key: 'quote',
                label: `Facturación`,
                children: <Row justify={"center"}>
                    <Col md={{ span: 16 }} span={24}>

                        {order.acceptedOrderQuoteId &&
                            <Space direction="vertical">
                                <OrderQuoteDetail
                                    orderQuoteId={order.acceptedOrderQuoteId}
                                />

                                {order.payments && order.payments.length > 0 &&
                                    <PaymentList payments={order.payments}></PaymentList>}


                            </Space>

                        }

                        {!order.acceptedOrderQuoteId &&
                            order?.orderStatusId === OrderStatuses.PendingSupplierSelection
                            && userCanAcceptOrder &&
                            <OrderQuotesReview
                                orderNumber={order.orderNumber.toString()}
                                onQuoteAccepted={() => window.location.reload()} />
                        }
                    </Col>
                </Row>,
            }]
        }
    }

    if (loading) {
        return <LoadingSpinner></LoadingSpinner>
    }

    if (!order) {
        return <Empty />
    }

    return (
        <>
            <PageTitle>
                Orden # {orderNumber} - {order?.orderStatusName}
                <Row justify={"center"}>
                    {userCanCancelOrder && order.orderStatusId !== OrderStatuses.Canceled &&
                        <CancelOrderButton orderNumber={order.orderNumber} onCanceled={async () => await getInfo()}></CancelOrderButton>
                    }
                </Row>

            </PageTitle>
            {order.orderStatusId === OrderStatuses.ConfirmingAvailability &&

                <Alert type="info"
                    style={{ marginBottom: 20 }}
                    showIcon
                    description={`Tiempo estimado para confirmación de disponibilidad de proveedores: ${dayjs(order.quoteDeadline).format('MMMM D, h:mm A')}`} />
            }

            {
                order.paymentTypeId === PaymentTypes.Transfer
                && order.orderPaymentStatusId === OrderPaymentStatuses.Pending
                && order.orderStatusId === OrderStatuses.PendingPaymentConfirmation
                && isCustomerUser
                &&
                <Alert type="warning" message={
                    <div>
                        Para dar inicio al proceso y acceder a los detalles del proveedor, es necesario completar el pago del servicio. Esto garantizará una experiencia continua y sin contratiempos.



                        <OrderPaymentButton orderNumber={order.orderNumber}></OrderPaymentButton>
                    </div>
                } showIcon />

            }


            <Tabs
                defaultActiveKey="service"
                items={items}
                type="card">
            </Tabs>
        </>
    );

}

export default OrderDetailPage;
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { getCustomerStatuses } from "api/coreapi/customers";
import { useEffect, useState } from "react";
import { EnumTypeModel } from "shared/models/enumTypeModel";

interface CustomerStatusDropdownProps {
    currentStatusId?: string | number;
    readonly?: boolean,
    placement?: "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight" | "top" | "bottom" | undefined;
    onStatusSelected?: (status: EnumTypeModel) => Promise<boolean>;
}

export function CustomerStatusDropdown(props: CustomerStatusDropdownProps) {
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [statuses, setStatuses] = useState<EnumTypeModel[]>();
    const [selectedStatus, setSelectedStatus] = useState<EnumTypeModel>();

    useEffect(() => {
        const getInfo = async () => {
            try {
                setLoading(true);
                const res = await getCustomerStatuses();

                if (res.result) {
                    setStatuses(res.result);

                    if (props.currentStatusId) {
                        const selectedItem = res.result!.find((item) => {
                            // eslint-disable-next-line
                            return item.id == props.currentStatusId?.toString();
                        });
                
                        setSelectedStatus(selectedItem);
                    }
                }
            } catch (error: any) {
            } finally {
                setLoading(false);
            }
        };
        getInfo();
    }, [props]);

    const handleMenuClick: MenuProps['onClick'] = async (e) => {
        const selectedItem = statuses!.find((item) => {
            return item?.id.toString() === e.key;
        });

        if (!selectedItem) {            
            return false;
        }

        if (!props.onStatusSelected) {
            return true;
        }

        setUpdating(true);
        const successfullySelected = await props.onStatusSelected(selectedItem);
        if(successfullySelected){
            setSelectedStatus(selectedItem);
        }
        
        setUpdating(false);
        return successfullySelected;
    };

    if (loading || statuses === undefined) {
        return <></>
    }

    return (

        <Dropdown.Button
            size="small"
            icon={<DownOutlined />}
            loading={updating}
            disabled={updating || props.readonly}
            menu={{ items: statuses.map((status: EnumTypeModel) => ({ label: status.name, key: status.id })), onClick: handleMenuClick }}
            placement={props.placement}
        >
            {(selectedStatus ? selectedStatus.displayName ?? selectedStatus.name : 'Seleccionar estado...')}
        </Dropdown.Button>
    );
}
import { Alert, message } from "antd";
import { getOrderByNumber } from "api/coreapi/orders";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import ErrorMessage from "components/errorMessage";
import OrderDetail from "features/orders/orderDetail";
import OrderQuotesReview from "features/orders/orderQuotesReview";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PATHS from 'routes/paths';
import { Order } from "shared/models/orderModel";

const OrderQuotesReviewPage = () => {

    const { orderNumber } = useParams();
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState<Order>();
    const navigate = useNavigate();


    useEffect(() => {

        const getOrder = async () => {
            if (!orderNumber) {
                return;
            }

            try {
                setLoading(true);
                const res = await getOrderByNumber(orderNumber);
                setOrder(res.result);
            } catch (error: any) {
                if (error.errors) {
                    message.error(ErrorMessage(error.errors));
                } else {
                    message.error('Error obteniendo información de la orden de servicio.');
                }

            } finally {
                setLoading(false);
            }
        };

        getOrder();
    }, [orderNumber]);

    if (loading) {
        return <LoadingSpinner></LoadingSpinner>
    }

    if (!orderNumber) {
        return (
            <>
                URL inválida
            </>
        );
    }

    if (!order) {
        return (
            <Alert message="No se encuentra la orden de servicio" type="error" />
        );
    }


    return (
        <>
            <PageTitle>Selección de cotización</PageTitle>
            <OrderDetail order={order}></OrderDetail>
            <OrderQuotesReview orderNumber={orderNumber}
                onQuoteAccepted={() => navigate(PATHS.ORDER_DETAIL.replace(':orderNumber', orderNumber!))}></OrderQuotesReview>
        </>

    );
};

export default OrderQuotesReviewPage;
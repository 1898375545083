import { LockOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';

const ForbiddenPage = () => {
  return (
    <Result
      icon={<LockOutlined />}
      title="Acceso Denegado"
      subTitle="No tienes permiso para acceder a esta sección."
      extra={[
        <Button type="primary" key="back" onClick={() => window.history.go(-2)}>
          Volver
        </Button>
      ]}
    />
  );
};

export default ForbiddenPage;
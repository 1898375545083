import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, message, Row, Space, Typography, Upload } from "antd";
import { createCertification, editCertification } from "api/coreapi/certifications";
import { createContact, editContact } from "api/coreapi/contacts";
import CertificationTypeSelect from "components/certificationTypeSelect";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { Certification } from "shared/models/certificationModel";
import { Contact } from "shared/models/contactModel";
import { CreateCertificationPayload } from "shared/models/payloads/createCertificationPayload";
import { CreateContactPayload } from "shared/models/payloads/createContactPayload";
import { EditCertificationPayload } from "shared/models/payloads/editCertificationPayload";
import { EditContactPayload } from "shared/models/payloads/editContactPayload";

interface ContactFormProps {
    supplierId?: string,
    customerId?: string,
    contact?: Contact,
    onCreate?: (certification?: Contact) => void;
    onEdit?: (certification?: Contact) => void;
    onCancel?: () => void;
}

function ContactForm(props: ContactFormProps) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    let initialFormValue: any = {};

    if (props.contact) {
        initialFormValue = {
            ...props.contact
        };
    }

    useEffect(() => form.resetFields(), [form, props.contact]);

    const onFinish = async (values: any) => {

        try {
            setLoading(true);
            let isNew = props.contact?.id === undefined;
            let response: EslavonApiResponse<Contact>;
            if (isNew) {
                //Create
                let payload = values as CreateContactPayload;
                response = await createContact({
                    ...payload,
                    customerId: props.customerId,
                    supplierId: props.supplierId
                });
            } else {
                //Edit existing record
                let payload = values as EditContactPayload;
                response = await editContact({
                    ...payload,
                    customerId: props.customerId,
                    supplierId: props.supplierId
                });
            }

            message.success('Contacto guardado.');
            form.resetFields();

            if (isNew && props.onCreate) {
                props.onCreate(response.result);
            } else if (!isNew && props.onEdit) {
                props.onEdit(response.result);
            }
        } catch (error: any) {
            if (error.errors) {
                message.error((error.errors));
            }
            else {
                message.error('Ha ocurrido un error.');
            }

        } finally {
            setLoading(false);
        }
    };

    if (!props.customerId && !props.supplierId) {
        return (
            <Typography.Text type="danger">Invalid configuration for CreateContactForm component. </Typography.Text>
        );
    }

    return (
        <Form
            form={form}
            name="contactForm"
            layout="vertical"
            initialValues={initialFormValue}
            onFinish={onFinish}>
            <Row gutter={24}>
                <Col lg={{ span: 8 }}>
                    <Form.Item hidden name="id">
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="firstName" label="Nombres" rules={[{ required: true }]}>
                        <Input maxLength={200}></Input>
                    </Form.Item>
                </Col>
                <Col lg={{ span: 8 }}>
                    <Form.Item name="lastName" label="Apellidos">
                        <Input maxLength={200}></Input>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col lg={{ span: 8 }}>
                    <Form.Item name="phoneNumber" label="Número celular" rules={[{ required: true }]}>
                        <Input type="number"></Input>
                    </Form.Item>
                </Col>
                <Col lg={{ span: 16 }}>
                    <Form.Item name="emailAddress" label="Correo electrónico" rules={[{ required: true }]}>
                        <Input type="email"></Input>
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Form.Item name="jobTitle" label="Cargo" rules={[{ required: true }]}>
                    <Input maxLength={200}></Input>
                </Form.Item>

            </Row>
            <Row justify={"end"}>
                <Form.Item>
                    <Space>
                        <Button type="default" onClick={() => {
                            form.resetFields();
                            if (props.onCancel) {
                                props.onCancel();
                            }
                        }}>
                            Cancelar
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Guardar
                        </Button>
                    </Space>
                </Form.Item>
            </Row>
        </Form>
    );
}

export default ContactForm;
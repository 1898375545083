import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import { getDownloadLink } from "api/coreapi/documents";
import { useState } from "react";

interface DocumentDownloadButtonProps extends ButtonProps {
    documentId: string
}

export function DocumentDownloadButton(props: DocumentDownloadButtonProps) {
    const [loading, setLoading] = useState(false);
    if (!props.documentId) {
        return <></>
    }

    async function downloadDocument() {
        setLoading(true);

        try {
            var documentUrl = await getDownloadLink(props.documentId);

            if (documentUrl.result && documentUrl.result.length > 0) {
                window.open(documentUrl.result);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Button type={props.type} children={props.children}
        onClick={async () => downloadDocument()}
        loading={loading}/>
    )
}
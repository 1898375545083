import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { ProfileDropdown } from "./profileDropdown";
import { Link } from "react-router-dom";

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <ProfileDropdown />
      </Col>

      <Col>
        <Row align="middle">
          <Col>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', position: 'relative' }}>
              <img src='/images/logoWhite2.png' alt="Eslavón" style={{ width: 148 }} />
            </Link>
          </Col>
          <Col>
            {/* <NotificationsDropdown /> */}
          </Col>

          <Col>
            {/* <HeaderSearch /> */}
          </Col>

          <Col>
            {/* <SettingsDropdown /> */}
          </Col>
        </Row>
      </Col>

      <Col style={{ zIndex: 999, display: 'flex' }}>
        {isSiderOpened
          ? <CloseOutlined onClick={toggleSider} style={{ color: "white", fontSize: 20 }} />
          : <MenuOutlined onClick={toggleSider} style={{ fontSize: 20, color: "white" }} />}
      </Col>
    </Row>
  );
};
import { Divider, Typography } from "antd";
import { useResponsive } from "hooks/useResponsive";
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { isTablet } = useResponsive();
  const auth = useAuth();
  const textStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    fontWeight: 400,
    fontSize: isTablet ? '1rem' : '0.875rem'
  }

  function logout() {
    try {
      auth.signoutRedirect({
        id_token_hint: auth.user?.id_token,
        post_logout_redirect_uri: window.location.origin + '/signout-callback-oidc',
        state: auth.user?.state
      }).then(() => {

      });

      auth.clearStaleState();
    } catch (error) {
      console.log(error);
    }

  }
  return (
    <div {...props}>
      <Typography.Text style={textStyle}>
        <Link to={`${process.env.REACT_APP_ESLAVON_IDENTITYSVC_URI!}/identity/account/manage`} target="_blank">Mi perfil</Link>
      </Typography.Text>
      <Divider style={{ margin: 0 }} />
      <Typography.Text style={textStyle}>
        <Link to="" onClick={() => logout()}>Cerrar sesión</Link>
      </Typography.Text>
    </div>
  );
};
import { ThemeConfig } from "antd/es/config-provider";

const fontFamily = 'Fira Sans';
const colorPrimary = '#1b549e';
const colorSuccess = '#8fbf58';
const colorInfo = '#54b9ad';

export const EslavonDefaultThemeConfig:ThemeConfig = {
    token: {
        fontFamily: fontFamily,
        colorPrimary: colorPrimary,
        colorSuccess: colorSuccess,
        colorInfo: colorInfo,
      },
      components: {
        Radio: {
          colorPrimary: '#00b96b',
        },
        Menu:{
          colorItemText: 'white',
          colorItemTextHover: colorInfo,
          colorItemBgHover:'transparent',
          colorItemTextSelected: colorInfo,          
          colorItemBgSelected: 'transparent',                  
        }
      },
}
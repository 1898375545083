import { Card, message } from "antd";
import { getSupplierDocuments } from "api/coreapi/suppliers";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import usePermission from "features/auth/usePermission";
import DocumentList from "features/documents/documentList";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { EslavonApiResponse } from "shared/models/apiResponses/eslavonResponse";
import { PermissionList } from "constants/permissions";
import { Document } from '../shared/models/documentModel';
import { getCustomerDocuments } from "api/coreapi/customers";

export interface DocumentListPageProps {
    OwnerType: 'customer' | 'supplier',
}

export default function DocumentListPage(props: DocumentListPageProps) {
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState<Document[]>([]);
    const currentUser = useAppSelector(state => state.currentUser);

    const userCanCreateDocument = usePermission([PermissionList.SupplierCreateDocument, PermissionList.CustomerCreateDocument]);
    const userCanEditDocument = usePermission([PermissionList.SupplierEditDocument, PermissionList.CustomerEditDocument]);
    const userCanDeleteDocument = usePermission([PermissionList.SupplierDeleteDocument, PermissionList.CustomerDeleteDocument]);

    useEffect(() => {
        const getInfo = async () => {

            try {
                setLoading(true);
                let response: EslavonApiResponse<Document[]> | null = null;

                if (props.OwnerType === 'supplier' && currentUser?.supplier) {
                    response = await getSupplierDocuments(currentUser.supplier.id);
                }

                if (props.OwnerType === 'customer' && currentUser?.customer) {
                    response = await getCustomerDocuments(currentUser.customer.id);
                }

                if (response?.result) {
                    setDocuments(response.result);
                }
            } catch (error: any) {
                message.error('Error obteniendo lista de documentos.');
            } finally {
                setLoading(false);
            }
        };
        getInfo();
    }, [props.OwnerType, currentUser]);


    if (loading) {
        return <LoadingSpinner></LoadingSpinner>
    }

    return (

        <Card>
            <PageTitle>Documentos</PageTitle>
            <DocumentList
                documents={documents}
                supplierId={props.OwnerType === 'supplier' ? currentUser?.supplier?.id : undefined}
                customerId={props.OwnerType === 'customer' ? currentUser?.customer?.id : undefined}
                canCreate={userCanCreateDocument}
                canEdit={userCanEditDocument}
                canDelete={userCanDeleteDocument}
            />
        </Card>
    );
}
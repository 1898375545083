import { Select } from "antd";
import { getTruckTypes } from "api/coreapi/common";
import { useEffect, useState } from "react";
import { EnumTypeModel } from "shared/models/enumTypeModel";

interface TruckTypeSelectProps {
    value?: number;
    onChange?: (value?: number) => void;
}

const TruckTypeSelect: React.FC<TruckTypeSelectProps> = (props) => {
    const {
        value,
        onChange        
    } = props;
    
    var [types, setTypes] = useState<EnumTypeModel[]>();
    const triggerChange = (changedValue?: number) => {
        onChange?.(changedValue);
      };

      const onSelectedItemChange = (newType: number) => {
        triggerChange(newType);
      };

    useEffect(() => {

        const getInfo = async () => {
            try {
                const res = await getTruckTypes();
                setTypes(res.result);
            } catch (error: any) {
            }
        };

        getInfo();
    }, []);


    return (
        <Select
            //style={{ width: 220 }}
            defaultValue={value}
            onChange={onSelectedItemChange}
            options={types?.map((item) => ({
                value: item.id,
                label: item.displayName ?? item.name,
            }))}
        />

    );
}

export default TruckTypeSelect;
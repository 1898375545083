import { StopOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Space, Typography, message } from 'antd';
import { getEmployeeLockReasons, updateEmployeeLock } from 'api/coreapi/employees';
import LoadingSpinner from 'components/common/loadingSpinner/loadingSpinner';
import { useEffect, useState } from 'react';
import { EmployeeLockReason } from 'shared/models/employeeLockReasonModel';
import { Employee } from 'shared/models/employeeModel';
import { UpdateEmployeeLockPayload } from 'shared/models/payloads/updateEmployeeLockPayload';

const { TextArea } = Input;

interface LockEmployeeButtonProps {
    employee: Employee;
    text?: string;
    onLocked?: (lockReason?: string) => void,
}

const LockEmployeeButton = (props: LockEmployeeButtonProps) => {

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lockReasons, setLockReasons] = useState<EmployeeLockReason[]>();

    useEffect(() => {
        const getInfo = async () => {
            try {
                setIsLoading(true);
                const res = await getEmployeeLockReasons();
                setLockReasons(res.result);
                setIsLoading(false);
            } catch (error: any) {
            }
        };
        getInfo();
    }, []);

    const handleOk = async () => {
        form.validateFields()
            .then((values: any) => {
                const cancel = async (payload: UpdateEmployeeLockPayload) => {
                    setSubmitting(true);
                    try {
                        const result = await updateEmployeeLock(payload);
                        if (!result?.result) {
                            message.error("Ocurrió un error bloqueando el colaborador.");
                            return;
                        }

                        if (props.onLocked) {
                            props.onLocked(payload.lockComment);
                        }

                        setIsModalOpen(false);
                    } catch (error) {
                        message.error('Error bloqueando el colaborador.');

                    } finally {
                        setSubmitting(false);
                    }
                };

                cancel({
                    id: props.employee.id,
                    lockReasonId: values.lockReasonId,
                    lockComment: values.lockComment
                });
            })
            .catch(() => {

            });
    };

    return (
        <>
            <Modal
                title="Bloquear colaborador"
                open={isModalOpen}
                okText="Bloquear"
                okButtonProps={{ icon: <StopOutlined></StopOutlined>, danger: true, loading: submitting }}
                onOk={handleOk}
                confirmLoading={submitting}
                onCancel={() => setIsModalOpen(false)}>
                {isLoading
                    ? <LoadingSpinner></LoadingSpinner>
                    : <Space direction='vertical'>
                        <Typography.Text>Seguro desea bloquear al colaborador {props.employee.firstName} {props.employee.lastName}?</Typography.Text>
                        <Typography.Text type='secondary'>Este colaborador no podrá ser usado por el proveedor hasta que se quite el bloqueo.</Typography.Text>
                        <Form layout="vertical" form={form} style={{ marginTop: 20 }}>
                            <Form.Item name="lockReasonId"
                                label="Motivo"
                                rules={[{ required: true, message: 'Por favor ingrese el motivo del bloqueo.' }]}>
                                <Select
                                    options={lockReasons?.map((item) => ({
                                        value: item.id,
                                        label: item.displayName ?? item.name,
                                    }))}
                                    onChange={(value: any) => { form.setFieldValue("lockComment", lockReasons?.find(item => item.id === value)?.defaultComment) }}
                                />
                            </Form.Item>
                            <Form.Item name="lockComment"
                                label="Detalles"
                                rules={[{ required: true, message: 'Por favor ingrese el detalle del bloqueo.' }]}>
                                <TextArea maxLength={2000} rows={4}></TextArea>
                            </Form.Item>
                        </Form>
                    </Space>
                }
            </Modal>
            <Button type="primary" icon={<StopOutlined />} danger
                onClick={() => setIsModalOpen(true)}
                loading={submitting}>
                {props.text ?? 'Bloquear colaborador'}
            </Button>
        </>
    );
};

export default LockEmployeeButton;
import { MoreOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Modal, Popover, Row, Space, Table, Typography } from "antd";
import Column from "antd/es/table/Column";
import { useState } from "react";
import { Role } from "shared/models/role";
import { User } from "shared/models/userModel";
import UserForm from "./userForm";

interface UserListProps {
    users: User[];
    supplierId?: string,
    customerId?: string,
    canCreate?: boolean,
    canEdit?: boolean,
    canDelete?: boolean,
    onDelete?: (userId: string) => void,
}

export function UserList(props: UserListProps){

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [users, setUsers] = useState<User[]>(props.users);
    const [selectedUser, setSelectedUser] = useState<User>();

    function onUserCreate(user?: User) {
        if (user) {
            setUsers([...users, user]);
        }

        setIsModalOpen(false);
    }

    function onUserEdit(user?: User) {
        if (!user) {
            setIsModalOpen(false);
            return;
        }

        const nextUsers = users.map(item => {
            if (item.id === user.id) {
                return {
                    ...user,
                    roles: item.roles
                };
            } else {
                return item;
            }
        });

        setUsers(nextUsers);
        setIsModalOpen(false);
    }

    function onUserCancel() {
        setIsModalOpen(false);
    }

    function editUser(user: User) {
        setSelectedUser(user);
        setIsModalOpen(true);
    }

    // function onUserDelete(user: User) {
    //     confirm({
    //         title: 'Seguro desea eliminar el usuario?',
    //         icon: <ExclamationCircleFilled />,
    //         content: <Space direction="vertical">
    //             {'Email: ' + user.email}                
    //         </Space>,
    //         okText: 'Si',
    //         okType: 'danger',
    //         cancelText: 'No',
    //         async onOk() {

    //             try {
    //                 const res = await deleteUser(certification.id);
    //                 if (res.result) {
    //                     setCertifications(certifications.filter(function (item: Certification) {
    //                         return item.id !== certification.id
    //                     }));
    //                 }

    //             } catch (error: any) {
    //                 message.error('Error eliminando el usuario.');
    //             }
    //         },
    //     });
    // }

    if (!props.customerId && !props.supplierId) {
        return (
            <Typography.Text type="danger">Invalid configuration for UserList component. </Typography.Text>
        );
    }

    return (
        <Row>
            <Col span={24}>
        <Space direction="vertical" style={{width: '100%'}}>
            <Row justify={"end"}>
                {props.canCreate &&
                    <Button type="dashed" onClick={() => {
                        setSelectedUser(undefined);
                        setIsModalOpen(true);}}
                        disabled={isModalOpen} size="small">
                        Nuevo usuario
                    </Button>}
            </Row>
            <Modal footer={null}
                title="Nuevo usuario"
                closable={false}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}>
                <UserForm
                    supplierId={props.supplierId}
                    customerId={props.customerId}
                    initialValue={selectedUser}
                    onCreate={onUserCreate}
                    onCancel={onUserCancel}
                    onEdit={onUserEdit}></UserForm>
            </Modal>

            <Table dataSource={users} rowKey="id">
                <Column
                    key="action"
                    width={50}
                    render={(_: any, record: User) => (
                        <Popover
                            placement="right"
                            trigger="click"
                            content={
                                <Space direction="vertical">
                                    <Space.Compact direction="vertical">
                                    {props.canEdit &&
                                        <>
                                            <Typography.Text>
                                                <Button type="link" onClick={() => {editUser(record); return true;}}>Editar</Button>
                                            </Typography.Text>
                                            <Divider style={{ margin: 0 }} />
                                        </>
                                    }

                                    {/* {props.canDelete &&
                                        <>
                                            <Typography.Text>
                                                <Button type="link" onClick={() => onUserDelete(record)}>Eliminar</Button>
                                            </Typography.Text>
                                            <Divider style={{ margin: 0 }} />
                                        </>
                                    } */}
                                    </Space.Compact>
                                </Space>
                            }>
                            <Button type="link"><MoreOutlined style={{ fontSize: 18, cursor: 'pointer', fontWeight:'bold' }} /></Button>
                        </Popover>
                    )}
                />
                <Column title="Correo" dataIndex="email" key="email" />
                <Column title="Nombres" dataIndex="firstName" key="firstName" />
                <Column title="Apellidos" dataIndex="lastName" key="lastName" />
                <Column title="Rol" dataIndex="roles" key="roles"
                    render={(roles: Role[]) =>
                        (roles[0]?.displayName)}
                    defaultSortOrder='ascend'
                    sorter={(a: Role, b: Role) => (a.displayName ?? '').localeCompare(b.displayName ?? '')}
                    sortDirections={['ascend', 'descend', 'ascend']}
                />
                
            </Table>
            </Space>
            </Col>
            </Row>
    );
}
import { Card, message } from "antd";
import { getUsers } from "api/identityapi/users";
import LoadingSpinner from "components/common/loadingSpinner/loadingSpinner";
import { PageTitle } from "components/common/pageTitle/pageTitle";
import { PermissionList } from "constants/permissions";
import usePermission from "features/auth/usePermission";
import { UserList } from "features/users/userList";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { User } from "shared/models/userModel";

export default function UserListPage() {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const currentUser = useAppSelector(state => state.currentUser);
    const userCanCreate = usePermission([PermissionList.UserCreate]);
    const userCanEdit= usePermission([PermissionList.UserEdit]);

    useEffect(() => {
        const getInfo = async () => {

            if (!currentUser?.customer && !currentUser?.supplier) {
                return;
            }

            try {
                setLoading(true);
                const response = await getUsers({ customerId: currentUser?.customer?.id, supplierId: currentUser?.supplier?.id });
                setUsers(response);
            } catch (error: any) {
                message.error('Error obteniendo lista de usuarios.');
            } finally {
                setLoading(false);
            }
        };
        getInfo();
    }, [currentUser]);

    if(loading){
        return <LoadingSpinner></LoadingSpinner>
    }

    return (
        <Card>
            <PageTitle>Usuarios</PageTitle>

            <UserList 
                users={users}
                supplierId={currentUser?.supplier?.id}
                customerId={currentUser?.customer?.id}
                canCreate={userCanCreate}
                canEdit={userCanEdit}
                canDelete={userCanEdit}
                />

            

        </Card>
    );

}
import { Col, Row } from "antd";
import { ProfileDropdown } from "./profileDropdown";

function DesktopHeader() {
  return (
    <Row justify="space-between" align="middle">
      <>
        <Col lg={10} xxl={8}>
          {/* Search */}
        </Col>
        <Col>
          {/* Github button */}
        </Col>
      </>

      <Col xl={8} xxl={7}>
        <Row align="middle" justify="end" gutter={[10, 10]}>
          <Col>
            <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>
              <Col>
                {/* <NotificationsDropdown /> */}
              </Col>

              <Col>
                {/* <SettingsDropdown /> */}
              </Col>
            </Row>
          </Col>

          <Col>
            <ProfileDropdown />
          </Col>
        </Row>
      </Col>
    </Row>
  );


}

export default DesktopHeader;
import { Card, Col, Row, Typography } from "antd";
import CustomerForm from "features/customers/customerForm";


function CreateCustomerPage(){
    return (
        <Row justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <Typography.Title level={2}>Crear perfil de cliente</Typography.Title>
            <Card style={{paddingLeft:50, paddingRight:50}}>
                <CustomerForm></CustomerForm>
            </Card>
            
        </Col>
        </Row>
    );
}

export default CreateCustomerPage;